import {every, first, flatten, forEach, get, groupBy, isEmpty, keyBy, keys, map, some, uniqBy} from 'lodash';
import {action} from 'mobx';

import Step from '../cablingMapEditor/store/Step';
import {generateId} from '../eptBuilder/utils';

const speedExpr = new RegExp(/^(\d+(\.\d+)?)([gkmtb])$/, 'i');
const nameExpr = new RegExp(/[^a-z0-9]/, 'gi');

export const speedFromString = (speedString) => {
  try {
    const [, value, , unit] = speedString.match(speedExpr);
    return {value: +value, unit};
  } catch {
    return null;
  }
};

export const stringFromSpeed = (speed) => {
  return `${speed?.value ?? ''}${speed?.unit ?? ''}`;
};

export const makeSafeName = (text) => {
  return text.replace(nameExpr, '_');
};

export const randomPrefix = () => {
  return generateId().substr(-5);
};

// When the same set of items (nodes|linksGroups) get changed in batch, existing Change object must be reused.
// If the set is not the same - generate the new Change object.
export const pickBatchChangeFor = (items, {changes}, registerChanges = true) => {
  // If given item(s) are nodes - their pairs will also be affected, thus
  // paired nodes must also be added to the set
  const itemsAffected = uniqBy(flatten(map(items, (item) => (item?.nodesInvolved ?? item))), 'id');
  const itemsById = keyBy(itemsAffected, 'id');

  let change = changes.current;
  if (change?.length === itemsAffected.length && every(change, ({id, isDelete}) => (!!itemsById[id] && !isDelete))) {
    // All node' properties changes must be tracked as a single change
    // Thus if the latest change equals to the current set of nodes - reuse it
    changes.splice();
  } else {
    // ... or create the new Change otherwise
    change = map(itemsAffected, (item) => Step.modification(null, item));
    if (registerChanges) changes.register(change);
  }
  return change;
};

// Register a single property change on a set of nodes
export const registerNodeChange = (change, rackStore, property, value) => {
  const isBatch = change?.length > 1;
  const isLabel = property === 'label';
  const stepsById = keyBy(change, 'id');

  if (isLabel) {
    // If label is being changed in batch previous label values must be removed for all
    // affected nodes in order not to interfere with taken labels set
    forEach(change, (step) => {
      rackStore.nodes[step.id]?.setProperty('label', '');
    });
  }

  forEach(change, (step) => {
    const node = rackStore.nodes[step.id];
    let newValue = value;
    if (isBatch && isLabel) {
      newValue = rackStore.pickUniqueLabel(value || node.role, node.isPaired);
      // If node is paired and the pair is in selection and the pair has already been assigned
      // a label, just propagate this label
      if (node.isPaired && !!stepsById[node.pairedWith?.id]) {
        newValue = node.pairedWith._label || newValue;
      }
    }

    // If no property is supplied - just dump the existing state
    if (property) {
      node.setProperty(
        property,
        newValue,
        true
      );
    }
    step.setResult(node);
  });
};

// Bulds all possible permutations for the given set of opitons
export const permute = (options, memo = [], result = []) => {
  for (let index = 0, length = options.length; index < length; index++) {
    const current = options.splice(index, 1);
    if (options.length === 0) result.push(memo.concat(current));
    permute(options.slice(), memo.concat(current), result);
    options.splice(index, 0, current[0]);
  }
  return result;
};

const releasePorts = (ports) => {
  forEach(ports, (port) => port.release());
};

export const tryPlacing = action((targetPorts, amongPorts) => {
  if (isEmpty(targetPorts)) return amongPorts;

  const clones = map(amongPorts, (port) => port.clone());
  const portsBySpeed = groupBy(clones, 'speedString');

  // For each of speeds must be calculated independently
  const result = every(groupBy(targetPorts, 'speedString'), (ports, speedString) => {
    const availablePorts = portsBySpeed[speedString];
    const byRoles = groupBy(ports, 'role');
    // Trying all possible permutations of target roles
    return some(permute(keys(byRoles)), (rolesOrdered) => {
      // Reset taken ports states
      releasePorts(availablePorts);
      // For a single roles combination ...

      return every(rolesOrdered, (role) => {
        // ... make sure all target ports
        return every(byRoles[role], (targetPort) => {
          // ... find available local target
          return some(availablePorts, (myPort) => {
            if (myPort.correspondsTo(targetPort, true)) {
              // If one exists, mark it as taken and proceed
              myPort.isTaken = true;
              return true;
            }
          });
        });
      });
    });
  });

  return result ? clones : false;
});

export const makePortChannelError = (id, peeringType) => {
  return peeringType ? (
    <>
      {'Port Channel ID '}
      <b>{id}</b>
      {` for ${peeringType} peer links is used more than once`}
    </>
  ) : (
    <>
      {'Port Channel ID '}
      <b>{id}</b>
      {' is used more than once'}
    </>
  );
};

// Returns the value of the property if it is the same for all given nodes or the
// defaultValue otherwize
export const sameValue = (nodes, property, defaultValue, comparator = property) => {
  const firstNode = first(nodes);
  const firstValue = get(firstNode, comparator);
  return every(nodes, (node) => (get(node, comparator) === firstValue)) ? get(firstNode, property) : defaultValue;
};
