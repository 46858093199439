import {Component} from 'react';
import {action, comparer, computed, observable, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {isString, keys, noop} from 'lodash';
import {Button, Popup} from 'semantic-ui-react';
import cx from 'classnames';
import {SearchBox} from 'apstra-ui-common';

import MatcherFilterStringParser from '../matcherFilterString/MatcherFilterStringParser';
import MatcherFilterStringToObjectConverter from '../matcherFilterString/MatcherFilterStringToObjectConverter';
import {filtersToQueryParam} from '../queryParamUtils';
import PlainQueryInput from './PlainQueryInput';
import {rangeControlRenderer} from './RangeControl';

import './PlainInputSearchBox.less';

@observer
export default class PlainInputSearchBox extends Component {
  static defaultProps = {
    highlightPropertyColumn: noop,
  };
  @observable filters = this.props.filters ?? {};

  disposeFiltersUpdater = reaction(
    () => this.props.filters,
    (filters) => {
      this.filters = filters ?? {};
    },
    {equals: comparer.structural}
  );

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get isPlainInputMode() {
    return isString(this.plainInputValue);
  }

  @computed
  get plainInputValue() {
    return this.props.plainInputProp ?
      this.filters[this.props.plainInputProp] :
      this.filters;
  }

  @action
  switchQueryInput = () => {
    const {
      filters, plainInputValue, isPlainInputMode,
      props: {plainInputProp, schema, onChange, highlightPropertyColumn}
    } = this;
    if (isPlainInputMode) {
      const keyValueFilters = MatcherFilterStringToObjectConverter.run(
        MatcherFilterStringParser.parse(plainInputValue).cst, schema
      ) || {};
      keys(keyValueFilters).forEach((key) => {
        const propertyValue = schema.find((property) => property.name === key);
        if (propertyValue?.isRange) {
          keyValueFilters[key] = isFinite(keyValueFilters[key]) ?
            {equals: keyValueFilters[key]} : keyValueFilters[key];
        }
      });
      onChange(keyValueFilters);
    } else {
      const inlineFilters = filtersToQueryParam(filters, schema) || '';
      const newValue = plainInputProp ? {[plainInputProp]: inlineFilters} : inlineFilters;
      onChange(newValue);
    }
    highlightPropertyColumn(null);
  };

  componentWillUnmount() {
    this.disposeFiltersUpdater();
  }

  render() {
    const {filters, isPlainInputMode, plainInputValue, switchQueryInput} = this;
    const {
      errors, valueProps, renderers, valueInputProps, schema,
      disabled, onChange, applyOnChange, plainInputProp,
      highlightPropertyColumn, 'aria-label': ariaLabel,
    } = this.props;

    const tooltip = isPlainInputMode ? 'Switch to basic' : 'Switch to plain query input';
    return (
      <div className='plain-input-search-box'>
        {isPlainInputMode ? (
          <PlainQueryInput
            onChange={((value) => onChange(plainInputProp ? {[plainInputProp]: value} : value))}
            schema={schema}
            applyOnChange={applyOnChange}
            disabled={disabled}
            filters={plainInputValue}
            highlightPropertyColumn={highlightPropertyColumn}
            aria-label={ariaLabel}
          />
        ) : (
          <SearchBox
            schema={schema}
            filters={filters}
            errors={errors}
            valueProps={valueProps}
            valueInputProps={valueInputProps}
            disabled={disabled}
            onChange={onChange}
            applyOnChange={applyOnChange}
            renderers={[
              rangeControlRenderer,
              ...(renderers ?? []),
            ]}
          />
        )}
        <Popup
          trigger={
            <Button
              className={cx('switch-mode', {ghost: !isPlainInputMode})}
              icon='terminal'
              disabled={disabled}
              onClick={switchQueryInput}
              active={isPlainInputMode}
              aria-label={tooltip}
            />
          }
          disabled={disabled}
          content={tooltip}
        />
      </div>
    );
  }
}
