import {Component, Fragment} from 'react';
import {observable, action, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Grid, Message, Dropdown, Icon} from 'semantic-ui-react';
import {map, flatMap, isEmpty, union, filter, keys, pick, values} from 'lodash';
import {
  ActionsMenu, DataFilter, DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, PermissionChecker,
  hasBlueprintPermissions, interpolateRoute, request, withRouter,
  stringFilterer, arrayFilterer,
} from 'apstra-ui-common';

import IBAContext from '../IBAContext';
import ProbeDeletionModal from './ProbeDeletionModal';
import ProbeBatchImportModal from './ProbeBatchImportModal';
import PredefinedProbeModal from './PredefinedProbeModal';
import {getFilterByProp} from '../dataFilteringUtils';
import generateProbeURI from '../generateProbeURI';
import {tagsFilterRenderer} from '../../components/TagsFilterInput';
import {tagsRenderer} from '../../components/TagsInput';
import {ProbeStateLabel, ProbeAnomalyCountLabel} from './ProbeDetails';
import UpdatedByLabel from './UpdatedByLabel';
import PredefinedEntityIcon from './PredefinedEntityIcon';
import ProbeActions from './ProbeActions';
import ProbeToggle from './ProbeToggle';
import userStore from '../../userStore';

import './ProbeList.less';

const tableSchema = [
  {
    name: 'label',
    label: 'Name',
    value: ['label'],
    formatter: ({item: probe, value, params: {blueprintId, predefinedProbes}}) => (
      <div className='entity-name'>
        <Link to={generateProbeURI({blueprintId, probeId: probe.id})}>
          {value}
        </Link>
        {probe.predefined_probe &&
          <PredefinedEntityIcon
            predefinedEntities={predefinedProbes}
            predefinedEntityName={probe.predefined_probe}
            predefinedEntityType='Predefined probe'
          />
        }
      </div>
    ),
    sortable: true,
  },
  {
    name: 'anomalies',
    label: 'Anomalies',
    value: ({item: probe}) => probe.anomaly_count,
    formatter: ({value}) => <ProbeAnomalyCountLabel anomalyCount={value} />,
    sortable: true,
  },
  {
    name: 'state',
    label: 'State',
    value: ({item: {state, disabled}}) => [state, disabled],
    formatter: ({item: probe}) => <ProbeStateLabel probe={probe} />,
    sortable: true,
  },
  {
    name: 'updated_by',
    label: 'Updated By',
    value: ['updated_by'],
    formatter: ({item: probe}) => <UpdatedByLabel updatedBy={probe.updated_by} timestamp={probe.updated_at} />,
    sortable: true,
  },
  {
    name: 'tags',
    label: 'Tags',
    value: ({item: {tags = [], stages}}) => [tags, flatMap(stages, ({tags = []}) => tags)],
    formatter: ({value: [probeTags, stageTags], name, params: {filterByProp}}) =>
      <div className='tag-list'>
        {!isEmpty(probeTags) &&
          <div key='probe'>
            {tagsRenderer.renderValueWithoutCondition({
              value: probeTags, onClick: (value) => filterByProp({name, value})
            })}
          </div>
        }
        {!isEmpty(stageTags) &&
          <div key='stage'>
            {tagsRenderer.renderValueWithoutCondition({
              value: stageTags, onClick: (value) => filterByProp({name, value})
            })}
          </div>
        }
      </div>,
    sortable: true,
  },
  {
    name: 'enabled',
    label: 'Enabled',
    value: ({item: {disabled}}) => !disabled,
    formatter: ({item: probe, value, params: {refetchOnEnableToggle, refetchOnEnableToggleInProgress}}) => (
      <ProbeToggle
        value={value}
        probe={probe}
        refetchInProgress={refetchOnEnableToggleInProgress}
        refetchData={() => refetchOnEnableToggle(probe.id)}
      />
    ),
    sortable: true,
  },
  {
    name: 'actions',
    label: 'Actions',
    formatter: ({item: probe, params: {setProbeDeletionModalProps, setPredefinedProbeModalProps, refetchData}}) => (
      <ProbeActions
        size='small'
        probe={probe}
        setProbeDeletionModalProps={setProbeDeletionModalProps}
        setPredefinedProbeModalProps={setPredefinedProbeModalProps}
        refetchData={refetchData}
      />
    ),
  },
];

const searchBoxSchema = [
  {
    name: 'label',
    schema: {
      type: 'string',
      title: 'Name',
    }
  },
  {
    name: 'tags',
    schema: {
      type: 'object',
      title: 'Tags',
      default: {tags: [], strict: true},
    }
  },
  {
    name: 'anomalousOnly',
    schema: {
      type: 'boolean',
      title: 'Show only probes with anomalies',
    }
  },
];

const labelFilterer = stringFilterer(['label'], ['label']);
const tagsFilterer = arrayFilterer(
  ({stages, tags}) => union(tags, ...map(stages, ({tags = []}) => tags)),
  ['tags', 'tags'],
  ['tags', 'strict']
);

@withRouter
@observer
export default class ProbeList extends Component {
  static contextType = IBAContext;

  static pollingInterval = 10000;
  static userStoreKey = 'probeList';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({blueprintId, routes, signal}) {
    const route = routes.probeList;
    const {items: probes} = await request(interpolateRoute(route, {blueprintId}), {signal});
    return {probes};
  }

  @observable.ref probeDeletionModalProps = null;
  @observable.ref predefinedProbeModalProps = null;
  @observable.ref batchImportModalProps = null;
  @observable.ref selection = {};
  @observable refetchOnEnableToggleInProgress = {};

  @action
  setProbeDeletionModalProps = (props) => {
    this.probeDeletionModalProps = props;
  };

  @action
  setPredefinedProbeModalProps = (props) => {
    this.predefinedProbeModalProps = props;
  };

  @action
  setBatchImportModalProps = (props) => {
    this.batchImportModalProps = props;
  };

  @action
  updateSelection = (selection) => {
    this.selection = selection;
  };

  @action
  refetchOnEnableToggle = async (refetchData, probeId) => {
    this.refetchOnEnableToggleInProgress[probeId] = true;
    await refetchData();
    runInAction(() => {
      this.refetchOnEnableToggleInProgress[probeId] = false;
      if (isEmpty(filter(values(this.refetchOnEnableToggleInProgress)))) {
        this.refetchOnEnableToggleInProgress = {};
      }
    });
  };

  getDataTableActions = ({selectionIds, probes, blueprintPermissions}) => [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: () => this.setProbeDeletionModalProps({
        open: true, probes: probes.filter((probe) => selectionIds.includes(probe.id))
      }),
      hasPermissions: hasBlueprintPermissions({blueprintPermissions, action: 'edit'}),
      notPermittedMessage: 'You do not have permission to delete',
      popupPosition: 'bottom center',
    }
  ];

  anomalousOnlyFilterer = ({item: probe, filters: {anomalousOnly = false}}) => {
    if (!anomalousOnly) return true;
    return probe.anomaly_count;
  };

  render() {
    const {blueprintId, predefinedProbes, knownTags, routes, blueprintPermissions} = this.context;
    const {
      getDataTableActions, updateSelection, selection,
      refetchOnEnableToggle, refetchOnEnableToggleInProgress,
      probeDeletionModalProps, predefinedProbeModalProps, batchImportModalProps,
      setProbeDeletionModalProps, setPredefinedProbeModalProps, setBatchImportModalProps
    } = this;
    const defaultPageSize = userStore.getStoreValue([ProbeList.userStoreKey, 'pageSize']);
    return (
      <DataFilteringContainer
        defaultPageSize={defaultPageSize}
        stateQueryParam='probes-filter'
        defaultSorting={{label: 'asc'}}
        setUserStoreProps={userStore.setStoreValueFn(ProbeList.userStoreKey)}
      >
        {({activePage, pageSize, updatePagination, filters, updateFilters, sorting, updateSorting}) =>
          <FetchData
            customLoader
            fetchData={ProbeList.fetchData}
            fetchParams={{blueprintId, routes}}
            pollingInterval={ProbeList.pollingInterval}
          >
            {({probes = [], loaderVisible, fetchDataError, refetchData}) => {
              const filteredSelection = pick(selection, map(probes, 'id'));
              const selectionIds = filter(keys(filteredSelection), (key) => filteredSelection[key]);
              const dataTableActionItems = getDataTableActions({selectionIds, probes, blueprintPermissions});
              return (
                <Fragment>
                  <Grid stackable className='iba-probe-list'>
                    <Grid.Column width={16} textAlign='right'>
                      <PermissionChecker
                        hasPermissions={hasBlueprintPermissions({blueprintPermissions, action: 'edit'})}
                        notPermittedMessage='You do not have permission to create'
                        popupProps={{
                          position: 'top center',
                          offset: ({reference}) => [0, reference.height],
                        }}
                      >
                        <Dropdown
                          button
                          aria-label='Create Probe'
                          className='primary big'
                          trigger={
                            <span>
                              <Icon name='add circle' className='add-probe-icon' />
                              {'Create Probe'}
                            </span>
                          }
                        >
                          <Dropdown.Menu direction='left'>
                            <Dropdown.Item
                              as={Link}
                              to='../create-probe'
                              size='big'
                              icon='file outline'
                              content='New Probe'
                            />
                            <Dropdown.Item
                              icon='box'
                              content='Instantiate Predefined Probe'
                              onClick={() => setPredefinedProbeModalProps({open: true})}
                            />
                            <Dropdown.Item
                              icon='upload'
                              content='Import Probes'
                              onClick={() => setBatchImportModalProps({open: true})}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </PermissionChecker>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <DataFilter
                        items={probes}
                        schema={tableSchema}
                        activePage={activePage}
                        pageSize={pageSize}
                        filterers={[labelFilterer, tagsFilterer, this.anomalousOnlyFilterer]}
                        filters={filters}
                        sorting={sorting}
                      >
                        {({items, allItems, totalCount}) =>
                          <DataFilteringLayout
                            totalCount={totalCount}
                            activePage={activePage}
                            pageSize={pageSize}
                            filters={filters}
                            searchBoxSchema={searchBoxSchema}
                            searchBoxSchemaValueInputProps={{knownTags, allowNewTags: false}}
                            searchBoxRenderers={[tagsFilterRenderer]}
                            updatePagination={updatePagination}
                            updateFilters={updateFilters}
                            loaderVisible={loaderVisible}
                            fetchDataError={fetchDataError}
                            leftColumn={
                              selectionIds.length > 0 &&
                                <ActionsMenu size='small' items={dataTableActionItems} />
                            }
                          >
                            {probes.length ?
                              <Fragment>
                                <DataTable
                                  selectable
                                  allowSelectAll
                                  allItems={allItems}
                                  updateSelection={updateSelection}
                                  selection={filteredSelection}
                                  className='probe-list-table'
                                  verticalAlign='middle'
                                  items={items}
                                  schema={tableSchema}
                                  params={{
                                    blueprintId, predefinedProbes,
                                    setProbeDeletionModalProps, setPredefinedProbeModalProps,
                                    refetchData, refetchOnEnableToggleInProgress,
                                    refetchOnEnableToggle: (probeId) => refetchOnEnableToggle(refetchData, probeId),
                                    filterByProp: getFilterByProp({filters, updateFilters})
                                  }}
                                  sorting={sorting}
                                  updateSorting={updateSorting}
                                  getHeaderCellProps={({name}) => ({collapsing: name === 'actions'})}
                                  getRowProps={({item}) => ({
                                    error: item.anomaly_count > 0 || item.state === 'error'
                                  })}
                                  getItemKey={({id}) => id}
                                />
                              </Fragment>
                          :
                              <Message
                                info
                                icon='info circle'
                                content={
                                  'There are currently no probes defined. ' +
                                  'Please consult Juniper Apstra documentation to create new probes.'
                                }
                              />
                            }
                          </DataFilteringLayout>
                        }
                      </DataFilter>
                    </Grid.Column>
                  </Grid>
                  <ProbeDeletionModal
                    open={false}
                    onClose={() => setProbeDeletionModalProps({open: false})}
                    onSuccess={() => refetchData()}
                    {...probeDeletionModalProps}
                  />
                  <PredefinedProbeModal
                    open={false}
                    onClose={() => setPredefinedProbeModalProps({open: false})}
                    {...predefinedProbeModalProps}
                  />
                  <ProbeBatchImportModal
                    open={false}
                    onUploadSuccess={refetchData}
                    onClose={() => setBatchImportModalProps({open: false})}
                    {...batchImportModalProps}
                  />
                </Fragment>
              );
            }}
          </FetchData>
        }
      </DataFilteringContainer>
    );
  }
}
