import {useMemo, useState} from 'react';
import cx from 'classnames';
import {constant, forEach, keys, filter, map, min, max, head, last, join, compact} from 'lodash';
import {Group} from '@visx/group';
import {Axis} from '@visx/axis';
import {GridRows} from '@visx/grid';
import {BarGroup} from '@visx/shape';
import {withResizeDetector} from 'react-resize-detector';
import {easeCubic, scaleBand, scaleLinear, scaleOrdinal, scaleTime} from 'd3';
import {brandColorNames, formatChartAxisTime, formatNumber, SvgTextLengthMeasurer} from 'apstra-ui-common';
import {animated, Spring} from '@react-spring/web';

import {getFittedTickLabels} from './utils';

import ChartPopup from './ChartPopup';
import ChartLegend from './ChartLegend';

import './BarGroupChart.less';

const DATA_FORMAT = {
  none: 'none',
  timestamp: 'timestamp',
};

const BarGroupChart = ({
  mode, samples, yScaleFn, className, width: chartWidth, targetRef, dimensions,
  groupLabelKey, colors, padding, springConfig, processPopupHeader, processPopupContent,
  units, tickGroupFormat, xLabel, yLabel, labelMarginLeft: labelMarginLeftProp, dataXFormat,
  fontSize,
}) => {
  const xScaleFn = dataXFormat === DATA_FORMAT.none ?
    scaleBand :
    dataXFormat === DATA_FORMAT.timestamp ?
      scaleTime :
      scaleLinear;

  const [popupDescription, setPopupDescription] = useState(null);
  const [textLength, setTextLength] = useState(0);
  const [unitsLength, setUnitsLength] = useState(0);
  const labelMarginLeft = labelMarginLeftProp + unitsLength;

  const hidePopup = () => setPopupDescription(null);

  const {height: chartHeight, margin} = dimensions[mode];

  const xMax = (chartWidth - margin.left - margin.right) || 0;
  const yMax = (chartHeight - margin.top - margin.bottom) || 0;

  const [minValue, maxValue, groups, valueKeys] = useMemo(() => {
    const valueKeys = filter(keys(samples[0]), (key) => key !== groupLabelKey);
    const groups = [];
    let minValue = Number.MAX_VALUE;
    let maxValue = -Number.MAX_VALUE;
    forEach(samples, (item) => {
      groups.push(item[groupLabelKey]);
      forEach(valueKeys, (key) => {
        const value = item[key];
        if (value < minValue) minValue = value;
        if (value > maxValue) maxValue = value;
      });
    });
    return [minValue, maxValue, groups, valueKeys];
  }, [samples, groupLabelKey]);

  const formattedGroups = useMemo(
    () => map(groups, (group) => tickGroupFormat(group)),
    [groups, tickGroupFormat]
  );

  const [xScale, yScale] = useMemo(() => {
    const xScale = xScaleFn();
    const yScale = yScaleFn();
    if (dataXFormat === DATA_FORMAT.timestamp) {
      xScale.domain([head(groups), last(groups)]);
    } else if (dataXFormat === DATA_FORMAT.none) {
      xScale.domain(groups).padding(padding);
    } else {
      xScale.domain(groups);
    }
    xScale.rangeRound([0, xMax]);
    yScale.domain([min([minValue, 0]), max([maxValue, 0])]).rangeRound([yMax, 0]);
    return [xScale, yScale];
  }, [xMax, groups, minValue, maxValue, yMax, padding, xScaleFn, yScaleFn, dataXFormat]);

  let boxSize = 0;
  if (dataXFormat === DATA_FORMAT.none) {
    boxSize = xScale.bandwidth();
  } else if (dataXFormat === DATA_FORMAT.timestamp) {
    const domain = xScale.domain();
    const startTime = head(domain);
    const endTime = last(domain);
    boxSize = (xScale(endTime) - xScale(startTime)) / groups.length;
  }

  const barScale = scaleBand().domain(valueKeys).padding(padding).rangeRound([0, boxSize]);
  const colorScale = scaleOrdinal().domain(valueKeys).range(colors);

  const formatTicksMap = useMemo(() => {
    if (dataXFormat !== DATA_FORMAT.none) return [];
    return getFittedTickLabels({
      textLength, scaleFn: xScale, labels: groups, labelFormatFn: tickGroupFormat, widthMax: xMax,
    });
  }, [xScale, textLength, groups, xMax, tickGroupFormat, dataXFormat]);
  const formatXTick = (value) => {
    if (dataXFormat === DATA_FORMAT.timestamp) return formatChartAxisTime(value);
    if (dataXFormat === DATA_FORMAT.none) return formatTicksMap[value];
    return value;
  };

  const label = useMemo(() => {
    return join(compact([yLabel, units]), ', ');
  }, [yLabel, units]);

  const showTicks = chartWidth > 0;
  return !chartWidth ? (
    <div
      ref={targetRef}
      className={cx('graph-container', {expandable: mode !== 'expanded'})}
    />
  ) : (
    <div
      ref={targetRef}
      className={cx('graph-container', {expandable: mode !== 'expanded'})}
    >
      <svg className={cx('bar-group-chart-layout', className)} width={chartWidth} height={chartHeight}>
        <Group top={margin.top} left={margin.left + labelMarginLeft}>
          <BarGroup
            data={samples}
            keys={valueKeys}
            height={yMax}
            x0={(item) => item[groupLabelKey]}
            x0Scale={xScale}
            x1Scale={barScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group
                  key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                  left={barGroup.x0}
                  onMouseLeave={hidePopup}
                >
                  {barGroup.bars.map((bar) => (
                    <Spring
                      key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                      from={{y: yScale(0), height: 0}}
                      to={{
                        y: getY({...bar, y0: yScale(0)}),
                        height: getHeight({...bar, y0: yScale(0), yMax})
                      }}
                      springConfig={springConfig}
                    >
                      {({y, height}) => (
                        <>
                          <animated.rect
                            x={bar.x}
                            y={y}
                            width={bar.width}
                            height={height}
                            rx={4}
                            className={cx('bar', bar.color)}
                          />
                          <circle
                            cx={bar.x + bar.width / 2}
                            cy={bar.y}
                            r={min([bar.width / 4, 7])}
                            className={cx('circle', bar.color)}
                          />
                        </>
                      )}
                    </Spring>
                  ))}
                  <rect
                    {...getBarGroupRect(barGroup, yScale(0), yMax)}
                    onMouseEnter={(e) => setPopupDescription({
                      node: e.target,
                      header: processPopupHeader(groups[barGroup.index]),
                      content: processPopupContent(barGroup.bars, units),
                    })}
                  />
                </Group>
              ))
            }
          </BarGroup>
          <Axis
            axisClassName='timeline-axis axis-bottom'
            orientation='bottom'
            top={yScale(0)}
            scale={xScale}
            hideTicks
            tickFormat={() => null}
          />
          <Axis
            axisClassName='timeline-axis axis-bottom'
            orientation='bottom'
            top={yScale(min([minValue, 0]))}
            scale={xScale}
            tickLabelProps={constant({})}
            tickFormat={formatXTick}
            label={xLabel}
            labelClassName='axis-label'
            hideAxisLine
          />
          <Axis
            axisClassName='timeline-axis axis-left'
            orientation='left'
            scale={yScale}
            tickLabelProps={constant({dx: '-0.25em', dy: '0.25em'})}
            label={label}
            labelClassName='axis-label'
            labelProps={{textAnchor: 'middle', y: -margin.left - labelMarginLeft / 2}}
            tickFormat={(value) =>
              formatNumber(value, {units, short: (value <= -1 || value >= 1), withIndent: true})
            }
          />
        </Group>
        <ChartPopup popupDescription={popupDescription} />
        {showTicks && (
          <GridRows
            className='timeline-grid'
            top={margin.top}
            left={margin.left}
            width={xMax}
            height={yMax}
            scale={yScale}
            stroke={null}
          />
        )}
        {dataXFormat === DATA_FORMAT.none && (
          <SvgTextLengthMeasurer
            value={formattedGroups}
            style={{fontSize}}
            onMeasure={(value) => setTextLength(value)}
          />
        )}
        {units && (
          <SvgTextLengthMeasurer
            value={units}
            style={{fontSize}}
            onMeasure={(value) => setUnitsLength(value)}
          />
        )}
      </svg>
      <ChartLegend ordinalColorScale={colorScale} horizontal />
    </div>
  );
};

BarGroupChart.defaultProps = {
  mode: 'expanded',
  dimensions: {
    compact: {
      height: 100,
      margin: {top: 4, right: 10, bottom: 10, left: 40},
    },
    expanded: {
      height: 400,
      margin: {top: 10, right: 10, bottom: 50, left: 50},
    },
  },
  processPopupHeader: (groupName) => groupName,
  processPopupContent: (items, units) => <BarGroupPopupContent items={items} units={units} />,
  yScaleFn: scaleLinear,
  groupLabelKey: 'group',
  tickGroupFormat: (value) => `${value}`,
  colors: brandColorNames,
  padding: 0.2,
  springConfig: {
    duration: 1000,
    easing: easeCubic
  },
  labelMarginLeft: 15,
  dataXFormat: DATA_FORMAT.none,
  fontSize: 10,
};

const BarGroupPopupContent = ({items, units}) => map(items, ({color, value, key}) => (
  <div key={key} className='bar-group-popup'>
    <div className={cx('legend', color)} />
    {key}
    {' '}
    {units ?
      `${formatNumber(value, {units, short: true})} (${value}${units})` :
      value
    }
  </div>
));

const getBarGroupRect = (barGroup, y0, yMax) => {
  const lastIndex = barGroup.bars?.length - 1;
  const firstBar = barGroup.bars[0];

  let y = Number.MAX_VALUE;
  let height = -Number.MAX_VALUE;
  forEach(barGroup.bars, (bar) => {
    const yBar = getY({...bar, y0});
    const heightBar = getHeight({...bar, y0, yMax});
    if (y > yBar) y = yBar;
    if (height < heightBar) height = heightBar;
  });

  return {
    height,
    y,
    width: barGroup.bars[lastIndex].x - firstBar.x + firstBar.width,
    x: firstBar.x,
    fill: 'transparent',
  };
};
const getY = ({y0, value, y}) => value > 0 ? y : y0;
const getHeight = ({y0, yMax, value, height}) => value > 0 ?
  height - yMax + y0 :
  yMax - height - y0;

export default withResizeDetector(BarGroupChart, {handleWidth: true});
