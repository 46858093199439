/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';
import {map, find, includes, isEmpty} from 'lodash';

import buildPropertyDocHTMLFromSchema from '../pythonExpression/buildPropertyDocHTMLFromSchema';

const property = /[a-zA-Z_][.\w]*/;
const keywordTypeMap = {
  string: ['=', '!=', '~=', 'is', 'is not', 'in', 'not in'],
  number: ['=', '!=', 'is', 'is not', 'in', 'not in', '<', '>', '<=', '>='],
  boolean: ['=', '!=', 'is', 'is not', 'in', 'not in'],
  object: ['=', '!=', 'is', 'is not', 'in', 'not in', '<', '>', '<=', '>='],
};
const keywordNoneMap = {
  is: ['not', 'none'],
  'is not': ['none'],
};

ace.define(
  'ace/mode/matcher-filter-string',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {TextHighlightRules} = require('ace/mode/text_highlight_rules');
    const {Mode: TextMode} = require('ace/mode/text');
    const {TokenIterator} = require('ace/token_iterator');
    const {BaseCompleter} = require('ace/base_completer');

    class MatcherFilterStringCompleter extends BaseCompleter {
      async getCustomCompletions(state, session, pos, prefix) {
        const token = session.getTokenAt(pos.row, pos.column);
        const properties = this.getProperties();
        const previousTokens = this.getPreviousTokens({session, pos});
        if (!token || !previousTokens) {
          return [
            ...properties,
            ...await this.getTextCompletions(state, session, pos, prefix)
          ];
        }
        const {tokens: [firstToken]} = previousTokens;
        if (firstToken.type === 'keyword.logic') {
          return [
            ...properties,
            ...await this.getTextCompletions(state, session, pos, prefix)
          ];
        }
        if (firstToken.type === 'property') {
          const propertyToken = this.getPropertyName({session, pos});
          const type = propertyToken ? this.getPropertyType(propertyToken.name) : 'string';
          return this.getKeywords(keywordTypeMap[type]);
        }
        if (firstToken.type === 'keyword.operator') {
          if (keywordNoneMap[firstToken.value]) {
            return map(keywordNoneMap[firstToken.value], (name, index) => ({
              caption: name,
              snippet: name,
              score: 100 - index,
            }));
          }
        }
        if (firstToken.type === 'keyword.operator' || firstToken.type === 'paren.lparen') {
          const propertyToken = this.getPropertyName({session, pos});
          if (!propertyToken) return [];
          return this.getPossibleValues(propertyToken.name);
        }
        if (includes(['string', 'constant.numeric', 'constant.language', 'paren.rparen'], firstToken.type)) {
          return this.getKeywords(['and', 'or']);
        }
      }

      checkForCustomCharacterCompletions(editor) {
        const pos = editor.getCursorPosition();
        const line = editor.getSession().getLine(pos.row).substr(0, pos.column);
        if (/(['"]|\s*)$/.test(line)) {
          this.showCompletionsPopup(editor);
        }
      }

      getKeywords(options) {
        return map(options, (keyword, index) => ({
          caption: keyword,
          snippet: keyword,
          meta: 'keyword',
          score: 100 - index,
        }));
      }

      getPropertyType(propertyName) {
        const property = find(this.params?.schema, ({name}) => name === propertyName);
        if (property) {
          const {schema} = property;
          return schema.type || 'string';
        }
        return 'string';
      }

      getPossibleValues(propertyName) {
        const property = find(this.params?.schema, ({name}) => name === propertyName);
        if (!property || !property.schema) return [];
        const {schema: {type, enum: options}} = property;
        const schemaOptions = isEmpty(options) ? [] : options;
        if (type === 'boolean') {
          schemaOptions.push('True', 'False');
        }
        return map(schemaOptions, (option, index) => ({
          caption: option,
          snippet: type === 'string' ? `'${option}'` : option,
          meta: 'possible value',
          className: 'completion-function ace_',
          score: 100 - index
        }));
      }

      getProperties() {
        return map(this.params?.schema, ({name, schema}, index) => ({
          caption: name,
          snippet: name,
          docHTML: buildPropertyDocHTMLFromSchema(schema),
          className: 'completion-keyword-argument ace_',
          meta: 'property',
          score: 1000 - index
        }));
      }

      getPropertyName({iterator, session, pos}) {
        if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
        let currentToken;
        do {
          do {
            currentToken = iterator.stepBackward();
            if (!currentToken) return null;
          } while (currentToken.type === 'text');
          if (currentToken.type === 'property') {
            return {token: currentToken, name: currentToken.value};
          }
        } while (true); // eslint-disable-line no-constant-condition
      }

      getPreviousTokens({iterator, session, pos}) {
        if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
        const tokens = [];
        let currentToken, nextToken;
        let parenNesting = false;
        do {
          do {
            currentToken = iterator.stepBackward();
            if (!currentToken) return null;
          } while (currentToken.type === 'text');
          tokens.unshift(currentToken);
          parenNesting = includes(['string', 'constant.numeric', 'constant.language'], currentToken.type);
          if (!parenNesting) {
            if (nextToken && tokens[0].type === 'keyword.operator') {
              return {iterator, tokens: [nextToken], name: nextToken.value};
            }
            return {iterator, tokens, name: currentToken.value};
          }
          nextToken = currentToken;
        } while (true); // eslint-disable-line no-constant-condition
      }
    }

    class MatcherFilterStringHighlightRules extends TextHighlightRules {
      constructor() {
        super();

        this.$rules = {
          start: [
            {
              token: 'string',
              regex: /'(:?[^\\\n\r']+|\\(:?[nr\\/']))*'/,
            },
            {
              token: 'string',
              regex: /"(:?[^\\\n\r"]+|\\(:?[nr\\/"]))*"/,
            },
            {
              token: 'keyword.logic',
              regex: /\b(?:or|and)\b/,
            },
            {
              token: 'keyword.operator',
              regex: /\b(?:or|and|is not|is|not in|in)\b/,
            },
            {
              token: 'keyword.operator',
              regex: /(?:=|!=|>=|<=|<|>|~=)/,
            },
            {
              include: 'constants'
            },
            {
              token: 'property',
              regex: property,
            },
            {
              token: 'paren.lparen',
              regex: /[[(]/
            },
            {
              token: 'paren.rparen',
              regex: /[\])]/
            },
          ],
          constants: [
            {
              token: 'constant.numeric',
              regex: /[-+]?\d+(\.\d+)?/
            },
            {
              token: 'constant.language',
              regex: /(True|False|none)/
            },
          ]
        };
        this.normalizeRules();
      }
    }

    class MatcherFilterStringMode extends TextMode {
      $id = 'ace/mode/matcher-filter-string';
      $behaviour = this.$defaultBehaviour;
      HighlightRules = MatcherFilterStringHighlightRules;
      completer = new MatcherFilterStringCompleter();
      getCurrentProperty = (session, {row, column}) => {
        const iterator = new TokenIterator(session, row, column);
        const valueTokens = ['constant.language', 'constant.numeric', 'paren.rparen', 'string'];
        let currentToken = session.getTokenAt(row, column);
        const isFirstTokenText = (currentToken?.type === 'text');
        if (isFirstTokenText) {
          const nextToken = iterator.stepForward();
          if (nextToken) {
            const nextTokenColumn = iterator.getCurrentTokenColumn();
            const nextTokenRow = iterator.getCurrentTokenRow();
            if (nextToken.type === 'property' && nextTokenRow === row && nextTokenColumn === column) {
              return nextToken;
            }
          }
        }
        do {
          if (!currentToken || currentToken.type === 'keyword.logic' ||
            (isFirstTokenText && includes(valueTokens, currentToken.type))) {
            return null;
          }
          if (currentToken.type === 'property') return currentToken;
          currentToken = iterator.stepBackward();
        } while (true); // eslint-disable-line no-constant-condition
      };
    }

    exports.Mode = MatcherFilterStringMode;
    exports.HighlightRules = MatcherFilterStringHighlightRules;
  }
);

ace.require(['ace/mode/matcher-filter-string']);
