import {Component} from 'react';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {HashRouter as Router, Link} from 'react-router-dom';
import {isString} from 'lodash';
import {DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, natsort, request} from 'apstra-ui-common';

import wrapWithComponent from '../../wrapWithComponent';
import PlainInputSearchBox from '../../components/PlainInputSearchBox';
import userStore from '../../userStore';

const tableSchema = [
  {
    name: 'node',
    label: 'Node Name',
    value: ({item, params: {systemIdMap}}) => systemIdMap[item.system_id].label,
    formatter: ({value, item, params: {blueprintId, systemIdMap}}) =>
      <Link
        to={`/blueprints/${blueprintId}/nodes/${systemIdMap[item.system_id].id}/active/telemetry/arp`}
        children={value}
      />
  },
  {
    name: 'system_id',
    label: 'S/N',
    value: ['system_id'],
  },
  {
    name: 'hostname',
    label: 'Hostname',
    value: ({item, params: {systemIdMap}}) => systemIdMap[item.system_id].hostname,
  },
  {
    name: 'type',
    label: 'Type',
    value: ['type'],
    formatter: ({value}) => ({staticArp: 'Static', dynamicArp: 'Dynamic'}[value] || value)
  },
  {
    name: 'vrf_name',
    label: 'VRF Name',
    value: ['vrf_name'],
  },
  {
    name: 'mac_address',
    label: 'MAC',
    value: ['mac_address'],
  },
  {
    name: 'ip_address',
    label: 'IP',
    value: ['ip_address'],
  },
  {
    name: 'interface_name',
    label: 'Interface',
    value: ['interface_name'],
  },
];

@wrapWithComponent(Router)
@observer
export default class BlueprintQueryARP extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({blueprintId, activePage, pageSize, filters, signal}) {
    let body;
    if (isString(filters.queryFilter)) {
      body = {query_filter: filters.queryFilter};
    } else {
      const {
        system_id: systemId, ip_address: ipAddress = '*.*.*.*', mac_address: macAddress = '*'
      } = filters;
      body = {system_id: systemId, ip_address: ipAddress, mac_address: macAddress};
    }
    const {items, total_count: totalCount} = await request(
      `/api/blueprints/${blueprintId}/query/arp`,
      {
        method: 'POST',
        queryParams: {page: activePage, per_page: pageSize},
        body: JSON.stringify(body),
        signal
      }
    );
    return {items, totalCount};
  }
  static pollingInterval = 10000;
  static userStoreKey = 'blueprintQueryARP';

  @computed get searchBoxSchema() {
    const nodes = Object.values(this.props.systemIdMap).sort((node1, node2) => natsort(node1.label, node2.label));
    return [
      {
        name: 'system_id',
        schema: {
          type: 'string',
          title: 'Node',
          oneOf: nodes.map(({system_id: systemId, label}) => ({const: systemId, title: label})),
        }
      },
      {
        name: 'ip_address',
        schema: {
          type: 'string',
          title: 'IP Address',
          description: (
            <span>
              {'Use '}<strong>{'*'}</strong>{' as a placeholder for octets, e.g. '}<strong>{'172.16.*.*'}</strong>
            </span>
          )
        }
      },
      {
        name: 'mac_address',
        schema: {
          type: 'string',
          title: 'MAC Address',
          description: (
            <span>
              {'Use '}<strong>{'*'}</strong>{' as a placeholder, e.g. '}<strong>{'11:22:*'}</strong>
            </span>
          )
        }
      }
    ];
  }

  renderSearchBox = (props) => {
    return <PlainInputSearchBox {...props} plainInputProp='queryFilter' aria-label='ARP blueprint query' />;
  };

  render() {
    const {searchBoxSchema, renderSearchBox, props: {systemIdMap, blueprintId}} = this;
    const defaultPageSize = userStore.getStoreValue([BlueprintQueryARP.userStoreKey, 'pageSize']);
    return (
      <DataFilteringContainer
        stateQueryParam='blueprint-query-arp'
        defaultPageSize={defaultPageSize}
        setUserStoreProps={userStore.setStoreValueFn(BlueprintQueryARP.userStoreKey)}
      >
        {({activePage, pageSize, filters, updatePagination, updateFilters}) =>
          <FetchData
            fetchData={BlueprintQueryARP.fetchData}
            pollingInterval={BlueprintQueryARP.pollingInterval}
            fetchParams={{blueprintId, activePage, pageSize, filters}}
            customLoader
          >
            {({items, totalCount, loaderVisible, fetchDataError}) =>
              <DataFilteringLayout
                loaderVisible={loaderVisible}
                fetchDataError={fetchDataError}
                totalCount={totalCount}
                activePage={activePage}
                pageSize={pageSize}
                filters={filters}
                searchBoxSchema={searchBoxSchema}
                SearchBoxComponent={renderSearchBox}
                updatePagination={updatePagination}
                updateFilters={updateFilters}
              >
                <DataTable
                  items={items}
                  schema={tableSchema}
                  params={{systemIdMap, blueprintId}}
                />
              </DataFilteringLayout>
            }
          </FetchData>
        }
      </DataFilteringContainer>
    );
  }
}
