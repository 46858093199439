import {createRef, isValidElement, useEffect, useState} from 'react';
import {Ref, Dropdown, Icon} from 'semantic-ui-react';
import cx from 'classnames';
import {useResizeDetector} from 'react-resize-detector';
import {isString} from 'lodash';

import './MainSidebar.less';

const MainSidebar = ({menuItems, withMessage, openMenuKey, openMenu, closeMenu}) => {
  const {height, ref} = useResizeDetector();
  return (
    <div
      ref={ref}
      className={cx('main-sidebar', {'with-cluster-message': withMessage})}
      role='navigation'
      aria-label='Sidebar'
    >
      <div className='content'>
        <div
          className={cx(
            'ui borderless inverted vertical left fixed menu',
            {open: openMenuKey}
          )}
        >
          <a className='item logo' href='#' aria-label='Home'>
            <div
              className='icon'
              data-tooltip='Home'
              data-inverted='true'
              data-position='right center'
            />
            <div className='text'><b>{'Juniper'}</b>{' Apstra™'}</div>
          </a>
          {menuItems.map((menuItem) => {
            const menuKey = menuItem['menu-key'] ?? menuItem.href;
            if (menuItem.children) {
              return (
                <Dropdown
                  key={menuKey}
                  icon={null}
                  upward={false}
                  item
                  aria-label={isString(menuItem.label) ? menuItem.label : menuKey}
                  className={cx(
                    'main-menu-item',
                    menuItem['item-class'],
                    {active: menuItem['active?']}
                  )}
                  open={menuKey === openMenuKey}
                  onOpen={() => openMenu(menuKey)}
                  onClose={closeMenu}
                  trigger={
                    <SidebarTopMenuItem menuItem={menuItem} />
                  }
                >
                  <SidebarSubmenuPopup
                    visible={menuKey === openMenuKey}
                    menuItem={menuItem}
                    height={height}
                  />
                </Dropdown>
              );
            } else {
              return (
                <SidebarMenuItem
                  key={menuKey}
                  className='main-menu-item'
                  topLevel
                  menuItem={menuItem}
                >
                  <SidebarTopMenuItem menuItem={menuItem} />
                </SidebarMenuItem>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export const SidebarTopMenuItem = ({menuItem}) => (
  <div
    data-tooltip={isString(menuItem.label) ? menuItem.label : null}
    data-inverted='true'
    data-position='right center'
  >
    {menuItem['icon-class'] &&
      <Icon className={menuItem['icon-class']} />
    }
    {menuItem.label ?
      isValidElement(menuItem.label) ?
        menuItem.label :
        <div className='text'>{menuItem.label}</div> :
      null
    }
  </div>
);

export const SidebarMenuItem = ({
  menuItem: {label, 'active?': active, href, 'on-click': onClick, target},
  className, topLevel, children,
}) => {
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      onClick?.();
    }
  };

  const props = {
    className: cx('item', className, {active}),
    onKeyDown,
    children: children ?? label,
  };
  if (!topLevel) {
    props.role = 'option';
    props['aria-checked'] = props['aria-selected'] = active;
  } else {
    props['aria-label'] = label;
  }
  if (href) props.href = href;
  if (target) props.target = target;
  if (onClick) {
    props.onClick = onClick;
    props.tabIndex = 0;
  }

  return <a {...props} />;
};

export const SidebarSubmenuPopup = ({menuItem, height, visible}) => {
  const [top, setTop] = useState(null);
  const [originalTop, setOriginalTop] = useState(null);

  const ref = createRef();

  useEffect(() => {
    const updateTop = () => {
      const dropdownRect = ref.current.getBoundingClientRect();
      if (dropdownRect.height >= document.documentElement.clientHeight) {
        setTop(-originalTop);
      } else {
        const spaceAtTheBottom = document.documentElement.clientHeight - originalTop - dropdownRect.height;
        setTop(spaceAtTheBottom < 0 ? spaceAtTheBottom : 0);
      }
    };

    if (ref.current) {
      setOriginalTop(ref.current.parentElement.getBoundingClientRect().top);
      updateTop();
    }
  }, [ref, originalTop, height, visible]);

  return (
    <Ref innerRef={ref}>
      <Dropdown.Menu
        className={cx('sidebar-submenu', {visible})}
        style={{top}}
      >
        {isString(menuItem.label) &&
          <>
            <div className='header'>{menuItem.label}</div>
            <hr />
          </>
        }
        <SidebarSubmenuContent menuItems={menuItem.children} />
      </Dropdown.Menu>
    </Ref>
  );
};

export const SidebarSubmenuContent = ({menuItems}) => menuItems.map((menuItem, index) =>
  menuItem.children ?
    [
      isString(menuItem.label) && <div key='header' className='secondary header'>{menuItem.label}</div>,
      menuItem.children.map((menuItem, index) =>
        isValidElement(menuItem) ?
          menuItem :
          <SidebarMenuItem
            key={index}
            className='secondary'
            menuItem={menuItem}
          />
      )
    ] :
    isValidElement(menuItem) ?
      menuItem :
      <SidebarMenuItem
        key={index}
        menuItem={menuItem}
      />
);

export default MainSidebar;
