import {Component} from 'react';
import {Popup, Table} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {reaction, observable, action, makeObservable, computed} from 'mobx';
import {observer} from 'mobx-react';
import csv from 'csvtojson';
import {has, get, merge, isPlainObject, intersection, castArray} from 'lodash';
import {
  Checkbox, DataFilter, DataFilteringContainer, DataFilteringLayout, DataTable,
  stringFilterer,
} from 'apstra-ui-common';

import './CSVPreview.less';

@observer
export default class CSVPreview extends Component {
  static propTypes = {
    contents: PropTypes.string.isRequired,
    importError: PropTypes.object,
    headers: PropTypes.object
  };

  static defaultProps = {
    headers: {}
  };

  @observable items = [];
  @observable tableSchema = [];
  @observable searchSchema = [];
  @observable filterers = [];
  @observable showErrorsOnly = false;

  @computed get errors() {
    return this.props.importError?.responseBody?.errors;
  }

  @action
  toggleShowErrorsOnly = () => {
    this.showErrorsOnly = !this.showErrorsOnly;
  };

  errorsFilterer = ({item}) => {
    if (this.showErrorsOnly && isPlainObject(this.errors)) {
      return has(this.errors, item.originalIndex);
    }
    return true;
  };

  @action
  updateSchemas(properties) {
    this.tableSchema = properties.map((property) => ({
      name: property,
      label: property,
      value: [property],
      sortable: true,
    }));

    const filters = (this.props.headers.size ? intersection(properties, this.props.headers.keys()) : properties);
    this.searchSchema = filters.map((property) => ({
      name: property,
      schema: {type: 'string', title: property},
    }));
    this.filterers = [
      this.errorsFilterer,
      ...filters.map((property) => stringFilterer([property], [property]))
    ];
  }

  @action
  updateItems(items) {
    // remember item index in the original collection to parse server errors (+2 to follow the backend indexation)
    this.items = items.map((item, index) => merge(item, {originalIndex: index + 2}));
  }

  @action
  parseCSV() {
    csv()
      .on('header', (header) => this.updateSchemas(header))
      .fromString(this.props.contents)
      .then((items) => this.updateItems(items));
  }

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeCSVParsing = reaction(
      () => this.props.contents,
      () => this.parseCSV(),
      {fireImmediately: true});
  }

  componentWillUnmount() {
    this.disposeCSVParsing();
  }

  render() {
    if (this.tableSchema.length) {
      return (
        <div className='csv-preview'>
          <DataFilteringContainer
            defaultPageSize={10}
          >
            {({activePage, pageSize, filters, sorting, updatePagination, updateFilters, updateSorting}) =>
              <DataFilter
                items={this.items}
                schema={this.tableSchema}
                activePage={activePage}
                pageSize={pageSize}
                sorting={sorting}
                filters={filters}
                filterers={this.filterers}
              >
                {({items, totalCount}) =>
                  <DataFilteringLayout
                    totalCount={totalCount}
                    activePage={activePage}
                    pageSize={pageSize}
                    updatePagination={updatePagination}
                    filters={filters}
                    searchBoxSchema={this.searchSchema}
                    leftColumn={
                      isPlainObject(this.errors) ?
                        <Checkbox
                          label='Show Errors Only'
                          checked={this.showErrorsOnly}
                          onChange={this.toggleShowErrorsOnly}
                        />
                      : []
                    }
                    updateFilters={updateFilters}
                  >
                    <div className='overflow-container'>
                      <DataTable
                        size='small'
                        items={items}
                        schema={this.tableSchema}
                        sorting={sorting}
                        updateSorting={updateSorting}
                        params={{allItems: this.items}}
                        getRowProps={({item: {originalIndex}}) => ({
                          error: isPlainObject(this.errors) && has(this.errors, originalIndex)
                        })}
                        getCellProps={({name, item: {originalIndex}}) => ({
                          negative: isPlainObject(this.errors) && (has(this.errors, [originalIndex, name]) ||
                                    has(this.errors, name)),
                          errors: isPlainObject(this.errors) && get(this.errors, [originalIndex, name])
                        })}
                        CellComponent={CSVTableCell}
                      />
                    </div>
                  </DataFilteringLayout>
                }
              </DataFilter>
            }
          </DataFilteringContainer>
        </div>
      );
    } else {
      return null;
    }
  }
}

function CSVTableCell({children, negative, errors}) {
  if (errors) {
    return (
      <Popup
        trigger={
          <Table.Cell role='cell' negative>
            {children}
          </Table.Cell>
        }
      >
        {(castArray(errors).join('. '))}
      </Popup>
    );
  } else {
    return (
      <Table.Cell role='cell' negative={negative}>
        {children}
      </Table.Cell>
    );
  }
}
