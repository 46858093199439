import {Component} from 'react';
import {observable, computed, action, toJS, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Form} from 'semantic-ui-react';
import {map, transform, cloneDeep, isEqual} from 'lodash';
import {saveAs} from 'file-saver';
import {parse as json2csv} from 'json2csv';
import {AsyncActionsModal, FormFragment, generatePropertyFromSchema, request} from 'apstra-ui-common';

import {filtersToQueryParam, sortingToQueryParam} from '../../queryParamUtils';
import auditEventLogFilterSchema from '../auditEventLogFilterSchema';

const formSchema = [
  {
    name: 'maxEntries',
    schema: {
      type: 'number',
      title: 'Max Entries',
      default: 1000,
    }
  },
  ...auditEventLogFilterSchema,
];

@observer
export default class AuditEventLogExportModal extends Component {
  @observable properties = {};

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setPropertyValue(name, value) {
    this.properties[name] = value;
  }

  @action
  resetState = () => {
    this.properties = {};
    const filters = cloneDeep(this.props.filters);
    for (const {name, schema} of formSchema) {
      this.properties[name] = name in filters ? filters[name] : generatePropertyFromSchema(schema);
    }
  };

  @computed get filters() {
    const currentFilters = toJS(this.properties);
    return transform(auditEventLogFilterSchema, (result, {name, schema}) => {
      const emptyFilterValue = generatePropertyFromSchema(schema);
      const filterValue = currentFilters[name];
      if (!isEqual(filterValue, emptyFilterValue)) result[name] = filterValue;
    }, {});
  }

  submit = async () => {
    const {routes} = this.props;
    const queryParams = {
      per_page: this.properties.maxEntries,
      orderby: sortingToQueryParam({timestamp: 'desc'}),
    };
    const filtersAsString = filtersToQueryParam(this.filters);
    if (filtersAsString.length) queryParams.filter = filtersAsString;
    const {items} = await request(routes.auditEventLog, {queryParams});
    const csv = json2csv(items, {fields: map(auditEventLogFilterSchema, 'name')});
    saveAs(new Blob([csv], {type: 'text/csv'}), 'event-log.csv');
  };

  render() {
    const props = {...this.props};
    delete props.filters;
    delete props.routes;
    return (
      <AsyncActionsModal
        closeIcon
        size='small'
        header='Export Event Log'
        onMount={this.resetState}
        content={
          ({currentActionKey}) =>
            <Form>
              <FormFragment
                schema={formSchema}
                disabled={!!currentActionKey}
                values={this.properties}
                onChange={(name, value) => this.setPropertyValue(name, value)}
              />
            </Form>
        }
        actions={[
          {
            key: 'save',
            primary: true,
            content: 'Save as CSV File',
            icon: 'save',
            size: 'large',
            onClick: this.submit
          },
        ]}
        {...props}
      />
    );
  }
}
