import {isNumber, isString} from 'lodash';
import {Field, FormattedNumber, createValueRenderer, formatSeconds, withPrefix} from 'apstra-ui-common';
import {Dropdown, Input} from 'semantic-ui-react';

import {SPECIAL_RENDERED_PROPERTIES} from './consts';

import './commonRenderers.less';

export const renderSeconds = createValueRenderer({
  condition: ({name, value}) => (
    withPrefix(SPECIAL_RENDERED_PROPERTIES.SECONDS_OR_EXPRESSION, 'properties.').includes(name) &&
    isNumber(value)),
  renderValue: ({value}) => formatSeconds(value)
});

export const renderSpeed = createValueRenderer({
  condition: ({name, value}) => name === 'properties.speed' && isNumber(value),
  renderValue: ({value}) => <FormattedNumber value={value} units='bps' />
});

const regexInputOptions = [
  {key: 'text', text: '=', value: 'text', description: 'exact match'},
  {key: 'regex', text: '~=', value: 'regex', description: 'regex match'},
];

const RegexValue = ({value}) => {
  const val = value?.value ?? value;
  return value?.type === 'regex' ? `/${val}/` : val;
};

export const renderRegexTextInput = createValueRenderer({
  condition: ({schema, value}) => schema?.type === 'string' ||
    isString(value?.value) && (['regex', 'text'].includes(value?.type)),
  renderValue: RegexValue,
  renderValueInput({value: sourceValue = '', name, schema, required, disabled,
    errors, onChange, placeholder, fieldProps}) {
    const {value, type} = isString(sourceValue) ? {value: sourceValue, type: 'text'} : sourceValue;
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
        {...fieldProps}
      >
        <Input
          type='text'
          label={<Dropdown
            className='regex-dropdown'
            basic
            options={regexInputOptions}
            value={type ?? 'text'}
            onChange={(_, data) => onChange({value, type: data.value})}
          />}
          value={value ?? ''}
          placeholder={placeholder}
          onChange={(e) => onChange({type, value: e.target.value})}
        />
      </Field>
    );
  }
});
