import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {map, isFunction} from 'lodash';

import BaseGauge from './Gauge';

export default class GaugeGroup extends PureComponent {
  static propTypes = {
    gauges: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number,
      minValue: PropTypes.number,
      maxValue: PropTypes.number,
      onClick: PropTypes.func
    })),
    size: PropTypes.number,
    hideLabels: PropTypes.bool,
    getInnerContent: PropTypes.func
  };

  static defaultProps = {
    gauges: [],
    size: 100,
    hideLabels: true
  };

  render() {
    const {gauges, size, hideLabels, getInnerContent} = this.props;

    const width = size;
    const height = size * 3 / 4 + 10;
    const radius = size / 2;
    const marginTop = (size - height) / 2;

    return (
      [map(gauges, (gauge, index) => (
        <BaseGauge
          key={index}
          value={gauge.value}
          minValue={gauge.minValue}
          maxValue={gauge.maxValue}
          width={width}
          height={height}
          radius={radius}
          margin={{top: marginTop, right: 0, bottom: 0, left: 0}}
          hideLabels={hideLabels}
          onClick={gauge.onClick}
        >
          {isFunction(getInnerContent) ? getInnerContent(gauge) : null}
        </BaseGauge>
      ))]
    );
  }
}
