import {Component, Fragment} from 'react';
import {HashRouter as Router} from 'react-router-dom';
import {Grid, Button, Modal, Label, Table} from 'semantic-ui-react';
import {isEmpty, map, identity} from 'lodash';
import {DataTable, DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DateFromNow, FetchData, PermissionChecker,
  checkPermissions, hasPermissions, parseTimestamp, request, timeRangeRenderer} from 'apstra-ui-common';

import wrapWithComponent from '../../wrapWithComponent';
import {ConfigView} from '../../components/ConfigDiff';
import {filtersToQueryParam, sortingToQueryParam} from '../../queryParamUtils';
import AuditEventLogExportModal from './AuditEventLogExportModal';
import auditEventLogFilterSchema from '../auditEventLogFilterSchema';
import userStore from '../../userStore';
import {userEventSchema, syslogEventSchema, authAclRuleEventSchema} from '../eventSchemas';

const tableSchema = [
  {
    name: 'timestamp',
    label: 'Time',
    value: ({item}) => parseTimestamp(item.timestamp),
    formatter: ({value}) => <DateFromNow popupPosition='left center' date={value} />,
    sortable: true,
  },
  {
    name: 'user',
    label: 'User',
    value: ['user'],
    sortable: true,
  },
  {
    name: 'user_ip',
    label: 'User IP Address',
    value: ['user_ip'],
    sortable: true,
  },
  {
    name: 'type',
    label: 'Type',
    value: ['type'],
    formatter: ({value}) => <Label>{value}</Label>,
    sortable: true,
  },
  {
    name: 'result',
    label: 'Result',
    value: ['result'],
    sortable: true,
  },
  {
    name: 'details',
    label: 'Details',
    value: (props) => <EventDetailsFormatter {...props} />,
  },
];

@wrapWithComponent(Router)
export default class AuditEventLog extends Component {
  static defaultProps = {
    routes: {
      auditEventLog: '/api/audit/events',
    }
  };

  static pollingInterval = 10000;
  static userStoreKey = 'auditEventLog';

  static async fetchData({activePage, pageSize, filters, sorting, routes, permissions, signal}) {
    checkPermissions({permissions, route: routes.auditEventLog});
    if (!sorting.timestamp) sorting = {...sorting, timestamp: 'desc'};
    const queryParams = {page: activePage, per_page: pageSize};
    const sortingAsString = sortingToQueryParam(sorting);
    if (sortingAsString.length) queryParams.orderby = sortingAsString;
    const filtersAsString = filtersToQueryParam(filters);
    if (filtersAsString.length) queryParams.filter = filtersAsString;
    const {items, total_count: totalCount} = await request(routes.auditEventLog, {queryParams, signal});
    return {items, totalCount};
  }

  render() {
    const {routes, permissions, systemsHrefs} = this.props;
    const defaultPageSize = userStore.getStoreValue([AuditEventLog.userStoreKey, 'pageSize']);
    return (
      <DataFilteringContainer
        stateQueryParam='event-filter'
        defaultPageSize={defaultPageSize}
        defaultSorting={{timestamp: 'desc'}}
        setUserStoreProps={userStore.setStoreValueFn(AuditEventLog.userStoreKey)}
      >
        {({activePage, pageSize, filters, sorting, updatePagination, updateFilters, updateSorting}) =>
          <FetchData
            fetchData={AuditEventLog.fetchData}
            pollingInterval={AuditEventLog.pollingInterval}
            fetchParams={{activePage, pageSize, filters, sorting, routes, permissions}}
            customLoader
          >
            {({items, totalCount, loaderVisible, fetchDataError}) =>
              <Grid stackable>
                <Grid.Column width={16} textAlign='right'>
                  <AuditEventLogExportModal
                    filters={filters}
                    routes={routes}
                    trigger={
                      <PermissionChecker
                        hasPermissions={
                          hasPermissions({permissions, route: routes.auditEventLog})
                        }
                        notPermittedMessage='You do not have permission to export'
                        popupProps={{
                          position: 'top center',
                          offset: ({reference}) => [0, reference.height],
                        }}
                      >
                        <Button
                          primary
                          size='big'
                          icon='share square'
                          content='Export to CSV'
                        />
                      </PermissionChecker>
                    }
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <DataFilteringLayout
                    loaderVisible={loaderVisible}
                    fetchDataError={fetchDataError}
                    totalCount={totalCount}
                    activePage={activePage}
                    pageSize={pageSize}
                    filters={filters}
                    searchBoxSchema={auditEventLogFilterSchema}
                    searchBoxRenderers={[timeRangeRenderer]}
                    updatePagination={updatePagination}
                    updateFilters={updateFilters}
                  >
                    <DataTable
                      items={items}
                      schema={tableSchema}
                      params={{systemsHrefs}}
                      sorting={sorting}
                      updateSorting={updateSorting}
                      getHeaderCellProps={({name}) => ({collapsing: name !== 'details' && name !== 'result'})}
                      getCellProps={({name}) => ({singleLine: name === 'timestamp'})}
                    />
                  </DataFilteringLayout>
                </Grid.Column>
              </Grid>
            }
          </FetchData>
        }
      </DataFilteringContainer>
    );
  }
}

function EventDetailsFormatter(props) {
  const {item} = props;
  const {type} = item;
  if (type === 'BlueprintCommit' || type === 'BlueprintRevert' || type === 'BlueprintDelete') {
    return <BlueprintDetails {...props} deleted={type === 'BlueprintDelete'} />;
  } else if (type === 'DeviceConfigChange') {
    return <DeviceConfigDetails {...props} />;
  } else if (type === 'RatelimitExceptionAdd' || type === 'RatelimitExceptionDelete' || type === 'RatelimitClear') {
    return <RatelimitDetails {...props} />;
  } else if (type === 'SyslogCreate' || type === 'SyslogUpdate' || type === 'SyslogDelete') {
    const DetailsComponent = type === 'SyslogUpdate' ? EventDetailsDiff : EventDetails;
    return <DetailsComponent item={item} schema={syslogEventSchema} />;
  } else if (type === 'UserCreate' || type === 'UserUpdate' || type === 'UserDelete') {
    const DetailsComponent = type === 'UserUpdate' ? EventDetailsDiff : EventDetails;
    return (
      <Fragment>
        <Label content={item.username} icon='user' />
        <DetailsComponent item={item} schema={userEventSchema} />
      </Fragment>
    );
  } else if (type === 'AuthAclRuleAdd' || type === 'AuthAclRuleDelete') {
    return <EventDetails item={item} schema={authAclRuleEventSchema} />;
  } else if (type === 'AuthAclRuleUpdate') {
    return (
      <Fragment>
        <div>{`Subnet: ${item.subnet}`}</div>
        <EventDetailsDiff item={item} schema={authAclRuleEventSchema} />
      </Fragment>
    );
  }
  return null;
}

function EventDetails({item, schema}) {
  return (
    <Table basic='very' definition size='small' compact>
      <Table.Body>
        {map(schema, ({field, label, formatter = identity}) => {
          const value = item[field];
          if (value) {
            return (
              <Table.Row key={field}>
                <Table.Cell collapsing>{label}</Table.Cell>
                <Table.Cell>{formatter(value)}</Table.Cell>
              </Table.Row>
            );
          }
        })}
      </Table.Body>
    </Table>
  );
}

function EventDetailsDiff({item, schema}) {
  return (
    <Table basic='very' definition size='small' compact collapsing>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>{'Old'}</Table.HeaderCell>
          <Table.HeaderCell>{'New'}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {map(schema, ({field, label, formatter = identity}) => {
          const oldValue = item['old_' + field];
          const newValue = item['new_' + field];
          if (oldValue || newValue) {
            return (
              <Table.Row key={field}>
                <Table.Cell>{label}</Table.Cell>
                <Table.Cell>{formatter(oldValue)}</Table.Cell>
                <Table.Cell>{formatter(newValue)}</Table.Cell>
              </Table.Row>
            );
          }
        })}
      </Table.Body>
    </Table>
  );
}

function BlueprintDetails({item, deleted}) {
  const {blueprint_id: blueprintId, blueprint_label: blueprintLabel, commit_message: commitMessage} = item;
  return (
    <Fragment>
      <div>
        {'Blueprint: '}
        {deleted ?
          `${blueprintLabel} (ID: ${blueprintId})`
        :
          <a href={`#/blueprints/${blueprintId}`}>{blueprintLabel}</a>
        }
      </div>
      {commitMessage &&
        <div>{commitMessage}</div>
      }
    </Fragment>
  );
}

function DeviceConfigDetails({item: {device_id: deviceId, device_config: deviceConfig}, params: {systemsHrefs}}) {
  return (
    <Fragment>
      <div>
        {'Device: '}
        {systemsHrefs
          ? <a href={systemsHrefs[deviceId]}>{deviceId}</a>
          : deviceId
        }
      </div>
      {isEmpty(deviceConfig) ?
        <div>{'Empty config'}</div>
      :
        <DeviceConfigModal
          trigger={
            <Button
              style={{marginTop: '1em'}}
              icon='eye'
              labelPosition='left'
              size='small'
              content='View Config'
            />
          }
          deviceConfig={deviceConfig}
        />
      }
    </Fragment>
  );
}

function DeviceConfigModal({trigger, deviceConfig}) {
  return (
    <Modal
      trigger={trigger}
      closeIcon
      header='Device Config'
      content={
        <Modal.Content scrolling>
          <ConfigView config={deviceConfig} showLineNumbers={false} />
        </Modal.Content>
      }
    />
  );
}

function RatelimitDetails({item: {subnet}}) {
  return (
    <div>
      {`Subnet: ${subnet}`}
    </div>
  );
}
