import React from 'react';

import {SharedTooltip} from './SharedTooltip';
import {InterfaceTooltipContent} from './InterfaceTooltipContent';
import {LinkTooltipContent} from './LinkTooltipContent';
import {NodeTooltipContent} from './NodeTooltipContent';
import {ALL_NODE_ROLES} from '../../../roles';

type TooltipContextValue = {
  sharedTooltip: any;
  containerRef: React.MutableRefObject<any>;
  layer: string;
};

const TooltipContext = React.createContext<TooltipContextValue>({
  containerRef: {current: null},
  layer: '',
  sharedTooltip: null,
});

type TooltipProviderProps = {
  layer: string;
  children: React.ReactElement;
}

export const TooltipProvider: React.FC<TooltipProviderProps> = ({
  layer,
  children,
}) => {
  const sharedTooltip = React.useMemo(() => new SharedTooltip(), []);
  const containerRef = React.useRef(null);

  return (
    <TooltipContext.Provider value={{containerRef, layer, sharedTooltip}}>
      <div
        ref={containerRef}
        onMouseMove={(e) => sharedTooltip.followThePointer(e, containerRef)}
      >
        {children}
      </div>
    </TooltipContext.Provider>
  );
};

export const useTooltip = () => {
  return React.useContext(TooltipContext);
};

export const useLinkTooltip = () => {
  const {sharedTooltip} = React.useContext(TooltipContext);
  return React.useCallback((link) => (e: any) => {
    e.stopPropagation();
    sharedTooltip.show(<LinkTooltipContent data={link} />);
  }, [sharedTooltip]);
};

export const useNodeTooltip = () => {
  const {layer, sharedTooltip} = React.useContext(TooltipContext);
  return React.useCallback((node) => (e: any) => {
    e.stopPropagation();
    const isRemoteGateway = node.role === ALL_NODE_ROLES.REMOTE_GATEWAY;
    const tooltip = isRemoteGateway ? (
      <section>
        <div><b>{'Name'}</b>{node.label}</div>
        <div><b>{'IP'}</b>{node.ip}</div>
      </section>
    ) : (
      <NodeTooltipContent
        node={node}
        layer={layer}
      />
    );
    sharedTooltip.show(tooltip, isRemoteGateway);
    e.stopPropagation();
  }, [layer, sharedTooltip]);
};

export const useInterfaceTooltip = () => {
  const {sharedTooltip} = React.useContext(TooltipContext);
  return React.useCallback((intf) => (e: any) => {
    e.stopPropagation();
    if (!intf.fakeInterface && intf.if_name) {
      const tooltip = <InterfaceTooltipContent
        label={intf.if_name}
        ipv4Addr={intf.if_ipv4}
        ipv6Addr={intf.if_ipv6}
        speed={intf.if_speed}
        tags={intf.tags}
        operation_state={intf.operation_state}
        CT={[]}
      />;
      sharedTooltip.show(tooltip);
    }
  }, [sharedTooltip]);
};
