import {Component, Fragment} from 'react';
import {action, computed, set, remove, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Popup, Table, Form, Input, Icon, Divider} from 'semantic-ui-react';
import {
  map, flatMap, filter, get, has, keys, includes, transform, pullAllWith, castArray,
  isArray, isPlainObject, isMatch, isEmpty, isNumber, head, isNil, some, find, isUndefined,
  forEach, flatten,
} from 'lodash';
import isNumeric from 'is-number';
import {CodeEditorInput, DurationInput, Field, FormFragment, ListInput, MapInput, StringListDropdownControl,
  ValidationErrors, Value, createValueRenderer, formatSeconds, withRouter} from 'apstra-ui-common';

import {
  SPECIAL_RENDERED_PROPERTIES,
  SPECIAL_RENDERED_INPUT_PROPERTIES,
  PROCESSOR_DYNAMIC_DATA_TYPES,
  PROCESSOR_STATIC_DATA_TYPES,
  SPECIAL_NUMBER_PROPERTIES,
} from '../consts';
import FormattedPythonExpression from '../../pythonExpression/components/FormattedPythonExpression';
import RangeValue from '../../components/RangeValue';
import {integerExpressionRenderer} from '../../components/IntegerExpressionInput';
import {environmentExpectationsRenderer} from './EnvironmentExpectationsInput';
import OptionalExpressionInputWrapper from './OptionalExpressionInputWrapper';
import ValueMapInput from './ValueMapInput';
import RangeInput from './RangeInput';
import StateRangeInput from './StateRangeInput';
import ServiceNameInput from './ServiceNameInput';
import {stageRenderer} from './StageInput';
import IngestionFilterInput from './IngestionFilterInput';
import IngestionFilter from './IngestionFilter';
import QueryGroupByInput from './QueryGroupByInput';
import IBAContext from '../IBAContext';
import GraphExplorerNewWindowButton from '../../graphExplorer/GraphExplorerNewWindowButton';
import QueryExpansionInput from './QueryExpansionInput';
import QueryTagFilterInput, {QueryTagFilter} from './QueryTagFilterInput';
import KeysInput from './KeysInput';
import {isDisabledProperty, isHiddenProperty} from '../processorUtils';
import SavedGraphQueryButton from '../../queryBuilder/SavedGraphQueryButton';
import GroupByInput from './GroupByInput';

const ADDITIONAL_PROPERTIES_FIELD_NAME = 'additionalProperties';

@withRouter
@observer
export default class ProcessorProperties extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get formSchema() {
    const {processor, processorDefinition} = this.props;
    const formSchema = map(processorDefinition.schema.properties, (schema, name) => {
      const hidden = isHiddenProperty(name, processor);
      const disabled = isDisabledProperty(name, processor, processorDefinition);
      return {
        name, schema, hidden, disabled, required: includes(processorDefinition.schema.required, name)
      };
    });
    const additionalPropertiesSchema = processorDefinition.schema.additionalProperties;
    if (!isEmpty(additionalPropertiesSchema)) {
      const {title, description, ...additionalProperties} = additionalPropertiesSchema;
      formSchema.push({
        name: ADDITIONAL_PROPERTIES_FIELD_NAME,
        schema: {type: 'object', title, description, additionalProperties},
      });
    }
    return formSchema;
  }

  @computed get serviceRegistryProperties() {
    const {processor, telemetryServiceRegistryItems} = this.props;
    const serviceName = processor.properties.service_name;
    const service = find(telemetryServiceRegistryItems, {service_name: serviceName});
    return get(service, ['application_schema', 'properties', 'key', 'properties']);
  }

  @computed get propertyValues() {
    const {processor, processorDefinition} = this.props;
    const condition = processor.type === 'extensible_data_collector' ?
      (name) => !has(this.serviceRegistryProperties, [name]) &&
        !processorDefinition.schema.properties[name] && !includes(processor.properties.keys, name) :
      (name) => !processorDefinition.schema.properties[name];

    return transform({...processor.properties}, (result, value, name) => {
      if (condition(name)) {
        result = result[ADDITIONAL_PROPERTIES_FIELD_NAME];
      }
      result[name] = value;
    }, {[ADDITIONAL_PROPERTIES_FIELD_NAME]: {}});
  }

  @computed get errorMessagesByProperty() {
    const {errors, processor, processorDefinition} = this.props;
    const propertyErrors = filter(errors, {type: 'processorProperty', processorName: processor.name});
    return transform(propertyErrors, (result, {propertyName, message}) => {
      if (!processorDefinition.schema.properties[propertyName] &&
        !has(this.serviceRegistryProperties, [propertyName])) {
        result[ADDITIONAL_PROPERTIES_FIELD_NAME].push({[propertyName]: message});
      } else if (has(this.serviceRegistryProperties, [propertyName]) || propertyName === 'keys') {
        result.keys.push({[propertyName]: message});
      } else {
        if (!result[propertyName]) result[propertyName] = [];
        result[propertyName].push(...castArray(message));
      }
    }, {[ADDITIONAL_PROPERTIES_FIELD_NAME]: [], keys: []});
  }

  render() {
    const {
      probe, processor, processorDefinition, telemetryServiceRegistryItems,
      editable, actionInProgress, errors, highlightStage,
      params: {probeId},
    } = this.props;
    const {formSchema, propertyValues, serviceRegistryProperties, errorMessagesByProperty} = this;
    return (editable ?
      <PropertyEditor
        processor={processor}
        processorDefinition={processorDefinition}
        formSchema={formSchema}
        propertyValues={propertyValues}
        actionInProgress={actionInProgress}
        errors={errors}
        probeId={probeId}
        probe={probe}
        highlightStage={highlightStage}
        errorMessagesByProperty={errorMessagesByProperty}
        telemetryServiceRegistryItems={telemetryServiceRegistryItems}
        serviceRegistryProperties={serviceRegistryProperties}
      />
    :
      <PropertyList
        formSchema={formSchema}
        propertyValues={propertyValues}
        probeId={probeId}
        probe={probe}
        processor={processor}
        highlightStage={highlightStage}
        errorMessagesByProperty={errorMessagesByProperty}
      />
    );
  }
}

function renderMap({value}) {
  if (isEmpty(value)) {
    return 'Empty';
  } else {
    return map(value, (value, key) =>
      <div key={key}>
        {key}{' ⟶ '}<FormattedPythonExpression value={value} />
      </div>
    );
  }
}

const {renderValue: renderGroupBy, renderValueInput: renderGroupByInput} = createValueRenderer({
  condition: ({name, value}) => name === 'group_by' && isArray(value),
  renderValue({name, value}) {
    if (value.length) {
      return this.renderArray({value, name});
    } else {
      return [
        'All',
        ' ',
        <Popup
          key='popup'
          trigger={<Icon circular name='info' aria-label='Aggregate all items into one group' />}
          content='Aggregate all items into one group'
          position='right center'
          wide
        />
      ];
    }
  },
  renderValueInput: (props) => <GroupByInput {...props} />,
});

const {renderValue: renderSignificantKeys, renderValueInput: renderSignificantKeysInput} = createValueRenderer({
  condition: ({name, value, processor}) =>
    ((name === 'keys' && processor?.type !== 'extensible_data_collector') ||
      name === 'significant_keys') && isArray(value),
  renderValue(...args) {
    return this.renderArray(...args);
  },
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) => {
    const castFlattenErrors = (errors) => flatten(map(errors, (error) => {
      const keysError = get(error, ['keys']) || get(error, ['significant_keys']) || error;
      return isPlainObject(keysError) ?
        map(keysError, (error, key) => `${key}: ${error}`) : keysError;
    }));
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={castFlattenErrors(errors)}
      >
        <StringListDropdownControl
          allowAdditions
          noResultsMessage='Start typing to add a new key'
          placeholder='No keys specified'
          value={value}
          onChange={onChange}
        />
      </Field>
    );
  }
});

const {renderValue: renderKeys, renderValueInput: renderKeysInput} = createValueRenderer({
  condition: ({name, processor}) => name === 'keys' && processor?.type === 'extensible_data_collector',
  renderValue: ({value, values}) => {
    return renderMap({
      value: transform(value, (result, key) => {
        if (!isUndefined(values[key])) result[key] = values[key];
      }, {})
    });
  },
  renderValueInput: ({
    name, value, values, schema, required, disabled, errors, onChange, telemetryServiceRegistryItems
  }) => (
    <KeysInput
      name={name}
      value={value}
      values={values}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
      telemetryServiceRegistryItems={telemetryServiceRegistryItems}
    />
  )
});

const {renderValue: renderPythonExpression, renderValueInput: renderPythonExpressionInput} = createValueRenderer({
  condition: ({name}) => SPECIAL_RENDERED_INPUT_PROPERTIES.PYTHON_EXPRESSION.includes(name),
  renderValue: ({name, value}) => {
    if (SPECIAL_RENDERED_PROPERTIES.SECONDS_OR_EXPRESSION.includes(name) && isNumeric(value)) {
      return renderSeconds({name, value: Number(value)});
    }

    return (
      <FormattedPythonExpression
        key={`python-expression-${name}`}
        value={String(value ?? '')}
      />
    );
  },
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) => (
    <CodeEditorInput
      mode='python-expression'
      name={name}
      value={String(value ?? '')}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
    />
  )
});
const {renderValue: renderGraphQuery, renderValueInput: renderGraphQueryInput} = createValueRenderer({
  condition: ({name}) => name === 'graph_query',
  renderValue({value}) {
    if (isEmpty(value)) {
      return 'Empty';
    } else {
      return (
        <IBAContext.Consumer>
          {({blueprintId, blueprintDesign}) =>
            flatMap(isArray(value) ? value : [value], (graphQuery, index) => [
              !!index && <Divider key={`divider-${index}`} />,
              <Fragment key={`gq-${index}`}>
                <FormattedPythonExpression
                  value={graphQuery}
                  multiLine
                />
                <GraphExplorerNewWindowButton
                  floated
                  defaultQuery={graphQuery}
                  explorerProps={{
                    blueprints: [{id: blueprintId, design: blueprintDesign}],
                    explorerMode: 'view-query'
                  }}
                />
              </Fragment>
            ])
          }
        </IBAContext.Consumer>
      );
    }
  },
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) => {
    const valueAsArray = isArray(value) ? value : [value];
    return (
      <IBAContext.Consumer>
        {({blueprintId, blueprintDesign}) =>
          <ListInput
            name={name}
            value={!required && value === '' ? [] : valueAsArray}
            schema={schema}
            required={required}
            disabled={disabled}
            errors={errors}
            onChange={(value) => onChange(required && value.length === 1 ? value[0] : value)}
            minItems={required ? 1 : 0}
            buttonText='Add Graph Query'
          >
            {({value, onChange}) =>
              <CodeEditorInput
                value={value}
                onChange={onChange}
                fieldProps={{width: 16}}
                mode='graph-query'
                completerParams={{blueprintDesign}}
                actions={[
                  <GraphExplorerNewWindowButton
                    key='GraphExplorerNewWindowButton'
                    explorerProps={{
                      blueprints: [{id: blueprintId, design: blueprintDesign}],
                      explorerMode: 'edit-query'
                    }}
                    defaultQuery={value}
                    saveQuery={onChange}
                  />,
                  <SavedGraphQueryButton
                    key='SavedGraphQueryButton'
                    resourceName='Graph Query'
                    onChange={onChange}
                  />
                ]}
                multiLine
                enableCompletion
              />
            }
          </ListInput>
        }
      </IBAContext.Consumer>
    );
  }
});

const {renderValue: renderServiceName, renderValueInput: renderServiceNameInput} = createValueRenderer({
  condition: ({name, schema}) => name === 'service_name' && !schema.enum,
  renderValue: ({value}) =>
    <>
      <a href={`/#/devices/telemetry-services/${value}`}>{value}</a>
      {' ('}<a href={`/#/devices/telemetry-service-registry/${value}`}>{'schema'}</a>{')'}
    </>,
  renderValueInput: ({
    name, value, values, schema, processor, required, disabled, errors, onChange, telemetryServiceRegistryItems
  }) => {
    return (
      <ServiceNameInput
        name={name}
        value={value}
        values={values}
        schema={schema}
        processor={processor}
        required={required}
        disabled={disabled}
        errors={errors}
        telemetryServiceRegistryItems={telemetryServiceRegistryItems}
        onChange={onChange}
      />
    );
  }
});

const {renderValue: renderSeconds, renderValueInput: renderSecondsInput} = createValueRenderer({
  condition: ({name}) => SPECIAL_RENDERED_PROPERTIES.SECONDS_OR_EXPRESSION.includes(name),
  renderValue: ({value}) => isNumber(value) ? formatSeconds(value) : <FormattedPythonExpression value={value} />,
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) => {
    const {type, anyOf} = schema;
    const switchModeEnabled = (!!anyOf && some(anyOf, {type: 'integer'}) && some(anyOf, {type: 'string'})) ||
      (type === 'string');
    const props = {
      value,
      name,
      type,
      schema,
      required,
      disabled,
      errors,
      onChange: (value) => {
        if (includes(SPECIAL_NUMBER_PROPERTIES, name)) {
          onChange(value);
        } else {
          onChange(switchModeEnabled && !!value ? `${value}` : value);
        }
      },
    };
    return (
      <OptionalExpressionInputWrapper switchModeEnabled={switchModeEnabled} {...props}>
        {() =>
          <DurationInput
            {...props}
            customValueType='duration'
            textPrefix=''
            value={isNumeric(value) ? Number(value) : value}
          />
        }
      </OptionalExpressionInputWrapper>
    );
  }
});

const {renderValue: renderValueMap, renderValueInput: renderValueMapInput} = createValueRenderer({
  condition: ({name}) => name === 'value_map',
  renderValue: renderMap,
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) =>
    <ValueMapInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
    />
});

const {renderValue: renderRange, renderValueInput: renderRangeInput} = createValueRenderer({
  condition: ({name, value}) => name === 'range' && isPlainObject(value),
  renderValue: ({value}) => <RangeValue min={value.min} max={value.max} />,
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) =>
    <RangeInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
    />
});

const {renderValue: renderQueryExpansion, renderValueInput: renderQueryExpansionInput} = createValueRenderer({
  condition: ({name}) => name === 'query_expansion',
  renderValue: ({value}) => {
    return (
      map(value, (keyValue, key) => {
        return (
          <div key={key}>
            <b>{key}</b>
            {keyValue.type && ` (${keyValue.type})`}
            {': '}
            <FormattedPythonExpression value={keyValue.generator} />
          </div>
        );
      })
    );
  },
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) =>
    <QueryExpansionInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
    />
});

const {renderValue: renderStateRange, renderValueInput: renderStateRangeInput} = createValueRenderer({
  condition: ({name, value}) => name === 'state_range' && isPlainObject(value),
  renderValue({value}) {
    const stateKey = head(keys(value));
    const range = head(value[stateKey]);
    const formatValue = (value) => isNil(value) ? null :
      isNumber(value) ? formatSeconds(value) : <FormattedPythonExpression value={value} />;

    return (
      <div>
        {'State '}<b>{stateKey}</b>{': '}
        <RangeValue min={formatValue(range.min)} max={formatValue(range.max)} />
      </div>
    );
  },
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) =>
    <StateRangeInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
    />
});

const {renderValue: renderIngestionFilter, renderValueInput: renderIngestionFilterInput} = createValueRenderer({
  condition: ({name}) => name === 'ingestion_filter',
  renderValue: ({value}) => <IngestionFilter value={value} />,
  renderValueInput: ({
    name, value, values, schema, required, disabled, errors, onChange,
    telemetryServiceRegistryItems
  }) =>
    <IngestionFilterInput
      name={name}
      value={value}
      values={values}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
      telemetryServiceRegistryItems={telemetryServiceRegistryItems}
    />
});

const {renderValue: renderQueryTagFilter, renderValueInput: renderQueryTagFilterInput} = createValueRenderer({
  condition: ({name}) => name === 'query_tag_filter',
  renderValue: ({value}) => <QueryTagFilter value={value} />,
  renderValueInput: ({name, value, values, schema, required, disabled, errors, onChange}) =>
    <QueryTagFilterInput
      name={name}
      value={value}
      values={values}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
    />
});

const {renderValueInput: renderQueryGroupByInput} = createValueRenderer({
  condition: ({name}) => name === 'query_group_by',
  renderValueInput: ({name, value, values, schema, required, disabled, errors, onChange}) =>
    <QueryGroupByInput
      name={name}
      value={value}
      values={values}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
    />
});

const {
  renderValue: renderExtraPythonExpressionKeys,
  renderValueInput: renderExtraPythonExpressionKeysInput
} = createValueRenderer({
  condition: ({name, schema}) => (
    name === ADDITIONAL_PROPERTIES_FIELD_NAME &&
    get(schema, ['additionalProperties', 'type']) === 'string'
  ),
  renderValue: renderMap,
  renderValueInput: ({name, value, schema, required, disabled, errors, onChange}) =>
    <MapInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
      buttonText='Add Key'
      noItemsMessage='No extra keys for graph query defined.'
    >
      {({key, value, index, errors, setEntryKey, setEntryValue}) =>
        <Fragment>
          <Field key='key' width={4}>
            <Input
              placeholder='Key'
              disabled={disabled}
              value={key}
              onChange={(e) => setEntryKey(index, e.target.value)}
            />
          </Field>
          <CodeEditorInput
            key='value'
            mode='python-expression'
            value={value}
            fieldProps={{width: 6}}
            placeholder='Expression'
            disabled={disabled}
            errors={errors}
            onChange={(value) => setEntryValue(index, value)}
          />
        </Fragment>
      }
    </MapInput>
});

@observer
class PropertyList extends Component {
  static contextType = IBAContext;

  render() {
    const {blueprintId} = this.context;
    const {formSchema, propertyValues, probeId, probe, processor, highlightStage, errorMessagesByProperty} = this.props;
    return (
      <Table definition size='small'>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan='2'>{'Properties'}</Table.Cell>
          </Table.Row>
          {formSchema.map(({name, schema}) => {
            const hasError = !isEmpty(errorMessagesByProperty[name]);
            return (
              <Table.Row key={name}>
                <Table.Cell collapsing error={hasError}>
                  {schema.title ?? name}
                  {schema.description &&
                    <Popup
                      trigger={<Icon role='img' name='info circle' color='grey' aria-label={schema.description} />}
                      content={schema.description}
                      position='right center'
                      wide
                    />
                  }
                </Table.Cell>
                <Table.Cell error={hasError}>
                  <Value
                    name={name}
                    value={propertyValues[name]}
                    values={propertyValues}
                    schema={schema}
                    renderers={[
                      renderPythonExpression,
                      renderSeconds,
                      renderRange,
                      renderStateRange,
                      renderGroupBy,
                      renderSignificantKeys,
                      renderKeys,
                      renderValueMap,
                      renderServiceName,
                      renderExtraPythonExpressionKeys,
                      stageRenderer.renderValue,
                      integerExpressionRenderer.renderValue,
                      environmentExpectationsRenderer.renderValue,
                      renderIngestionFilter,
                      renderQueryTagFilter,
                      renderGraphQuery,
                      renderQueryExpansion,
                    ]}
                    blueprintId={blueprintId}
                    probeId={probeId}
                    processor={processor}
                    probe={probe}
                    highlightStage={highlightStage}
                  />
                  {hasError &&
                    <div>
                      <ValidationErrors errors={errorMessagesByProperty[name]} pointing={false} />
                    </div>
                  }
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}

@observer
class PropertyEditor extends Component {
  static contextType = IBAContext;

  @action
  onPropertyChange = (propertyName, value) => {
    const {processor, processorDefinition, serviceRegistryProperties, errors} = this.props;
    const processorName = processor.name;
    if (propertyName === ADDITIONAL_PROPERTIES_FIELD_NAME) {
      const newAdditionalProperties = value;
      for (const propertyName of keys(processor.properties)) {
        if (
          !has(serviceRegistryProperties, [propertyName]) &&
          !processorDefinition.schema.properties[propertyName] &&
          !has(newAdditionalProperties, [propertyName])
        ) {
          remove(processor.properties, propertyName);
          pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
        }
      }
      for (const propertyName of keys(newAdditionalProperties)) {
        if (processor.properties[propertyName] !== newAdditionalProperties[propertyName]) {
          set(processor.properties, propertyName, newAdditionalProperties[propertyName]);
          pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
        }
      }
    } else if (processor.type === 'extensible_data_collector' && propertyName === 'keys') {
      const keysProperty = [...processor.properties.keys] ?? [];
      const newAdditionalProperties = value;
      for (const propertyName of keysProperty) {
        if (!has(newAdditionalProperties, [propertyName])) {
          remove(processor.properties, propertyName);
          pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
        }
      }
      for (const propertyName of keys(newAdditionalProperties)) {
        if (processor.properties[propertyName] !== newAdditionalProperties[propertyName]) {
          set(processor.properties, propertyName, newAdditionalProperties[propertyName]);
          pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
        }
      }
      processor.properties[propertyName] = keys(value);
      pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
    } else if (propertyName === 'enable_telemetry_service') {
      const properties = ['service_input', 'execution_count', 'service_interval'];
      const pullAllWithValues = [];
      processor.properties[propertyName] = value;
      forEach(properties, (name) => {
        set(processor.properties, name, processorDefinition.schema.properties[name].default);
        pullAllWithValues.push({type: 'processorProperty', processorName, name});
      });
      pullAllWithValues.push({type: 'processorProperty', processorName, propertyName});
      pullAllWith(errors, pullAllWithValues, isMatch);
    } else {
      const pullAllWithValues = [];
      processor.properties[propertyName] = value;
      if (processor.type === 'extensible_data_collector' && propertyName === 'data_type') {
        if (PROCESSOR_DYNAMIC_DATA_TYPES.has(value)) {
          const keysProperty = [...processor.properties.keys] ?? [];
          for (const propertyName of keysProperty) {
            remove(processor.properties, propertyName);
            pullAllWithValues.push({type: 'processorProperty', processorName, propertyName});
          }
          set(processor.properties, 'keys', []);
          pullAllWithValues.push({type: 'processorProperty', processorName, propertyName: 'keys'});
        } else if (PROCESSOR_STATIC_DATA_TYPES.has(value)) {
          set(processor.properties, 'ingestion_filter', {});
          pullAllWithValues.push({type: 'processorProperty', processorName, propertyName: 'ingestion_filter'});
        }
      }
      pullAllWithValues.push({type: 'processorProperty', processorName, propertyName});
      pullAllWith(errors, pullAllWithValues, isMatch);
    }
    pullAllWith(errors, [{type: 'processors'}, {type: 'processor', processorName}], isMatch);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {blueprintId, processorDefinitions} = this.context;
    const {
      formSchema, propertyValues, actionInProgress, probeId, probe, processor, highlightStage,
      errorMessagesByProperty, telemetryServiceRegistryItems,
    } = this.props;
    return (
      <Table size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Properties'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Form>
                <FormFragment
                  schema={formSchema}
                  values={propertyValues}
                  renderers={[
                    renderPythonExpressionInput,
                    renderRangeInput,
                    renderStateRangeInput,
                    renderGroupByInput,
                    renderSignificantKeysInput,
                    renderKeysInput,
                    renderValueMapInput,
                    renderServiceNameInput,
                    renderExtraPythonExpressionKeysInput,
                    stageRenderer.renderValueInput,
                    renderSecondsInput,
                    integerExpressionRenderer.renderValueInput,
                    environmentExpectationsRenderer.renderValueInput,
                    renderIngestionFilterInput,
                    renderQueryTagFilterInput,
                    renderQueryGroupByInput,
                    renderGraphQueryInput,
                    renderQueryExpansionInput,
                  ]}
                  errors={errorMessagesByProperty}
                  disabled={actionInProgress}
                  onChange={this.onPropertyChange}
                  blueprintId={blueprintId}
                  probeId={probeId}
                  probe={probe}
                  processor={processor}
                  processorDefinitions={processorDefinitions}
                  highlightStage={highlightStage}
                  telemetryServiceRegistryItems={telemetryServiceRegistryItems}
                />
              </Form>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
