import React, {Component, Fragment, createRef, PureComponent} from 'react';
import {Header, Label, Message, Grid, Table, Form, Button, Icon, Popup} from 'semantic-ui-react';
import {observable, computed, action, toJS, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {
  castArray, compact, filter, find, findIndex, forEach, get, has, head, includes, isEmpty, isFinite, isFunction, values,
  isMatch, isNumber, isString, keys, last, map, max, merge, min, pullAllWith, reduce, startCase, transform, xor,
  isUndefined, join,
} from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import {COMBING_GRAPHS_MODE, DEFAULT_COMBING_GRAPHS_MODE, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES, ActionsMenu, Checkbox,
  DataFilteringContainerWithRouter as DataFilteringContainer, DataTable, DataTableRowFragment, DateFromNow,
  DropdownControl, DurationInput, FetchData, FetchDataError, FormFragment, FormattedNumber, Loader, MultipleLineChart,
  Pagination, StackedChart, TimelineGraphContainer, Value,
  createValueRenderer, durationRenderer, hasBlueprintPermissions, interpolateRoute, notifier, parseTimestamp,
  refetchExcludeParametersComparer, request, withRouter} from 'apstra-ui-common';

import {
  NODE_ROLES, DEFAULT_STAGE_DATA_SOURCE,
  WIDGET_TYPE_STAGE, STAGE_DATA_SOURCE,
  DEFAULT_STAGE_TIME_SERIES_DURATION,
  DEFAULT_STAGE_TIME_SERIES_AGGREGATION,
  OPTICAL_PROBE_METRICS, STAGE_DYNAMIC_POPUP_MESSAGE,
} from '../consts';
import {sortingToQueryParam, filtersToQueryParam} from '../../queryParamUtils';
import {
  getStageSorting, processorHasRaiseAnomaliesFlag, getOutputNameByStageName, processorCanRaiseAnomalies,
  getStageFormSchema, getStageDataSchema, sortStagePropertyNames, getInputStages, getProcessorByStageName,
  getStageRenderingStrategy, processorCanRaiseWarnings, getTelemetryServiceSchema, getBaseRenderingStrategy,
  getValueAggregationTypes, getPossibleAggregationTypes,
} from '../stageUtils';
import generateProbeURI from '../generateProbeURI';
import {rangeProcessorUtils} from '../processorUtils';
import {anomalyColors, discreteStateColors} from '../../graphColors';
import AnomalyValues from './AnomalyValues';
import {descriptionRenderer} from '../descriptionRenderer';
import {tagsRenderer} from '../../components/TagsInput';
import checkForPatterns from '../checkForPatterns';
import DiscreteStateLegend from './graphs/DiscreteStateLegend';
import AnomalyHistoryGraphLegend from './graphs/AnomalyHistoryGraphLegend';
import SingleHorizontalBar from './graphs/SingleHorizontalBar';
import LineChart from '../../components/graphs/LineChart';
import DiscreteStateTimeline from '../../components/graphs/DiscreteStateTimeline';
import EventTimeline from '../../components/graphs/EventTimeline';
import AggregationInput from '../../components/AggregationInput';
import {renderSeconds, renderSpeed} from '../commonRenderers';
import DiscreteStateTimelineWithSamples from './graphs/DiscreteStateTimelineWithSamples';
import Gauge from './graphs/Gauge';
import TrafficDiagramContainer from './graphs/TrafficDiagramContainer';
import WidgetModal from './WidgetModal';
import StageSearchBox from './StageSearchBox';
import IBAContext from '../IBAContext';
import PersistedLabel from './PersistedLabel';
import humanizeString from '../../humanizeString';
import {ValueColumnNameInput} from './StageWidgetValueColumnNameInput';
import {TRAFFIC_PROBE_STAGE} from './graphs/trafficUtils';
import {unitsRenderer} from './UnitsInput';
import userStore from '../../userStore';
import {graphAnnotationPropertiesRenderer} from './GraphAnnotationPropertiesInput';
import AnomalousMetrics from './AnomalousMetrics';
import AggregationTypeInput from './AggregationTypeInput';

import {ReactComponent as StageDynamic} from '../../../styles/icons/iba/stage-dynamic.svg';

import './ProbeStage.less';

@observer
export default class ProbeStage extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    spotlightMode: false,
  };

  static pollingInterval = 10000;
  static userStoreKey = 'probeStage';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static get excludeNonImportantParameters() {
    return ['probe.anomaly_count', 'probe.stages', 'probe.probe_state', 'stage.anomaly_count'];
  }

  static async fetchData(args) {
    const {
      editable,
      blueprintId, probe, processor, stage,
      activePage, pageSize, filters, sorting,
      routes, signal
    } = args;
    if (editable || probe.disabled || probe.state === 'maintenance') return {};

    const patternDescription = checkForPatterns({probe, stageName: stage.name, dataSource: filters.dataSource});
    const usePattern = patternDescription && filters.showContextInfo;
    const stageFilter = filtersToQueryParam(
      (usePattern && isFunction(patternDescription.renderingStrategy.filterTransformer)) ?
      patternDescription.renderingStrategy.filterTransformer(filters.filter) : filters.filter
    );

    if (usePattern && isFunction(patternDescription.renderingStrategy?.shouldFetchData) &&
      !patternDescription.renderingStrategy.shouldFetchData(args)) {
      return {};
    }

    const body = {stage: stage.name};

    const queryParams = new URLSearchParams();
    if (activePage && pageSize && !(usePattern && patternDescription.renderingStrategy.noPagination)) {
      queryParams.set('page', activePage);
      queryParams.set('per_page', pageSize);
    }
    const sortingAsString = sortingToQueryParam(
      getStageSorting({sortingOverrides: sorting, propertyNames: keys(stage.keys)})
    );

    if (sortingAsString.length) body.order_by = sortingAsString;

    if (stageFilter) body.filter = stageFilter;
    if (patternDescription && !isEmpty(patternDescription.fetchDataForRelatedStages) && filters.showContextInfo) {
      body.include_stage = map(filter(
        patternDescription.fetchDataForRelatedStages, ({stageName}) => stageName !== stage.name
      ), 'stageName');
    }
    if (filters.anomalousOnly) body.anomalous_only = true;

    const now = moment();
    const {timeSeriesDuration} = filters;

    if (patternDescription && patternDescription.shouldFetchPersistedStageData && filters.showContextInfo ||
      (patternDescription?.shouldFetchRawPersistedStageData && filters.showContextInfo) ||
      filters.dataSource === STAGE_DATA_SOURCE.time_series) {
      body.begin_time =
        isNumber(timeSeriesDuration) ?
          now.clone().add(-timeSeriesDuration, 's').toISOString() : timeSeriesDuration?.start;
      body.end_time =
        (isNumber(timeSeriesDuration) ? null : timeSeriesDuration?.end) ?? now.toISOString();

      if (filters.timeSeriesAggregation && !patternDescription?.shouldFetchRawPersistedStageData) {
        const metrics = {[filters.valueColumnName]: filters.aggregationType};
        const stageAggregation = {
          period: filters.timeSeriesAggregation,
          metrics: filters.aggregationType === 'unset' ?
            {} : metrics,
        };
        body.aggregation = {
          [stage.name]: stageAggregation
        };
        reduce(
          filter(patternDescription?.fetchDataForRelatedStages, {hasPersistedData: true}),
          (acc, {stageName}) => {
            acc[stageName] = {period: filters.timeSeriesAggregation, metrics: {}};
            return acc;
          },
          body.aggregation);
      }

      if (patternDescription?.shouldIncludeStagesForTimeSeries) {
        body.include_stage = map(filter(
          patternDescription.fetchDataForRelatedStages, ({stageName}) => stageName !== stage.name
        ), 'stageName');
      }
    }

    if (processorCanRaiseAnomalies(processor)) {
      body.anomaly_context = true;
    }
    if (filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings) {
      if (body.order_by) queryParams.set('orderby', body.order_by);
      if (body.filter) queryParams.set('filter', body.filter);
    }

    const [{items: stageItems, total_count: totalCount},
      patternData, diskUsage] = (await Promise.allSettled([
      filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings ?
        request(
          interpolateRoute(routes.telemetryServiceWarnings, {blueprintId, probeId: probe.id, stageName: stage.name}),
          {signal, queryParams, method: 'GET'}
        ) :
        request(
          interpolateRoute(routes.probeStage, {blueprintId, probeId: probe.id}),
          {signal, queryParams, method: 'POST', body: JSON.stringify(body)}
        ),
      usePattern && patternDescription.fetchData ? patternDescription.fetchData({
        blueprintId, probe, processor, stage, routes, signal, filters
      }) : null,
      stage.enable_metric_logging ? request(interpolateRoute(routes.diskSpaceUsageForStage, {
        blueprintId, probeId: probe.id, stageName: stage.name
      })) : null,
    ])).map((response, index) => {
      if (response.status === 'fulfilled') {
        return response.value;
      }
      // diskUsage
      if (index === 2) {
        return null;
      }
      throw response.reason;
    });

    return {stageItems, totalCount, patternData, diskUsage};
  }

  @observable.ref widgetModalProps = null;

  @action
  setWidgetModalProps = (props) => {
    this.widgetModalProps = props;
  };

  render() {
    const {blueprintId, routes, blueprintPermissions} = this.context;
    const telemetryServiceWarnings = get(this.props.stage, ['warnings'], 0);
    const defaultDataSource = processorCanRaiseWarnings(this.props.processor) && telemetryServiceWarnings > 0 ?
      STAGE_DATA_SOURCE.telemetry_service_warnings : DEFAULT_STAGE_DATA_SOURCE;
    const {
      editable, compact,
      probe, processor, stage, stageData,
      actionInProgress, errors, knownTags,
      visibleColumns,
      filter,
      anomalousOnly = false,
      spotlightMode = false,
      patternDescription = checkForPatterns({probe, stageName: stage.name, dataSource: 'time_series'}),
      showContextInfo = !!patternDescription && !patternDescription.renderingStrategy?.hiddenContextByDefault,
      stageDataTableFooterContent,
      dataSource = defaultDataSource,
      timeSeriesDuration = DEFAULT_STAGE_TIME_SERIES_DURATION,
      timeSeriesAggregation = DEFAULT_STAGE_TIME_SERIES_AGGREGATION,
      combineGraphs = DEFAULT_COMBING_GRAPHS_MODE,
      valueColumnName = head(keys(stage.values)),
      aggregationType = getValueAggregationTypes(stage, valueColumnName, patternDescription),
    } = this.props;
    const isProbeOperational = probe?.state === 'operational';
    const defaultFilter = patternDescription?.renderingStrategy.defaultFilter || null;
    const defaultPageSize = userStore.getStoreValue([ProbeStage.userStoreKey, 'pageSize']);
    const setUserStoreProps = (value) => {
      if (value?.pageSize > 1) {
        userStore.setStoreValueFn(ProbeStage.userStoreKey)(value);
      }
    };

    return (
      <DataFilteringContainer
        stateQueryParam={compact ? null : 'stage-filter'}
        defaultFilters={{
          filter: filter || defaultFilter, dataSource, anomalousOnly, spotlightMode, showContextInfo,
          timeSeriesDuration, timeSeriesAggregation, valueColumnName, combineGraphs, aggregationType,
        }}
        defaultPageSize={spotlightMode ? 1 : defaultPageSize}
        setUserStoreProps={setUserStoreProps}
      >
        {({activePage, pageSize, updatePagination, filters, updateFilters, sorting, updateSorting}) => {
          const renderingStrategy = getStageRenderingStrategy({
            probe, processor, stage,
            dataSource: filters.dataSource,
            usePattern: filters.showContextInfo
          });
          const isTelemetryServiceWarningsDataSource =
            filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings;
          return (
            <FetchData
              customLoader
              pollingInterval={ProbeStage.pollingInterval}
              providedData={stageData}
              fetchData={ProbeStage.fetchData}
              fetchParams={{
                stageData,
                editable,
                blueprintId, probe, processor, stage,
                activePage, pageSize, filters, sorting,
                routes
              }}
              refetchComparer={refetchExcludeParametersComparer(ProbeStage.excludeNonImportantParameters)}
            >
              {({
                stageItems = [],
                totalCount = null,
                patternData,
                loaderVisible,
                fetchDataError,
                diskUsage,
              }) =>
                <Grid className='probe-stage'>
                  {!compact &&
                    <Fragment>
                      <Grid.Row>
                        <Grid.Column width={13}>
                          <Header className='probe-contents-header'>
                            {'Stage:'}
                            &nbsp;
                            {stage.name}
                            &nbsp;
                            {stage.dynamic &&
                              <Fragment>
                                <Popup
                                  trigger={
                                    <Label
                                      content='Dynamic'
                                      icon={<StageDynamic className='stage-icon' />}
                                    />
                                  }
                                  content={STAGE_DYNAMIC_POPUP_MESSAGE}
                                  wide
                                />
                                &nbsp;
                              </Fragment>
                            }
                            {!editable &&
                              <Fragment>
                                {stage.enable_metric_logging &&
                                  <Fragment>
                                    <PersistedLabel duration={stage.retention_duration} diskUsage={diskUsage} />
                                    &nbsp;
                                  </Fragment>
                                }
                                {tagsRenderer.renderValueWithoutCondition({value: stage.tags})}
                              </Fragment>
                            }
                          </Header>
                        </Grid.Column>
                        {!editable && isProbeOperational && !isTelemetryServiceWarningsDataSource &&
                          <Grid.Column width={3} textAlign='right'>
                            <ActionsMenu
                              items={[{
                                icon: 'apstra-icon apstra-icon-widget',
                                title: 'Create dashboard widget',
                                hasPermissions:
                                  hasBlueprintPermissions({blueprintPermissions, action: 'edit'}),
                                notPermittedMessage: 'You do not have permission to create',
                                onClick: () => this.setWidgetModalProps({
                                  open: true,
                                  widget: {
                                    type: WIDGET_TYPE_STAGE,
                                    label: stage.name,
                                    probe_id: probe.id,
                                    stage_name: stage.name,
                                    data_source: filters.dataSource,
                                    filter: (renderingStrategy?.filterSerializer || filtersToQueryParam)(
                                      filters.filter),
                                    anomalous_only: filters.anomalousOnly,
                                    show_context: filters.showContextInfo,
                                    spotlight_mode: filters.spotlightMode,
                                    orderby: sortingToQueryParam(sorting),
                                    aggregation_type: filters.aggregationType,
                                    aggregation_period: filters.timeSeriesAggregation,
                                    ...(isNumber(filters.timeSeriesDuration) &&
                                      {time_series_duration: filters.timeSeriesDuration}
                                    ),
                                    value_column_name: filters.valueColumnName,
                                    combine_graphs: filters.combineGraphs,
                                  }
                                }),
                              }]}
                            />
                            <WidgetModal
                              open={false}
                              onClose={() => this.setWidgetModalProps({open: false})}
                              probes={[probe]}
                              fixedWidgetType
                              fixedStage
                              {...this.widgetModalProps}
                            />
                          </Grid.Column>
                        }
                      </Grid.Row>
                      {!editable && stage.description &&
                        <Grid.Row>
                          <Grid.Column width={16}>
                            {descriptionRenderer.renderValueWithoutCondition({value: stage.description})}
                          </Grid.Column>
                        </Grid.Row>
                      }
                    </Fragment>
                  }
                  {!editable && VSphereAnomalyRemediator.anomalyRemediationPossible({
                    stage, routes, blueprintPermissions
                  }) &&
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <VSphereAnomalyRemediator
                          probe={probe}
                          stage={stage}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  }
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {editable ?
                        <StageEditor
                          stage={stage}
                          processor={processor}
                          knownTags={knownTags}
                          actionInProgress={actionInProgress}
                          errors={errors}
                        />
                      : probe.disabled ?
                        <Message
                          info
                          icon='info circle'
                          content='This probe is disabled.'
                        />
                      : probe.state === 'configuring' ?
                        <Message
                          info
                          icon='hourglass half'
                          content='This probe is configuring.'
                        />
                      : probe.state === 'maintenance' ?
                        <Message
                          info
                          icon='wrench'
                          content='This probe is in maintenance mode.'
                        />
                      : probe.state === 'error' &&
                        // FIXME(vkramskikh): this is a hack to show stage data for probes with telemetry warnings
                        // to be removed after proper "warning" state is introduced by the backend
                        // eslint-disable-next-line camelcase
                        probe.probe_state?.processors_configuration?.state !== 'operational' &&
                        probe.probe_state?.cachaca?.state !== 'operational'
                      ?
                        <Message
                          error
                          icon='warning sign'
                          content='This probe is in error state.'
                        />
                      :
                        <StageData
                          stage={stage}
                          stageItems={stageItems}
                          visibleColumns={visibleColumns}
                          probe={probe}
                          processor={processor}
                          compact={compact}
                          totalCount={totalCount}
                          activePage={activePage}
                          pageSize={pageSize}
                          filters={filters}
                          sorting={sorting}
                          updatePagination={updatePagination}
                          updateFilters={updateFilters}
                          updateSorting={updateSorting}
                          patternData={patternData}
                          stageDataTableFooterContent={stageDataTableFooterContent}
                          loaderVisible={loaderVisible}
                          fetchDataError={fetchDataError}
                        />
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              }
            </FetchData>
          );
        }
        }
      </DataFilteringContainer>
    );
  }
}

@observer
export class StageEditor extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get schema() {
    const {processorDefinitions} = this.context;
    const {stage, processor} = this.props;
    const processorDefinition = find(processorDefinitions, {name: processor.type});
    const outputName = getOutputNameByStageName({processor, stageName: stage.name});
    const stageDefinition = processorDefinition.outputs[outputName];
    return getStageFormSchema(processorDefinition, stage, stageDefinition);
  }

  @computed get errorMessagesByProperty() {
    const {stage, errors} = this.props;
    const propertyErrors = filter(errors, {type: 'stageProperty', stageName: stage.name});
    return transform(propertyErrors, (result, {propertyName, message}) => {
      if (!result[propertyName]) result[propertyName] = [];
      result[propertyName].push(...castArray(message));
    }, {});
  }

  @action
  onPropertyChange = (propertyName, value) => {
    const {stage, errors} = this.props;
    pullAllWith(errors, [{type: 'stageProperty', stageName: stage.name, propertyName}], isMatch);
    stage[propertyName] = value;
  };

  render() {
    const {stage, knownTags, actionInProgress} = this.props;
    return (
      <Table size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{'Properties'}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Form>
                <FormFragment
                  schema={this.schema}
                  values={stage}
                  renderers={[
                    descriptionRenderer.renderValueInput,
                    tagsRenderer.renderValueInput,
                    durationRenderer.renderValueInput,
                    unitsRenderer.renderValueInput,
                    graphAnnotationPropertiesRenderer.renderValueInput,
                  ]}
                  errors={this.errorMessagesByProperty}
                  disabled={actionInProgress}
                  onChange={this.onPropertyChange}
                  knownTags={knownTags}
                />
              </Form>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

@withRouter
@observer
export class StageData extends Component {
  static contextType = IBAContext;

  previousScrollPositionTop = null;
  spotlightViewRef = createRef();

  @observable highlightedColumn = null;

  @action highlightPropertyColumn = (column) => {
    if (column !== this.highlightedColumn) this.highlightedColumn = column;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loaderVisible && !this.props.loaderVisible) {
      if (!this.props.filters.spotlightMode && this.previousScrollPositionTop) {
        window.scrollTo({top: this.previousScrollPositionTop, behavior: 'smooth'});
        this.previousScrollPositionTop = null;
      }
      if (this.props.filters.spotlightMode && this.spotlightViewRef.current) {
        this.spotlightViewRef.current.focus();
      }
    }
  }

  storeScrollPosition = () => {
    this.previousScrollPositionTop = window.pageYOffset !== undefined ?
      window.pageYOffset
      :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;
  };

  @action
  switchToSpotlightMode = (itemId) => {
    this.storeScrollPosition();
    const {updatePagination, filters, activePage, pageSize} = this.props;
    this.updateFilters({...filters, spotlightMode: true});
    const page = (activePage - 1) * pageSize + findIndex(this.props.stageItems, (item) => item.id === itemId) + 1;
    updatePagination({activePage: page, pageSize: 1});
  };

  onSpotlightModeKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.switchToListMode();
    }
  };

  @action
  switchToListMode = () => {
    const {updatePagination, filters, activePage} = this.props;
    this.updateFilters({...filters, spotlightMode: false});
    const page = Math.floor(activePage / DEFAULT_PAGE_SIZE) + 1;
    updatePagination({activePage: page, pageSize: DEFAULT_PAGE_SIZE});
  };

  @computed get anomaliesByItems() {
    const {stageItems, processor} = this.props;
    if (!processorCanRaiseAnomalies(processor)) {
      return {};
    }

    return transform(stageItems, (result, item) => {
      const {actual_value: actualValue, anomalous_value: anomalyValue,
        anomalous_value_min: valueMin, anomalous_value_max: valueMax, value} = item;
      const anomaly = {actual: {value: actualValue}, anomalous: {}};
      if (anomalyValue) anomaly.anomalous.value = anomalyValue;
      if (isNumber(valueMin)) anomaly.anomalous.value_min = valueMin;
      if (isNumber(valueMax)) anomaly.anomalous.value_max = valueMax;
      if (value === 'true') {
        result[item.id] = anomaly;
      }
    }, {});
  }

  @computed get stageHasPersistedData() {
    return this.props.stage.enable_metric_logging;
  }

  @computed get patternDescription() {
    const {probe, stage, filters} = this.props;
    return checkForPatterns({probe, stageName: stage.name, dataSource: filters.dataSource});
  }

  @computed get usePatternWithPersistedData() {
    const {patternDescription, props: {filters}} = this;
    return patternDescription && patternDescription.shouldFetchPersistedStageData && filters.showContextInfo;
  }

  @computed get usePatternWithRawPersistedData() {
    const {patternDescription} = this;
    return patternDescription && patternDescription.shouldFetchRawPersistedStageData;
  }

  @computed get valueColumnName() {
    const {filters: {valueColumnName}, stage} = this.props;
    return valueColumnName || head(keys(stage.values));
  }

  @computed get tableSchema() {
    const {processorDefinitions, blueprintId} = this.context;
    const {stage, probe, stageItems, processor, visibleColumns, filters} = this.props;
    const {renderingStrategy, valueColumnName, stageGraphColors} = this;
    const isTelemetryServiceWarningsDataSource = filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings;
    const tableSchema = isTelemetryServiceWarningsDataSource ?
      getTelemetryServiceSchema(stageItems) :
      getStageDataSchema({
        stage,
        processor,
        renderingStrategy,
        processorDefinitions,
        showValue: !filters.spotlightMode && !isTelemetryServiceWarningsDataSource,
        valueColumnName: filters.dataSource === STAGE_DATA_SOURCE.time_series ? valueColumnName : null,
      });
    forEach(tableSchema, (column) => {
      const value = stage.values[column.name];
      if (column.name === 'anomaly') {
        column.value = ({item}) => item.id in this.anomaliesByItems;
        column.formatter = ({item}) => <AnomalyValues anomaly={this.anomaliesByItems[item.id]} />;
      } else if (column.name === 'warning') {
        column.formatter = ({value}) => <TelemetryServiceStatus warning={value} />;
      } else if (column.name in stage.values) {
        const renderValueAs = isFunction(renderingStrategy.renderValueAs) ?
          renderingStrategy.renderValueAs(value) :
          renderingStrategy.renderValueAs;
        column.formatter = this.valueRenderers[renderValueAs ?? 'primitiveValue'];
      } else if (column.name === 'timestamp') {
        column.value = ({item}) => parseTimestamp(item.timestamp);
        column.formatter = ({value}) => <DateFromNow date={value} />;
      } else if (column.name === 'anomalous_metrics') {
        column.value = ({item: {preceding_items: precedingItems} = {}}) => {
          const metrics = [];
          forEach(precedingItems, (stageItems, stageName) => {
            forEach(stageItems, (properties) => {
              forEach(properties, (value, key) => {
                if (value === 'true') {
                  const lane = get(properties, ['properties', 'lane']);
                  metrics.push({
                    lane,
                    link: generateProbeURI({
                      blueprintId,
                      stageName,
                      probeId: probe.id,
                      queryParams: {
                        filters: {
                          filter: {
                            'properties.lane': lane,
                            'properties.interface': get(properties, ['properties', 'interface']),
                            'properties.system_id': get(properties, ['properties', 'system_id']),
                          }
                        }
                      },
                    }),
                    label: humanizeString(key),
                  });
                }
              });
            });
          });
          return metrics;
        };
        column.formatter = ({value}) => {
          return <AnomalousMetrics value={value} />;
        };
      } else {
        column.formatter = this.renderProperty;
      }
      column.description = column.type ?
        <Fragment>
          <div>
            <strong>{'Name: '}</strong>
            {column.name}
          </div>
          <div>
            <strong>{'Type: '}</strong>
            {column.type}
          </div>
          {value && !isEmpty(stage.units[column.name]) && (
            <div>
              <strong>{'Units: '}</strong>
              {stage.units[column.name]}
            </div>
          )}
          {!isEmpty(value?.description) && (
            <div>
              <strong>{'Description: '}</strong>
              {castArray(value.description).join(', ')}
            </div>
          )
          }
          {!isEmpty(value?.possible_values) && (
            <div>
              <strong>{'Possible Values: '}</strong>
              <DiscreteStateLegend
                colors={stageGraphColors}
                possibleValues={value?.possible_values}
              />
            </div>
          )}
        </Fragment> :
        null;
    });
    if (isEmpty(visibleColumns)) {
      return tableSchema;
    } else {
      return compact(map(visibleColumns, (name) => find(tableSchema, {name})));
    }
  }

  @computed get stageGraphColors() {
    const {probe, stage} = this.props;
    let {processor} = this.props;

    let processorWithRaiseAnomalyFlag = null;
    const stages = [stage];
    do {
      processorWithRaiseAnomalyFlag = processorHasRaiseAnomaliesFlag(processor) ? processor : null;
      const inputStage = stages.pop();
      processor = getProcessorByStageName({probe, stageName: inputStage.name});
      stages.push(...getInputStages({probe, processor}));
    } while (!isEmpty(stages) && !processorWithRaiseAnomalyFlag);

    return processorWithRaiseAnomalyFlag && processorCanRaiseAnomalies(processorWithRaiseAnomalyFlag) ?
      anomalyColors : discreteStateColors;
  }

  // Common graph props

  maxValuesByItems = new WeakMap();

  getItemMaxValue(items) {
    if (this.maxValuesByItems.has(items)) return this.maxValuesByItems.get(items);
    const maxValue = max(map(items, 'value'));
    this.maxValuesByItems.set(items, maxValue);
    return maxValue;
  }

  sampleValueExtentByItems = new WeakMap();

  getItemSampleExtentValues(items) {
    const {valueColumnName} = this;
    if (this.sampleValueExtentByItems.has(items)) return this.sampleValueExtentByItems.get(items);
    let minValue;
    let maxValue = 0;
    for (const item of items) {
      if (item && item.persisted_samples) {
        for (const sample of item.persisted_samples) {
          const value = sample[valueColumnName];
          if (isUndefined(minValue) || value < minValue) minValue = value;
          if (value > maxValue) maxValue = value;
        }
      }
    }
    const result = [minValue, maxValue];
    this.sampleValueExtentByItems.set(items, result);
    return result;
  }

  getCorrespondingStageItemForStage = ({item, stage}) => {
    return head(item.preceding_items[stage.name]) || null;
  };

  getCorrespondingStageItemsForStages({item, stages}) {
    return map(stages, (stage) => this.getCorrespondingStageItemForStage({item, stage}));
  }

  correspondingStageItemMapping = new WeakMap();

  getAllCorrespondingStageItemsForStages({items, stages}) {
    const {correspondingStageItemMapping} = this;

    if (correspondingStageItemMapping.has(items)) {
      return correspondingStageItemMapping.get(items);
    }

    const result = map(stages, (stage) =>
      map(items, (item) => this.getCorrespondingStageItemForStage({item, stage}))
    );
    correspondingStageItemMapping.set(items, result);
    return result;
  }

  // Property Renderers

  renderProperty = ({name, value, item}) => (
    <Value
      name={name}
      value={value}
      item={item}
      renderers={[
        this.renderSystemId,
        this.renderEndpoint,
        renderSeconds.renderValue,
        renderSpeed.renderValue,
        this.renderVNI,
        this.renderSubnet,
      ]}
    />
  );

  renderChartPopupSystemIdLabel = createValueRenderer({
    condition: ({name}) => name === 'properties.system_id',
    renderValue: ({value: systemId}) => {
      const systemInfo = this.context.systemIdMap[systemId] ?? {};
      const {hostname, role} = systemInfo;
      return (
        <>
          {systemId}
          {!(isUndefined(hostname) && isUndefined(role)) && ' ('}
          {!isUndefined(hostname) && hostname}
          {!isUndefined(role) && (
            <>
              <div className='content-item-divider' />
              {role}
            </>
          )}
          {!(isUndefined(hostname) && isUndefined(role)) && ') '}
        </>
      );
    }
  }).renderValue;

  renderEndpoint = createValueRenderer({
    condition: ({name}) => name === 'properties.endpoint',
    renderValue: ({value}) => {
      return map((value || '').split('/'), (systemId, index) =>
        <SystemInfo key={index} systemId={systemId} />);
    }
  }).renderValue;

  renderVNI = createValueRenderer({
    condition: ({name}) => name === 'properties.vni',
    renderValue: ({value}) => {
      const {generateVnLink} = this.context;
      return generateVnLink ?
        <a href={generateVnLink({'vn-id': value})}>{value}</a> :
        value;
    }
  }).renderValue;

  renderSubnet = createValueRenderer({
    condition: ({name}) => name === 'properties.subnet',
    renderValue: ({value, item}) => {
      const addressFamily = get(item, ['properties', 'address_family']);
      const {generateVnLink} = this.context;
      const filter = {[`${addressFamily}-subnet`]: value};
      return generateVnLink ?
        <a href={generateVnLink(filter)}>{value}</a> :
        value;
    }
  }).renderValue;

  renderSystemId = createValueRenderer({
    condition: ({name}) => name === 'properties.system_id',
    renderValue: ({value}) => <SystemInfo systemId={value} />
  }).renderValue;

  // Value Renderers

  renderPrimitiveValue = ({item, name}) => (
    <div className='value-container'>
      <div>
        <Value value={item[name]} />
      </div>
    </div>
  );

  renderFormattedNumber = ({item, name, value}) => {
    const {stage} = this.props;
    if (!isFinite(value) || !stage.values[name]) return this.renderPrimitiveValue({item, name});
    const {[name]: units} = stage.units;
    return (
      <FormattedNumber value={value} units={units} />
    );
  };

  // N/NS

  renderHorizontalBar = ({item, name, items}) => {
    if (!isFinite(item.value)) return this.renderPrimitiveValue({item, name});
    const {props: {stage}, valueColumnName} = this;
    const {[valueColumnName]: units} = stage.units;
    return (
      <SingleHorizontalBar
        value={item.value}
        maxValue={this.getItemMaxValue(items)}
        units={units}
      />
    );
  };

  renderGauge = ({item, groupBy}, params) => {
    const {blueprintId} = this.context;
    const {probe, processor, navigate} = this.props;
    const referenceState = get(processor, ['properties', 'reference_state'], 'true');
    const inputColumn = head(map(processor?.inputs, ({column}) => column));

    const {patternDescription: {relatedStages}} = this;
    const sourceStage = relatedStages[0];

    const redirectToSourceStage = (e, operator) => {
      e.stopPropagation(); // to prevent opening spotlight view
      const properties = transform(groupBy, (acc, key) => {acc[key] = item.properties[key];}, {});
      const filter = join(
        compact([`${inputColumn}${operator}"${referenceState}"`, filtersToQueryParam(properties)]),
        ' and '
      );
      const queryParams = {filters: {filter}};
      navigate(generateProbeURI(
        {blueprintId, probeId: probe.id, stageName: sourceStage.name, queryParams}
      ));
    };

    const {rangeMin, rangeMax, minValue: originalMinValue = 0, maxValue: originalMaxValue, value} = params;
    const minValue = min([originalMinValue, rangeMin, rangeMax]);
    const maxValue = max([originalMaxValue, rangeMin, rangeMax, value]);

    return (
      <Gauge
        colors={this.stageGraphColors}
        onBaseArcClick={(e) => redirectToSourceStage(e, '!=')}
        onSpecialRangeArcClick={(e) => redirectToSourceStage(e, '=')}
        withValueArrow
        {...params}
        minValue={minValue}
        maxValue={maxValue}
        mode={this.props.filters.spotlightMode ? 'expanded' : 'compact'}
      />
    );
  };

  // N/NS (N out of M)

  renderGaugeCount = ({item, name}) => {
    if (!isFinite(item.value)) return this.renderPrimitiveValue({item, name});
    const {patternDescription: {relatedProcessors}, props: {stage}, valueColumnName} = this;
    const {[valueColumnName]: units} = stage.units;
    const [, matchProcessor] = relatedProcessors;
    const groupBy = matchProcessor.properties.group_by;
    const maxValue = item.properties.total_count || item.total_count;

    return this.renderGauge({item, groupBy}, {value: item.value, maxValue, units});
  };

  // N/NS (N out of M) + Range
  renderGaugeCountWithRange = ({item, name}) => {
    const {patternDescription: {relatedStages, relatedProcessors}, props: {processor}, valueColumnName} = this;
    const [, matchProcessor] = relatedProcessors;
    const groupBy = matchProcessor.properties.group_by;
    const [, matchCountStage] = relatedStages;
    const [, matchCountStageItem] = this.getCorrespondingStageItemsForStages({item, stages: relatedStages});
    const {[valueColumnName]: units} = matchCountStage.units;

    if (!isFinite(matchCountStageItem.value)) {
      return this.renderPrimitiveValue({item: matchCountStageItem, name});
    }

    const maxValue = matchCountStageItem.properties.total_count || matchCountStageItem.total_count;
    const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

    return this.renderGauge({item, groupBy}, {
      value: matchCountStageItem.value,
      maxValue,
      rangeMin,
      rangeMax,
      units,
    });
  };

  // N/NS (N out of M percent)

  renderGaugePercent = ({item, name}) => {
    if (!isFinite(item.value)) return this.renderPrimitiveValue({item, name});

    const {patternDescription: {relatedProcessors}, props: {stage}, valueColumnName} = this;
    const {[valueColumnName]: units} = stage.units;
    const [, matchProcessor] = relatedProcessors;
    const groupBy = matchProcessor.properties.group_by;

    return this.renderGauge({item, groupBy}, {value: item.value, maxValue: 100, units});
  };

  // N/NS (N out of M percent) + Range
  renderGaugePercentWithRange = ({item, name}) => {
    const {patternDescription: {relatedProcessors, relatedStages}, props: {processor}, valueColumnName} = this;
    const [, matchProcessor] = relatedProcessors;
    const groupBy = matchProcessor.properties.group_by;
    const [, matchPercentStage] = relatedStages;
    const [, matchPercentStageItem] = this.getCorrespondingStageItemsForStages({item, stages: relatedStages});
    const {[valueColumnName]: units} = matchPercentStage.units;

    if (!isFinite(matchPercentStageItem.value)) {
      return this.renderPrimitiveValue({item: matchPercentStageItem, name});
    }

    const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

    return this.renderGauge({item, groupBy}, {
      value: matchPercentStageItem.value,
      maxValue: 100,
      rangeMin,
      rangeMax,
      units,
    });
  };

  // DS/DSS

  renderDiscreteStateValue = ({item, name}) => {
    const {props: {stage}} = this;
    const {possible_values: possibleValues} = stage.values[name];
    const result = this.renderPrimitiveValue({item, name});
    const index = possibleValues.indexOf(item[name]);
    if (index !== -1) {
      const colorName = this.stageGraphColors[index];
      if (colorName) {
        return React.cloneElement(result, {className: `value-container graph-color-${colorName}`});
      }
    }
    return result;
  };

  // T/TS

  renderTextValue = ({item, name, value}) => {
    if (!isString(value)) return this.renderPrimitiveValue({item, name});
    return (
      <div className='value-container'>
        <div>
          <pre>
            <code>{value}</code>
          </pre>
        </div>
      </div>
    );
  };

  // NTS/NSTS

  renderLineChart = ({item, items, rangeMin, rangeMax, expanded = false}) => {
    const {props: {stage, filters: {combineGraphs}, visibleColumns}, valueColumnName} = this;
    const units = stage.units[valueColumnName];

    const stageKeys = isEmpty(visibleColumns) ?
      keys(stage.keys) :
      filter(keys(stage.keys), (key) =>
        (includes(visibleColumns, key) || includes(visibleColumns, `properties.${key}`)));
    const sortedKeys = sortStagePropertyNames(stageKeys);

    const [originalMinValue, originalMaxValue] = this.getItemSampleExtentValues(items);

    const minValue = min([originalMinValue, rangeMin, rangeMax]);
    const maxValue = max([originalMaxValue, rangeMin, rangeMax]);

    if (combineGraphs !== COMBING_GRAPHS_MODE.NONE) {
      return (
        <TimelineGraphContainer
          items={items}
          itemSamplesPath='persisted_samples'
          useCurrentTimeAsTimelineEnd={false}
          expanded={expanded}
        >
          {combineGraphs === COMBING_GRAPHS_MODE.LINEAR ? (
            <MultipleLineChart
              popupContentItemKeys={sortedKeys}
              minValue={minValue}
              maxValue={maxValue}
              units={units}
              valueKeyName={valueColumnName}
              popupRenderers={[
                renderSeconds.renderValue,
                renderSpeed.renderValue,
                this.renderChartPopupSystemIdLabel
              ]}
            />
          ) : combineGraphs === COMBING_GRAPHS_MODE.STACKED ? (
            <StackedChart
              popupContentItemKeys={sortedKeys}
              units={units}
              valueKeyName={valueColumnName}
              popupRenderers={[
                renderSeconds.renderValue,
                renderSpeed.renderValue,
                this.renderChartPopupSystemIdLabel
              ]}
            />
          ) : null}
        </TimelineGraphContainer>
      );
    }

    return (
      <TimelineGraphContainer
        samples={item.persisted_samples}
        useCurrentTimeAsTimelineEnd={false}
        expanded={expanded}
      >
        <LineChart
          minValue={minValue}
          maxValue={maxValue}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
          colors={this.stageGraphColors}
          units={units}
          valueKeyName={valueColumnName}
        />
      </TimelineGraphContainer>
    );
  };

  // NTS/NSTS + Range

  renderLineChartWithRange = ({item, index, items, expanded = false}) => {
    const {patternDescription: {relatedStages}, props: {processor}} = this;
    const [nsSourceItems] = this.getAllCorrespondingStageItemsForStages({items, stages: relatedStages});
    const nsSourceItem = nsSourceItems[index];
    const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

    return nsSourceItem ? this.renderLineChart({
      item: nsSourceItem,
      items: nsSourceItems,
      rowKey: item.id,
      rangeMin, rangeMax,
      index, expanded, processor
    }) : null;
  };

  // DSTS/DSSTS

  renderDiscreteStateTimeline = ({item, expanded = false}) => {
    const {props: {stage, filters: {timeSeriesAggregation}}, valueColumnName} = this;
    const {possible_values: possibleValues} = stage.values[valueColumnName];
    return (
      <TimelineGraphContainer
        samples={item.persisted_samples}
        expanded={expanded}
        timeSeriesAggregation={timeSeriesAggregation}
        useCurrentTimeAsTimelineEnd={timeSeriesAggregation === 0}
      >
        <DiscreteStateTimeline
          possibleValues={possibleValues}
          colors={this.stageGraphColors}
          valueKeyName={valueColumnName}
        />
      </TimelineGraphContainer>
    );
  };

  // DSTS/DSSTS

  renderDiscreteStateTimelineWithLegend = ({item, expanded = false}) => {
    const {
      patternDescription: {relatedStages, relatedProcessors},
      props: {stage},
      context: {processorDefinitions},
      valueColumnName,
    } = this;
    const {possible_values: possibleValues} = stage.values[valueColumnName];

    const [nsProcessor, rangeProcessor, timeInStateProcessor] = relatedProcessors;

    const [nsSourceItem, rangeSourceItem] =
      this.getCorrespondingStageItemsForStages({item, stages: relatedStages});

    const processorDefinition = find(processorDefinitions, {name: nsProcessor.type});
    const counterTypeSchema = processorDefinition.schema.properties.counter_type;
    const counterTypeName = nsProcessor.properties.counter_type;

    let timeLineSamples = item.persisted_samples;
    if (!timeLineSamples && item.value) {
      timeLineSamples = [{
        timestamp: item.timestamp,
        value: item.value,
      }];
    }

    const numericValue = nsSourceItem?.value;
    const lastSample = last(timeLineSamples);
    const sustainedAnomalyValue = lastSample?.value;
    const instantaneousAnomalyValue = rangeSourceItem?.value;

    return (
      <TimelineGraphContainer
        samples={timeLineSamples}
        expanded={expanded}
        popupIntervalPrefix='Time in State: '
      >
        {(childProps) => (
          <Fragment>
            <DiscreteStateTimeline {...childProps} possibleValues={possibleValues} colors={this.stageGraphColors} />
            <AnomalyHistoryGraphLegend
              counterType={counterTypeName ? <Value value={counterTypeName} schema={counterTypeSchema} /> : null}
              lastSampleTime={last(timeLineSamples)?.timestamp}
              numericValue={numericValue}
              sustainedAnomalyValue={sustainedAnomalyValue}
              instantaneousAnomalyValue={instantaneousAnomalyValue}
              possibleValues={possibleValues}
              relatedProcessors={{rangeProcessor, timeInStateProcessor}}
              relatedItems={{rangeSourceItem, timeInStateSourceItem: item}}
              colors={this.stageGraphColors}
            />
          </Fragment>
        )}
      </TimelineGraphContainer>
    );
  };

  // DSTS/DSSTS

  renderDiscreteStateTimelineWithSamples = ({item, index, items, expanded = false, rowKey = item.id}) => {
    const {
      patternDescription: {relatedStages, relatedProcessors},
      context: {processorDefinitions}, stageGraphColors,
      props: {stage},
      valueColumnName
    } = this;
    const {possible_values: possibleValues} = stage.values[valueColumnName];
    const units = stage.units[valueColumnName];

    const correspondingSourceStageItems = this.getAllCorrespondingStageItemsForStages({items, stages: relatedStages});
    const [nsSourceItems] = correspondingSourceStageItems;
    const [minValue, maxValue] = this.getItemSampleExtentValues(nsSourceItems);

    const graphProps = {correspondingSourceStageItems, expanded, index, item, maxValue, minValue,
      possibleValues, processorDefinitions, relatedProcessors, rowKey,
      units, colors: stageGraphColors};

    return (
      <DiscreteStateTimelineWithSamples {...graphProps} />
    );
  };

  // TTS/TSTS

  renderEventTimeline = ({item, expanded = false}) => {
    return (
      <TimelineGraphContainer
        samples={item.persisted_samples}
        expanded={expanded}
      >
        <EventTimeline valueKeyName={this.valueColumnName} />
      </TimelineGraphContainer>
    );
  };

  // Stage data renderers

  renderDeviceTraffic = () => {
    const {filters, stage, stageItems, patternData, probe} = this.props;
    const averageInterfaceCountersStage = find(
      probe?.stages,
      {name: TRAFFIC_PROBE_STAGE.averageInterfaceCounters}
    );
    return (
      <TrafficDiagramContainer
        stage={stage}
        stageItems={stageItems}
        patternData={patternData}
        sourceNodeId={filters?.filter?.sourceId}
        destinationNodeId={filters?.filter?.targetId}
        systemValuesSchema={toJS(stage?.values)}
        interfaceValuesSchema={toJS(averageInterfaceCountersStage?.values)}
      />
    );
  };

  // EVPN routes

  renderRoutesStatus = ({item}) => {
    const color = item.value === 'Missing' ? 'red' : item.value === 'Expected' ? 'green' : null;
    return (
      <div className={cx('value-container', {[`graph-color-${color}`]: !!color})}>
        <div>
          <Value value={item.value} />
        </div>
      </div>
    );
  };

  renderAnomalousOpticalMetric = (value) => {
    const {props: {stage, filters: {dataSource}}, valueColumnName} = this;
    const {name, item, items, expanded} = value;
    const warnColor = 'yellow';
    const alarmColor = 'red';

    if (!includes(OPTICAL_PROBE_METRICS, valueColumnName)) {
      const valueSchema = stage.values[valueColumnName];
      const {renderValueAs} = getBaseRenderingStrategy(dataSource, stage);
      const renderType = isFunction(renderValueAs) ? renderValueAs(valueSchema) : renderValueAs;
      const formatter = this.valueRenderers[renderType ?? 'primitiveValue'];
      return formatter(value);
    }

    const sourceThresholdItem = has(item, ['preceding_items', 'Interface Stats']) ?
      get(item, ['preceding_items', 'Interface Stats', '0']) :
      item;
    const [lowWarn, highWarn] = [
      get(sourceThresholdItem, [`${name}_low_warn`]),
      get(sourceThresholdItem, [`${name}_high_warn`]),
    ];
    const [lowAlarm, highAlarm] = [
      get(sourceThresholdItem, [`${name}_low_alarm`]),
      get(sourceThresholdItem, [`${name}_high_alarm`]),
    ];
    const rangesByColor = {};
    rangesByColor[warnColor] = [
      {name: 'Warning High', borders: [highWarn, highAlarm], borderColor: warnColor, includeBorders: {min: true}},
      {name: 'Warning Low', borders: [lowAlarm, lowWarn], borderColor: warnColor, includeBorders: {max: true}},
    ];
    rangesByColor[alarmColor] = [
      {name: 'Alert High', borders: [highAlarm, null], borderColor: alarmColor, includeBorders: {min: true}},
      {name: 'Alert Low', borders: [null, lowAlarm], borderColor: alarmColor, includeBorders: {max: true}},
    ];

    const units = stage.units[name];
    const [originalMinValue, originalMaxValue] = this.getItemSampleExtentValues(items);
    const minValue = min([originalMinValue, lowAlarm]);
    const maxValue = max([originalMaxValue, highAlarm]);

    return (
      <TimelineGraphContainer
        samples={item.persisted_samples}
        useCurrentTimeAsTimelineEnd={false}
        expanded={expanded}
      >
        <LineChart
          minValue={minValue}
          maxValue={maxValue}
          rangesByColor={rangesByColor}
          colors={anomalyColors}
          units={units}
          valueKeyName={valueColumnName}
          showRangeInfoInPopup
        />
      </TimelineGraphContainer>
    );
  };

  // Renderer mapping

  valueRenderers = {
    formattedNumberValue: this.renderFormattedNumber,
    primitiveValue: this.renderPrimitiveValue,
    textValue: this.renderTextValue,
    discreteStateValue: this.renderDiscreteStateValue,
    horizontalBar: this.renderHorizontalBar,
    discreteStateTimeline: this.renderDiscreteStateTimeline,
    discreteStateTimelineWithLegend: this.renderDiscreteStateTimelineWithLegend,
    discreteStateTimelineWithSamples: this.renderDiscreteStateTimelineWithSamples,
    lineChart: this.renderLineChart,
    lineChartWithRange: this.renderLineChartWithRange,
    eventTimeline: this.renderEventTimeline,
    gaugeCount: this.renderGaugeCount,
    gaugeCountWithRange: this.renderGaugeCountWithRange,
    gaugePercent: this.renderGaugePercent,
    gaugePercentWithRange: this.renderGaugePercentWithRange,
    routesStatus: this.renderRoutesStatus,
    anomalousOpticalMetric: this.renderAnomalousOpticalMetric,
  };

  stageDataRenderers = {
    deviceTraffic: this.renderDeviceTraffic,
  };

  @computed get renderingStrategy() {
    const {props: {probe, stage, processor, filters: {dataSource, showContextInfo}}} = this;
    return getStageRenderingStrategy({
      probe, processor, stage,
      dataSource, usePattern: showContextInfo,
    });
  }

  @computed get renderValueAs() {
    const {renderingStrategy, valueColumnName, props: {stage: {values}}} = this;
    const value = values[valueColumnName];
    return isFunction(renderingStrategy.renderValueAs) ?
      renderingStrategy.renderValueAs(value) :
      renderingStrategy.renderValueAs;
  }

  @computed get mayCombineGraphs() {
    const {spotlightMode} = this.props.filters;
    const isGraphSupportCombine = this.renderValueAs === 'lineChart' ||
      (this.renderValueAs === 'anomalousOpticalMetric' && !includes(OPTICAL_PROBE_METRICS, this.valueColumnName));
    return !spotlightMode && isGraphSupportCombine;
  }

  @computed get maySwitchSpotlightMode() {
    return !this.props.compact;
  }

  @computed get stageDataSourceIsTimeSeries() {
    return this.props.filters.dataSource === STAGE_DATA_SOURCE.time_series;
  }

  @computed get customRows() {
    const {spotlightMode} = this.props.filters;
    return transform(this.props.stageItems, (result, item) => {result[item.id] = spotlightMode;}, {});
  }

  @computed get stageOptions() {
    const {patternDescription, stageDataSourceIsTimeSeries, props: {filters, processor}} = this;
    return [
      {
        name: 'Anomalies Only',
        isAvailable: processorCanRaiseAnomalies(processor) && !stageDataSourceIsTimeSeries,
        getUpdateOptions: (isSelected) => ({filters: {anomalousOnly: isSelected}}),
        isSelected: filters.anomalousOnly
      },
      {
        name: 'Show Context',
        isAvailable: !!patternDescription &&
          !patternDescription.renderingStrategy?.alwaysUseContext &&
          !stageDataSourceIsTimeSeries,
        getUpdateOptions: (isSelected) => {
          if (!isSelected && patternDescription?.renderingStrategy?.alwaysUseContext) return {};
          return {filters: {showContextInfo: isSelected}};
        },
        isSelected: filters.showContextInfo
      }
    ];
  }

  @computed get availableOptions() {
    return map(filter(this.stageOptions, {isAvailable: true}), 'name');
  }

  @computed get selectedOptions() {
    return map(filter(this.stageOptions, {isSelected: true}), 'name');
  }

  @computed get aggregationTypeOptions() {
    const {stage, filters} = this.props;
    return map(getPossibleAggregationTypes(stage, filters.valueColumnName), (type) => ({
      key: type,
      text: type,
      value: type,
    }));
  }

  @action
  applyStageOptions = (value) => {
    const {props: {filters, updatePagination}, stageOptions, updateFilters} = this;
    const updateOptions = {};
    forEach(stageOptions, (option) => {
      if (option.isAvailable) {
        const isSelected = value.includes(option.name);
        if (isSelected !== option.isSelected) {
          merge(updateOptions, option.getUpdateOptions(isSelected));
        }
      } else if (option.isSelected) {
        merge(updateOptions, option.getUpdateOptions(false));
      }
    });
    if (updateOptions.filters) {
      const newFilters = {...filters, ...updateOptions.filters};
      updateFilters(newFilters);
    }
    if (updateOptions.pagination) {
      updatePagination(updateOptions.pagination);
    }
  };

  updateFilters = (newFilters) => {
    const {props: {filters}, patternDescription} = this;
    const processedFilters = {...newFilters};
    if (patternDescription?.renderingStrategy?.clearFilterOnContextTrigger &&
      has(newFilters, 'showContextInfo') &&
      filters?.showContextInfo !== newFilters?.showContextInfo
    ) {
      processedFilters.filter = {};
    }
    this.props.updateFilters(processedFilters);
  };

  @action
  onDataSourceChange = (value) => {
    const {props: {probe, stage, filters}, updateFilters} = this;
    const patternDescription = checkForPatterns({probe, stageName: stage.name, dataSource: value});
    const changes = {dataSource: value};
    if (value === STAGE_DATA_SOURCE.telemetry_service_warnings ||
      filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings) {
      if (filters.filter) changes.filter = {};
    }
    if (value === STAGE_DATA_SOURCE.time_series) {
      if (!filters.timeSeriesDuration) {
        changes.timeSeriesDuration = DEFAULT_STAGE_TIME_SERIES_DURATION;
      }
      if (filters.showContextInfo && !patternDescription?.renderingStrategy?.alwaysUseContext) {
        changes.showContextInfo = false;
      }
    } else if (value === STAGE_DATA_SOURCE.telemetry_service_warnings) {
      if (filters.spotlightMode) {
        changes.spotlightMode = false;
      }
    } else if (patternDescription) {
      changes.showContextInfo = !patternDescription.renderingStrategy?.hiddenContextByDefault;
    }
    updateFilters({...filters, ...changes});
  };

  @action
  onUpdateValueColumnName = (valueColumnName) => {
    const {props: {filters, stage}, updateFilters, patternDescription} = this;
    const aggregationType = getValueAggregationTypes(stage, valueColumnName, patternDescription);
    updateFilters({...filters, valueColumnName, aggregationType});
  };

  getDataTableCellProps = ({name, item}) => {
    const {noSpotlightMode} = this.renderingStrategy;
    const {stage} = this.props;
    const possibleValues = get(stage.values, [name, 'possible_values'], []);
    const valueCell = (name === 'value' || (name in stage.values && !isEmpty(possibleValues)));
    const expandableValueCell = name in stage.values && !noSpotlightMode;
    return ({
      onClick: expandableValueCell && this.maySwitchSpotlightMode ? () => this.switchToSpotlightMode(item.id) : null,
      className: cx({'value-cell': valueCell, expandable: expandableValueCell,
        highlight: this.highlightedColumn === name})
    });
  };

  getSpotlightModeDataTableProps = ({name, params}) => ({
    label: params.labels[name]
  });

  renderSpotlightItemValue = () => {
    const {props: {stageItems}, valueColumnName} = this;
    const {values} = this.props.stage;
    const value = values[valueColumnName];
    const renderValueAs = isFunction(this.renderingStrategy.renderValueAs) ?
      this.renderingStrategy.renderValueAs(value) :
      this.renderingStrategy.renderValueAs;
    const renderValue = this.valueRenderers[renderValueAs ?? 'primitiveValue'];
    return renderValue({item: stageItems[0], name: valueColumnName, index: 0, items: stageItems, expanded: true});
  };

  renderCombinedGraphs = () => {
    const {props: {stageItems}, valueColumnName} = this;
    const {values} = this.props.stage;
    const value = values[valueColumnName];
    const renderValueAs = isFunction(this.renderingStrategy.renderValueAs) ?
      this.renderingStrategy.renderValueAs(value) :
      this.renderingStrategy.renderValueAs;
    const renderValue = this.valueRenderers[renderValueAs ?? 'primitiveValue'];
    return renderValue({item: stageItems[0], index: 0, items: stageItems, expanded: true});
  };

  renderStageDataTableFooterContent = ({stageDataTableFooterContent, wrap}) => {
    const {tableSchema} = this;
    if (!stageDataTableFooterContent) return null;
    const footer = (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={tableSchema.length} textAlign='center'>
            {stageDataTableFooterContent}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
    return (wrap ? <Table size='small'>{footer}</Table> : footer);
  };

  render() {
    const {
      tableSchema,
      renderingStrategy, anomaliesByItems,
      onSpotlightModeKeyDown, spotlightViewRef,
      renderStageDataTableFooterContent,
      stageHasPersistedData,
      stageDataSourceIsTimeSeries,
      onDataSourceChange,
      usePatternWithPersistedData,
      usePatternWithRawPersistedData,
      onUpdateValueColumnName, valueColumnName,
      renderCombinedGraphs, mayCombineGraphs,
      updateFilters, highlightPropertyColumn, highlightedColumn,
      aggregationTypeOptions,
      props: {
        compact, stage, processor,
        loaderVisible, fetchDataError,
        activePage, pageSize, updatePagination,
        filters,
        sorting, updateSorting,
        stageItems, totalCount,
        stageDataTableFooterContent
      }
    } = this;
    const {spotlightMode} = filters;
    const pageSizes = spotlightMode ? [1] : DEFAULT_PAGE_SIZES;
    const {noAnomalyHighlighting, noSearch, noPagination, renderStageDataAs = null,
      SearchComponent = StageSearchBox, noDataMessage} = renderingStrategy;
    const paginationProps = {
      activePage,
      pageSize,
      pageSizes,
      totalCount,
      onChange: updatePagination,
    };
    const valueColumnOptions = values(stage.values).map(({name, title}) => ({
      key: name,
      value: name,
      text: title || humanizeString(name),
    }));
    const dataSourceOptions = filter([
      {key: STAGE_DATA_SOURCE.real_time, value: STAGE_DATA_SOURCE.real_time, text: 'Real Time'},
      stageHasPersistedData ?
        {key: STAGE_DATA_SOURCE.time_series, value: STAGE_DATA_SOURCE.time_series, text: 'Time Series'} :
        null,
      processorCanRaiseWarnings(processor) ?
        {
          key: STAGE_DATA_SOURCE.telemetry_service_warnings,
          value: STAGE_DATA_SOURCE.telemetry_service_warnings,
          text: 'Telemetry Service Warnings'
        } :
        null
    ]);
    const isTimeSeriesControl = (stageDataSourceIsTimeSeries || usePatternWithPersistedData);
    const showPersistedDataControl = (
      stageHasPersistedData || usePatternWithPersistedData ||
      usePatternWithRawPersistedData || processorCanRaiseWarnings(processor)
    ) && (
      dataSourceOptions.length > 1 || mayCombineGraphs ||
        (isTimeSeriesControl && valueColumnOptions.length > 1) ||
        usePatternWithRawPersistedData
    );

    return (
      <Grid stackable>
        {!compact &&
          <Fragment>
            {showPersistedDataControl &&
              <Grid.Row>
                <Grid.Column>
                  <div className='stage-data-controls'>
                    {dataSourceOptions.length > 1 &&
                      <DropdownControl
                        value={filters.dataSource || DEFAULT_STAGE_DATA_SOURCE}
                        selectedValueLabel='Data source: '
                        options={dataSourceOptions}
                        onChange={onDataSourceChange}
                        className='data-source'
                      />}
                    {stageDataSourceIsTimeSeries && valueColumnOptions.length > 1 && (
                      <ValueColumnNameInput
                        options={valueColumnOptions}
                        value={valueColumnName}
                        onChange={(value) => onUpdateValueColumnName(value)}
                      />
                    )}
                    {mayCombineGraphs &&
                      <DropdownControl
                        value={filters.combineGraphs || DEFAULT_COMBING_GRAPHS_MODE}
                        selectedValueLabel=' '
                        options={[
                          {key: COMBING_GRAPHS_MODE.NONE, value: COMBING_GRAPHS_MODE.NONE,
                            text: 'Separate graphs'},
                          {key: COMBING_GRAPHS_MODE.LINEAR, value: COMBING_GRAPHS_MODE.LINEAR,
                            text: 'Combine graphs: Linear'},
                          {key: COMBING_GRAPHS_MODE.STACKED, value: COMBING_GRAPHS_MODE.STACKED,
                            text: 'Combine graphs: Stacked'},
                        ]}
                        onChange={(value) => updateFilters({...filters, combineGraphs: value})}
                      />
                    }
                    {usePatternWithRawPersistedData && (
                      <DurationInput
                        value={filters.timeSeriesDuration}
                        customValueType='dates'
                        onChange={(timeSeriesDuration) => updateFilters(
                          {...filters, timeSeriesDuration}, {resetActivePage: false})}
                      />
                    )}
                  </div>
                </Grid.Column>
              </Grid.Row>
            }
            {isTimeSeriesControl && !usePatternWithRawPersistedData &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className='stage-aggregation-controls'>
                    <AggregationTypeInput
                      selectedValueLabel='Aggregation type: '
                      value={filters.aggregationType}
                      onChange={(aggregationType) => updateFilters(
                        {
                          ...filters,
                          aggregationType,
                          timeSeriesAggregation: aggregationType === 'none' ? 0 : filters.timeSeriesAggregation
                        },
                        {resetActivePage: false}
                      )}
                      options={aggregationTypeOptions}
                      clearable
                    />
                    <AggregationInput
                      value={filters.timeSeriesAggregation}
                      disabled={filters.aggregationType === 'none'}
                      selectedValueLabel='Aggregation: '
                      onChange={(timeSeriesAggregation) => updateFilters(
                        {...filters, timeSeriesAggregation}, {resetActivePage: false})}
                    />
                    <DurationInput
                      value={filters.timeSeriesDuration}
                      customValueType='dates'
                      onChange={(timeSeriesDuration) => updateFilters(
                        {...filters, timeSeriesDuration}, {resetActivePage: false})}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            }
            {this.availableOptions.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className='options'>
                    {map(this.availableOptions, (option) => (
                      <Checkbox
                        key={option}
                        label={option}
                        checked={includes(this.selectedOptions, option)}
                        onChange={() => this.applyStageOptions(xor(this.selectedOptions, [option]))}
                      />
                    ))}
                  </div>
                </Grid.Column>
              </Grid.Row>
            }
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='stage-filtering-controls'>
                  <SearchComponent
                    filters={filters.filter}
                    stage={stage}
                    stageItems={stageItems}
                    processor={processor}
                    dataSource={filters.dataSource}
                    disabled={noSearch}
                    onChange={(filter) => updateFilters({...filters, filter})}
                    highlightPropertyColumn={highlightPropertyColumn}
                    aria-label='Stage filter query'
                  />
                  {!spotlightMode && !noPagination &&
                    <Pagination {...paginationProps} />
                  }
                </div>
              </Grid.Column>
            </Grid.Row>
          </Fragment>
        }
        <Grid.Row>
          <Grid.Column>
            {loaderVisible ?
              <Loader />
            : fetchDataError ?
              <FetchDataError error={fetchDataError} />
            : !stageItems.length ?
              <Fragment>
                {filters.anomalousOnly ?
                  <Message success icon='check circle' header='No anomalies!' />
                :
                  <Message info icon='info circle' content={noDataMessage || 'No data.'} />
                }

                {renderStageDataTableFooterContent({stageDataTableFooterContent, wrap: true})}
              </Fragment>
            : renderStageDataAs ?
              <Fragment>
                {this.stageDataRenderers[renderStageDataAs]()}
                {renderStageDataTableFooterContent({stageDataTableFooterContent, wrap: true})}
              </Fragment>
            :
              <div className='item-set-container'>
                {spotlightMode ? // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div
                    ref={spotlightViewRef}
                    tabIndex={-1}
                    onKeyDown={onSpotlightModeKeyDown}
                    className='spotlight-mode-container'
                  >
                    <div className='spotlight-mode-item item-set'>
                      <div className='spotlight-mode-item-value'>
                        {this.renderSpotlightItemValue()}
                        {this.maySwitchSpotlightMode &&
                          <Icon link name='close' onClick={this.switchToListMode} />
                        }
                      </div>
                      <Table definition size='small'>
                        <Table.Body>
                          <DataTableRowFragment
                            schema={this.tableSchema}
                            item={stageItems[0]}
                            params={transform(
                              this.tableSchema,
                              (result, schemaItem) => {
                                result.labels[schemaItem.name] = schemaItem.label;
                              },
                              {labels: {}}
                            )}
                            getCellProps={this.getSpotlightModeDataTableProps}
                            CellComponent={SpotlightKeysTableRow}
                          />
                          {stageDataTableFooterContent &&
                            <Table.Row>
                              <Table.Cell colSpan={this.tableSchema.length} textAlign='center'>
                                {stageDataTableFooterContent}
                              </Table.Cell>
                            </Table.Row>
                          }
                        </Table.Body>
                      </Table>
                    </div>
                    <div className='pagination-centered'>
                      <Pagination separateButtons {...paginationProps} />
                    </div>
                  </div>
                  :
                  mayCombineGraphs && filters.combineGraphs !== COMBING_GRAPHS_MODE.NONE ?
                    <Fragment>
                      {renderCombinedGraphs()}
                      {renderStageDataTableFooterContent({stageDataTableFooterContent, wrap: true})}
                    </Fragment>
                  :
                    <DataTable
                      className='item-set'
                      size='small'
                      items={stageItems}
                      schema={tableSchema}
                      sortable={!compact}
                      sorting={sorting}
                      updateSorting={updateSorting}
                      getHeaderCellProps={({name}) => ({
                        collapsing: !(name in stage.values),
                        className: cx({highlight: name === highlightedColumn})
                      })}
                      getCellProps={this.getDataTableCellProps}
                      getRowProps={({item}) => ({
                        warning: !isEmpty(item.warning),
                        error: !noAnomalyHighlighting && item.id in anomaliesByItems,
                      })}
                      getItemKey={({id}) => id}
                      footer={
                        renderStageDataTableFooterContent({stageDataTableFooterContent, wrap: false})
                      }
                      params={{highlightedColumn}}
                    />
                }
              </div>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

function SpotlightKeysTableRow({children, label}) {
  return (
    <Table.Row role='row'>
      <Table.Cell role='cell' collapsing>
        {label}
      </Table.Cell>
      <Table.Cell role='cell' collapsing>
        {children}
      </Table.Cell>
    </Table.Row>
  );
}

class SystemInfo extends PureComponent {
  static contextType = IBAContext;

  render() {
    const {systemId} = this.props;
    const {systemIdMap, systemsHrefs} = this.context;
    const systemInfo = systemIdMap[systemId] ?? {};
    const {href, hostname, role} = systemInfo;
    const internalUrl = systemsHrefs ? systemsHrefs[systemId] : null;
    const systemIdHref = href ?? internalUrl;
    return [
      <div key='link'>
        {systemIdHref ?
          <a href={systemIdHref}>{systemId}</a> :
          systemId
        }
      </div>,
      hostname && <div key='hostname'>{hostname}</div>,
      role && <Label key='role' size='tiny'>{NODE_ROLES[role] ?? role}</Label>,
    ];
  }
}

export class TelemetryServiceStatus extends Component {
  render() {
    const {warning} = this.props;
    const warningType = warning ? warning.type : null;
    if (warningType === 'invalid_service') {
      return (
        <Fragment>
          <div><strong>{'Invalid service'}</strong></div>
          <div>{warning.message}</div>
        </Fragment>
      );
    } else if (warningType === 'conflict') {
      return (
        <Fragment>
          <div><strong>{'Conflict'}</strong></div>
          {map(['input', 'interval', 'execution_count'], (property) => {
            const expected = get(warning, ['expected', property], null);
            const actual = get(warning, ['actual', property], null);
            return expected !== actual ? (
              <Fragment key={property}>
                <div>{`Expected ${startCase(property)}: `}<b><Value value={expected} /></b></div>
                <div>{`Actual ${startCase(property)}: `}<b><Value value={actual} /></b></div>
              </Fragment>
            ) : null;
          })}
        </Fragment>
      );
    }
    return 'No warnings';
  }
}

@withRouter
@observer
export class VSphereAnomalyRemediator extends Component {
  static contextType = IBAContext;

  static stageNames = [
    'Fabric missing VLAN configs anomaly',
    'Hypervisor missing VLAN configs anomaly',
  ];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static anomalyRemediationPossible({stage, blueprintPermissions}) {
    return (
      hasBlueprintPermissions({blueprintPermissions, action: 'edit'}) &&
      includes(VSphereAnomalyRemediator.stageNames, stage.name) &&
      !!stage.anomaly_count
    );
  }

  @observable actionInProgress = false;

  @action
  resolveAnomalies = async () => {
    const {blueprintId, routes, anomalyRemediationUrl} = this.context;
    const {probe, stage} = this.props;
    let error = null;
    this.actionInProgress = true;
    try {
      await request(
        interpolateRoute(routes.vShpereAnomalyResolver, {blueprintId}),
        {method: 'POST', body: JSON.stringify({probe_id: probe.id, stage_name: stage.name})}
      );
    } catch (e) {
      error = e;
    }
    if (!error) {
      notifier.notify({message: 'Anomalies were remediated successfully'});
      window.location.href = `/#${anomalyRemediationUrl}`;
    } else {
      notifier.showError(error);
      runInAction(() => {
        this.actionInProgress = false;
      });
    }
  };

  render() {
    return (
      <Message info>
        <Message.Content>
          <Message.Header>{'Anomaly Remediation'}</Message.Header>
          <p>{'It is possible to automatically fix the anomalies.'}</p>
          <Button
            primary
            icon='wrench'
            labelPosition='left'
            content='Remediate Anomalies'
            disabled={this.actionInProgress}
            onClick={this.resolveAnomalies}
          />
        </Message.Content>
      </Message>
    );
  }
}
