import {Component} from 'react';
import {HashRouter as Router, Link} from 'react-router-dom';
import {List} from 'semantic-ui-react';
import {flatMap, has, map} from 'lodash';
import {DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, request} from 'apstra-ui-common';

import {sortingToQueryParam} from '../../queryParamUtils';
import wrapWithComponent from '../../wrapWithComponent';
import userStore from '../../userStore';

import './BlueprintQueryVM.less';

const tableSchema = [
  {
    name: 'vm_label',
    label: 'VM Name',
    value: ['vm_label'],
    sortable: true,
  },
  {
    name: 'hosted_on',
    label: 'Hosted On',
    value: ['hosted_on'],
    formatter: ({item, params: {blueprintId, hypervisors}}) =>
      has(hypervisors, [item.hypervisor_label, 'hosted_on', 'id']) ?
        <div>
          <div>{item.hypervisor_label}</div>
          <div>
            <span>{'('}</span>
            <Link
              to={`/blueprints/${blueprintId}/nodes/${hypervisors[item.hypervisor_label].hosted_on.id}/active/physical`}
              children={hypervisors[item.hypervisor_label].hosted_on.label}
            />
            <span>{')'}</span>
          </div>
        </div>
      :
        <span>{item.hypervisor_label}</span>
  },
  {
    name: 'hypervisor_hostname',
    label: 'Hypervisor Hostname',
    value: ['hypervisor_hostname'],
    sortable: true,
  },
  {
    name: 'hypervisor_version',
    label: 'Hypervisor Version',
    value: ['hypervisor_version'],
    sortable: true,
  },
  {
    name: 'vm_ips',
    label: 'VM IPs',
    value: ['vm_ips'],
    formatter: ({item}) =>
      <List>
        {item.vm_ips.map((vmIP, index) => <List.Item key={index}>{vmIP}</List.Item>)}
      </List>
  },
  {
    name: 'tor_switch',
    label: 'ToR Switch:Interface',
    value: ['tor_switch'],
    formatter: ({item}) =>
      <List>
        {item.tor_switch.map((e, index) => <List.Item key={index}>{e}</List.Item>)}
      </List>
  },
  {
    name: 'vnets',
    label: 'Port Group Name:VLAN ID',
    value: ['vnets'],
    formatter: ({item, params: {blueprintId, vns}}) =>
      <List>
        {flatMap(item.vnets, (e) =>
          <List.Item key={e.vnet_name}>
            <div>
              {e.vswitch_label}{':('}{
                map(e.vn_info, (vnInfo, i) =>
                  <>
                    {i !== 0 && ', '}
                    {has(vns, vnInfo.fabric_vn_id) ?
                      <Link
                        reloadDocument
                        to={`/blueprints/${blueprintId}/active/virtual/virtual-networks/${vnInfo.fabric_vn_id}`}
                        children={vns[vnInfo.fabric_vn_id].label}
                      />
                      :
                      <span>{vnInfo.vlan}</span>
                    }
                  </>
                )
              }{')'}
            </div>
          </List.Item>
        )}
      </List>
  },
  {
    name: 'mac_addresses',
    label: 'MAC Addresses',
    value: ['mac_addresses'],
    formatter: ({item}) =>
      <List>
        {item.mac_addresses.map((e, index) => <List.Item key={index}>{e}</List.Item>)}
      </List>
  },
  {
    name: 'virtual_infra_mgmt_ip',
    label: 'Virtual Infra Address',
    value: ['virtual_infra_mgmt_ip'],
    sortable: true,
  },
  {
    name: 'infra_type',
    label: 'Virtual Infra Type',
    value: ['infra_type'],
    sortable: true,
  },
];

const searchBoxSchema = [
  {
    name: 'vm_name',
    schema: {
      type: 'string',
      title: 'VM Name',
      description: (
        <span>
          {'Use '}<strong>{'*'}</strong>{' as a placeholder, e.g. '}<strong>{'my_vm*'}</strong>
        </span>
      )
    }
  }
];

@wrapWithComponent(Router)
export default class BlueprintQueryVM extends Component {
  static async fetchData({blueprintId, activePage, pageSize, filters, signal, sorting}) {
    const {vm_name: vmName = '^.*$'} = filters;
    const {items, total_count: totalCount} = await request(
      `/api/blueprints/${blueprintId}/virtual_infra/query/vm`,
      {
        method: 'POST',
        queryParams: {
          page: activePage,
          per_page: pageSize,
          type: 'operation',
          orderby: sortingToQueryParam(sorting)
        },
        body: JSON.stringify({vm_name: vmName}),
        signal
      }
    );
    return {items, totalCount};
  }

  static pollingInterval = 10000;
  static userStoreKey = 'blueprintQueryVM';

  render() {
    const {hypervisors, vns, blueprintId} = this.props;
    const defaultPageSize = userStore.getStoreValue([BlueprintQueryVM.userStoreKey, 'pageSize']);
    return (
      <div className='blueprint-query-vm-component'>
        <DataFilteringContainer
          stateQueryParam='blueprint-query-vm'
          defaultPageSize={defaultPageSize}
          setUserStoreProps={userStore.setStoreValueFn(BlueprintQueryVM.userStoreKey)}
        >
          {({activePage, pageSize, filters, updatePagination, updateFilters, sorting, updateSorting}) =>
            <FetchData
              fetchData={BlueprintQueryVM.fetchData}
              pollingInterval={BlueprintQueryVM.pollingInterval}
              fetchParams={{
                blueprintId,
                activePage,
                pageSize,
                filters,
                sorting
              }}
              customLoader
            >
              {({items, totalCount, loaderVisible, fetchDataError}) =>
                <DataFilteringLayout
                  loaderVisible={loaderVisible}
                  fetchDataError={fetchDataError}
                  totalCount={totalCount}
                  activePage={activePage}
                  pageSize={pageSize}
                  filters={filters}
                  searchBoxSchema={searchBoxSchema}
                  updatePagination={updatePagination}
                  updateFilters={updateFilters}
                >
                  <div className='vms-table-container'>
                    <DataTable
                      items={items}
                      schema={tableSchema}
                      params={{
                        hypervisors,
                        vns,
                        blueprintId
                      }}
                      sorting={sorting}
                      updateSorting={updateSorting}
                      noItemsMessage='No VMs found.'
                    />
                  </div>
                </DataFilteringLayout>
              }
            </FetchData>
          }
        </DataFilteringContainer>
      </div>
    );
  }
}
