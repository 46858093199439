import {Component, Fragment} from 'react';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Grid, Table, Button, Header, Label, Message} from 'semantic-ui-react';
import {zip, pick, map, filter, keys} from 'lodash';
import {ActionsMenu, Checkbox, DataFilter, DataFilteringContainerWithRouter as DataFilteringContainer,
  DataFilteringLayout, DataTable, FetchData, PermissionChecker,
  hasBlueprintPermissions, interpolateRoute, request} from 'apstra-ui-common';

import WidgetDeletionModal from './WidgetDeletionModal';
import generateProbeURI from '../generateProbeURI';
import {getStageQueryParamsFromWidget, getStageRenderingStrategy,
  getStageComponentPropsFromWidget} from '../stageUtils';
import {tagsRenderer} from '../../components/TagsInput';
import {WIDGET_TYPES, WIDGET_TYPE_ANOMALY_HEATMAP, WIDGET_TYPE_STAGE} from '../consts';
import Widget, {widgetComponentsByType} from './Widget';
import WidgetModal from './WidgetModal';
import WidgetActions from './WidgetActions';
import UpdatedByLabel from './UpdatedByLabel';
import IBAContext from '../IBAContext';
import userStore from '../../userStore';

import './WidgetList.less';

const tableSchema = [
  {
    name: 'label',
    label: 'Name',
    value: ['label'],
    formatter: ({item: widget, value, params: {blueprintId}}) => (
      <div className='entity-name'>
        <Link to={`/blueprints/${blueprintId}/analytics/widgets/${widget.id}`}>{value}</Link>
      </div>
    ),
    sortable: true,
  },
  {
    name: 'type',
    label: 'Type',
    value: ['type'],
    formatter: ({value}) => WIDGET_TYPES[value] ?? value,
    sortable: true,
  },
  {
    name: 'properties',
    label: 'Properties',
    formatter: ({item: widget, params}) => <WidgetProperties widget={widget} {...params} />,
  },
  {
    name: 'updated_by',
    label: 'Updated by',
    value: ['updated_by'],
    formatter: ({item: widget}) => <UpdatedByLabel updatedBy={widget.updated_by} timestamp={widget.updated_at} />,
    sortable: true,
  },
  {
    name: 'actions',
    label: 'Actions',
    formatter: ({item: widget, params: {setWidgetModalProps, setWidgetDeletionModalProps}}) =>
      <WidgetActions
        size='small'
        widget={widget}
        setWidgetModalProps={setWidgetModalProps}
        setWidgetDeletionModalProps={setWidgetDeletionModalProps}
      />,
  },
];

@observer
export default class WidgetList extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchData({blueprintId, inlineMode, routes, signal}) {
    const [{items: probes}, {items: widgets}] = await Promise.all([
      request(interpolateRoute(routes.probeList, {blueprintId}), {signal}),
      request(interpolateRoute(routes.widgetList, {blueprintId}), {signal}),
    ]);
    const widgetData = {};
    if (inlineMode) {
      const widgetsData = await Promise.all(widgets.map((widget) => {
        const fetchWidgetData = widgetComponentsByType[widget.type].fetchData;
        return fetchWidgetData ?
          fetchWidgetData({widget, probes, blueprintId, routes, signal}) :
          null;
      }));
      for (const [{id}, data] of zip(widgets, widgetsData)) widgetData[id] = data;
    }
    return {widgets, widgetData, probes};
  }

  static pollingInterval = 30000;
  static userStoreKey = 'widgetList';

  @observable.ref widgetModalProps = null;
  @observable.ref widgetDeletionModalProps = null;
  @observable inlineMode = false;
  @observable anomalousOnly = false;
  @observable.ref selection = {};

  @action
  setWidgetModalProps = (props) => {
    this.widgetModalProps = props;
  };

  @action
  setWidgetDeletionModalProps = (props) => {
    this.widgetDeletionModalProps = props;
  };

  @action
  toggleInlineMode = () => {
    this.inlineMode = !this.inlineMode;
  };

  @action
  toggleAnomalousOnly = () => {
    this.anomalousOnly = !this.anomalousOnly;
  };

  @action
  updateSelection = (selection) => {
    this.selection = selection;
  };

  getDataTableActions = ({selectionIds, widgets, blueprintPermissions}) => [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: () => this.setWidgetDeletionModalProps({
        open: true, widgets: widgets.filter((widget) => selectionIds.includes(widget.id))
      }),
      hasPermissions: hasBlueprintPermissions({blueprintPermissions, action: 'edit'}),
      notPermittedMessage: 'You do not have permission to delete',
      popupPosition: 'bottom center',
    }
  ];

  filterWidgets = (widgets, probes) => {
    if (this.anomalousOnly) {
      return filter(widgets, (widget) => {
        return widget.type === 'anomaly_heatmap' ||
          widget.type === 'stage' && getStageComponentPropsFromWidget({widget, probes})?.stage.anomaly_count;
      });
    }
    return widgets;
  };

  render() {
    const {blueprintId, routes, blueprintPermissions} = this.context;
    const {
      inlineMode, toggleInlineMode,
      getDataTableActions, updateSelection, selection,
      anomalousOnly, toggleAnomalousOnly, filterWidgets,
      widgetModalProps, setWidgetModalProps,
      widgetDeletionModalProps, setWidgetDeletionModalProps,
    } = this;
    const defaultPageSize = userStore.getStoreValue([WidgetList.userStoreKey, 'pageSize']);
    return (
      <DataFilteringContainer
        stateQueryParam='widgets-filter'
        defaultPageSize={defaultPageSize}
        defaultSorting={{label: 'asc'}}
        setUserStoreProps={userStore.setStoreValueFn(WidgetList.userStoreKey)}
      >
        {({activePage, pageSize, updatePagination, sorting, updateSorting}) =>
          <FetchData
            customLoader
            fetchData={WidgetList.fetchData}
            fetchParams={{blueprintId, inlineMode, routes}}
            pollingInterval={WidgetList.pollingInterval}
          >
            {({widgets = [], widgetData, probes, loaderVisible, fetchDataError, refetchData}) => {
              const filteredSelection = pick(selection, map(widgets, 'id'));
              const selectionIds = filter(keys(filteredSelection), (key) => filteredSelection[key]);
              const dataTableActionItems = getDataTableActions({selectionIds, widgets, blueprintPermissions});
              return (
                <Grid stackable>
                  <Grid.Column width={16} textAlign='right'>
                    <PermissionChecker
                      hasPermissions={hasBlueprintPermissions({blueprintPermissions, action: 'edit'})}
                      notPermittedMessage='You do not have permission to create'
                      popupProps={{
                        position: 'top center',
                        offset: ({reference}) => [0, reference.height],
                      }}
                    >
                      <Button
                        primary
                        size='big'
                        icon='add circle'
                        content='Create Widget'
                        onClick={() => setWidgetModalProps({open: true, mode: 'create'})}
                      />
                    </PermissionChecker>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <DataFilter
                      items={filterWidgets(widgets, probes)}
                      schema={tableSchema}
                      activePage={activePage}
                      pageSize={pageSize}
                      sorting={sorting}
                    >
                      {({items: filteredWidgets, allItems, totalCount}) =>
                        <DataFilteringLayout
                          totalCount={totalCount}
                          activePage={activePage}
                          pageSize={pageSize}
                          updatePagination={updatePagination}
                          loaderVisible={loaderVisible}
                          fetchDataError={fetchDataError}
                          leftColumn={[
                            <Fragment key='widget-list-actions'>
                              <Checkbox
                                label='Show widget contents'
                                checked={inlineMode}
                                onChange={toggleInlineMode}
                              />
                              <br />
                              <Checkbox
                                label='Anomalies Only'
                                checked={anomalousOnly}
                                onChange={toggleAnomalousOnly}
                              />
                            </Fragment>,
                            selectionIds.length > 0 &&
                              <ActionsMenu
                                key='data-table-actions'
                                size='small'
                                items={dataTableActionItems}
                              />,
                          ]}
                        >
                          {!filteredWidgets.length ?
                          (
                            anomalousOnly ?
                              <Message success icon='check circle' header='No anomalies!' />
                            :
                              <Message
                                info
                                icon='info circle'
                                content='There are currently no widgets defined.'
                              />
                          )
                        : inlineMode ?
                          <WidgetsWithContents
                            widgets={filteredWidgets}
                            widgetData={widgetData}
                            setWidgetModalProps={setWidgetModalProps}
                            setWidgetDeletionModalProps={setWidgetDeletionModalProps}
                          />
                        :
                          <Fragment>
                            <DataTable
                              className='widget-list'
                              selectable
                              allowSelectAll
                              allItems={allItems}
                              updateSelection={updateSelection}
                              selection={filteredSelection}
                              items={filteredWidgets}
                              schema={tableSchema}
                              params={{
                                blueprintId, probes, routes, blueprintPermissions, refetchData,
                                setWidgetModalProps, setWidgetDeletionModalProps,
                              }}
                              sorting={sorting}
                              updateSorting={updateSorting}
                              getHeaderCellProps={({name}) => ({collapsing: name === 'actions'})}
                              getItemKey={({id}) => id}
                            />
                          </Fragment>
                          }
                        </DataFilteringLayout>
                      }
                    </DataFilter>
                    <WidgetModal
                      open={false}
                      onClose={() => setWidgetModalProps({open: false})}
                      onSuccess={() => refetchData()}
                      probes={probes}
                      {...widgetModalProps}
                    />
                    <WidgetDeletionModal
                      open={false}
                      onClose={() => setWidgetDeletionModalProps({open: false})}
                      onSuccess={() => refetchData()}
                      {...widgetDeletionModalProps}
                    />
                  </Grid.Column>
                </Grid>
              );
            }}
          </FetchData>
        }
      </DataFilteringContainer>
    );
  }
}

export class AnomalyHeatmapWidgetProperties extends Component {
  render() {
    const {widget} = this.props;
    return (
      <Table definition basic='very' collapsing size='small'>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{'Row Tags'}</Table.Cell>
            <Table.Cell>{tagsRenderer.renderValueWithoutCondition({value: widget.rows})}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{'Columns Tags'}</Table.Cell>
            <Table.Cell>{tagsRenderer.renderValueWithoutCondition({value: widget.columns})}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export class StageWidgetProperties extends Component {
  static contextType = IBAContext;

  render() {
    const {blueprintId} = this.context;
    const {widget, probes} = this.props;
    const {probe_id: probeId, stage_name: stageName} = widget;
    let stageLink = null;
    const {probe, processor, stage} = getStageComponentPropsFromWidget({widget, probes});
    if (probe && stage) {
      const renderingStrategy = getStageRenderingStrategy({
        probe, processor, stage,
        dataSource: widget.dataSource,
        usePattern: widget.show_context,
      });
      stageLink = (
        <Fragment>
          <Link to={generateProbeURI({blueprintId, probeId})}>{probe.label}</Link>
          {' / '}
          <Link
            to={{
              pathname: generateProbeURI({blueprintId, probeId, stageName}),
              search: getStageQueryParamsFromWidget({
                widget,
                stage,
                alwaysUseContext: renderingStrategy?.alwaysUseContext,
                filterDeserializer: renderingStrategy?.filterDeserializer
              })
            }}
          >
            {stageName}
          </Link>
        </Fragment>
      );
    }
    if (!stageLink) {
      stageLink = (
        <Label basic color='red' icon='warning sign' content='Invalid stage' />
      );
    }
    return (
      <Table definition basic='very' collapsing size='small'>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{'Stage'}</Table.Cell>
            <Table.Cell>{stageLink}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export class WidgetProperties extends Component {
  render() {
    const widgetPropertiesComponentsByType = {
      [WIDGET_TYPE_ANOMALY_HEATMAP]: AnomalyHeatmapWidgetProperties,
      [WIDGET_TYPE_STAGE]: StageWidgetProperties,
    };
    const WidgetPropertiesComponent = widgetPropertiesComponentsByType[this.props.widget.type];
    return WidgetPropertiesComponent ? <WidgetPropertiesComponent {...this.props} /> : null;
  }
}

@observer
export class WidgetsWithContents extends Component {
  render() {
    const {
      widgets, widgetData,
      setWidgetModalProps, setWidgetDeletionModalProps
    } = this.props;
    return widgets.map((widget) =>
      <Grid key={widget.id} className='iba-widget-container'>
        <Grid.Column textAlign='center' width={16}>
          <WidgetActions
            size='tiny'
            floated='right'
            widget={widget}
            setWidgetModalProps={setWidgetModalProps}
            setWidgetDeletionModalProps={setWidgetDeletionModalProps}
          />
          <Header size='small'>{widget.label}</Header>
        </Grid.Column>
        <Grid.Column width={16}>
          <Widget
            widget={widget}
            widgetData={widgetData[widget.id]}
          />
        </Grid.Column>
      </Grid>
    );
  }
}
