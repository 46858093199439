import {Fragment, memo, useContext, useEffect, useMemo, useRef} from 'react';
import {compact, isEmpty, keys, map, max, min, transform, zipWith} from 'lodash';
import {
  brandColorNames, formatDateAsLocalDateTime, formatNumber, MultipleLineChart, parseTimestamp, TimelineGraphContainer
} from 'apstra-ui-common';
import ReactMarkdown from 'react-markdown';
import cx from 'classnames';
import {Button, Header, Popup, Divider} from 'semantic-ui-react';
import {scaleOrdinal} from 'd3';

import BarGroupChart from '../../../components/graphs/BarGroupChart';
import BoxplotChart from '../../../components/graphs/BoxplotChart';
import DonutChart from '../../../components/graphs/DonutChart';
import SunburstChart from '../../../components/graphs/SunburstChart';
import ReportTable from './ReportTable';
import {CHART_TYPES, REPORT_TYPES} from './const';
import ReportContext from './ReportContext';
import ChartLegend from '../../../components/graphs/ChartLegend';

import {ReactComponent as Chevron} from '../../../../styles/icons/iba/thin-chevron.svg';

const processPopupHeader = (value) => formatDateAsLocalDateTime(value);
const xFn = (d) => d.x;
const firstQuartileFn = (d) => d.q1;
const thirdQuartileFn = (d) => d.q3;
const upperFenceFn = (d) => d.upper_fence;
const lowerFenceFn = (d) => d.lower_fence;

const reportColors = ['green', 'blue', 'purple', 'orange', 'brown', 'red', 'olive', 'teal', 'violet', 'pink', 'grey'];

const ReportEntity = memo(({
  type, title, text, data, x_axis: xAxis, y_axis: yAxis, items, path,
  tocLevels, lastSectionItem, dimensions,
}) => {
  const [samples, minValue, maxValue] = useMemo(() => {
    if (type === REPORT_TYPES.barChart) {
      const {traces} = data;
      const {ticks, ticks_labels: ticksLabels, data_format: dataFormat} = xAxis;
      const xValues = (dataFormat === 'timestamp' ?
        map(data.x_values, (value) => parseTimestamp(value)) :
        ticksLabels ?
          ticksLabels :
          ticks ?
            ticks :
            data.x_values) || [];
      return [map(xValues, (x, i) => {
        return transform(traces, (result, {label, y_values: yValues}) => {
          result[label] = yValues[i];
        }, {x});
      })];
    }
    if (type === REPORT_TYPES.boxChart) {
      const {lowerfence, q1, q2, q3, upperfence} = data;
      const values = data.values || [];
      const q0 = data.q0 || [];
      const q4 = data.q4 || [];
      const {ticks, ticks_labels: ticksLabels, data_format: dataFormat} = xAxis;
      const xValues = (dataFormat === 'timestamp' ?
        map(data.x_values, (value) => parseTimestamp(value)) :
        ticksLabels ?
          ticksLabels :
          ticks ?
            ticks :
            data.x_values) || [];
      return [zipWith(
        xValues, lowerfence, q1, q2, q3, upperfence, values, q0, q4, (x, min, q1, median, q3, max, points, q0, q4) => ({
          x, upper_fence: max, q3, median, q1, lower_fence: min, points,
          outliers: compact([q0 !== min ? q0 : null, q4 !== max ? q4 : null]),
        })
      )];
    }
    if (type === REPORT_TYPES.pieChart) {
      const {values, labels, colors} = data;
      const colorNames = values?.length > reportColors.length ? brandColorNames : reportColors;
      let i = 0;
      return [zipWith(values, labels, colors, (value, label, color) => {
        const tooltip = `${label}: ${formatNumber(value, {short: true, withIndent: true})}`;
        return {
          value, label, tooltip, color: color || colorNames[i++ % colorNames.length], id: label,
        };
      })];
    }
    if (type === REPORT_TYPES.lineChart) {
      const {x_values: xValues, traces} = data;
      let minValue, maxValue;
      return [
        transform(traces, (result, {y_values: yValues, x_values: xLineValues, mode, label}) => {
          minValue = min([minValue, min(yValues)]);
          maxValue = max([maxValue, max(yValues)]);
          const samples = zipWith(xLineValues || xValues, yValues, (timestamp, value) => {
            return {
              value,
              timestamp
            };
          });
          result.push({persisted_samples: samples, properties: {label}, lineMode: mode});
        }, []),
        minValue,
        maxValue
      ];
    }
    if (type === REPORT_TYPES.sunburstChart) {
      const {values, labels, parents, ids} = data;
      const labelById = transform(ids, (result, id, i) => {
        result[id] = labels[i];
      }, {});
      const tree = transform(parents, (result, parentId, i) => {
        const child = {name: labels[i], size: values[i], id: ids[i]};
        if (parentId) {
          result[parentId] = result[parentId] || [];
          result[parentId].push(child);
        }
      }, {});

      const parentIds = keys(tree);
      const colorNames = parentIds.length > reportColors.length ? brandColorNames : reportColors;
      let colorIndex = 0;
      return [{
        name: '',
        children: map(tree, (children, id) => ({
          id,
          color: colorNames[colorIndex++ % colorNames.length],
          name: labelById[id],
          children
        }))
      }];
    }
    return [null];
  }, [data, xAxis, type]);
  const colors = useMemo(() => {
    if (type === REPORT_TYPES.barChart || type === REPORT_TYPES.lineChart) {
      const {traces} = data;
      const colorNames = traces?.length > reportColors.length ? brandColorNames : reportColors;
      return map(traces, ({color}, i) => color || colorNames[i % colorNames.length]);
    }
    return [];
  }, [data, type]);
  const colorScale = useMemo(() => {
    if (type === REPORT_TYPES.lineChart) {
      const labels = map(samples, ({properties}) => properties?.label);
      const itemColors = colors.length === samples.length ?
        colors :
        map(labels, (_, i) => brandColorNames[i % brandColorNames.length]);
      return scaleOrdinal().domain(labels).range(itemColors);
    }
    return () => null;
  }, [colors, samples, type]);

  if (type === REPORT_TYPES.markdown) {
    return (
      <ReportMarkdown path={path} text={text} />
    );
  }
  if (type === REPORT_TYPES.section) {
    return (
      <>
        <ReportHeader
          title={title}
          path={path}
          tocLevels={tocLevels}
        />
        <ReportSectionContainer className='report-section' path={path}>
          {map(items, (entity, i) => (
            <ReportEntity
              key={i}
              {...entity}
              lastSectionItem={items.length - 1 === i}
            />
          ))}
        </ReportSectionContainer>
        {!lastSectionItem && <Divider />}
      </>
    );
  }

  return (
    <div className='report'>
      {title && (
        <div className='title'>
          {CHART_TYPES.has(type) && <ReportCollapseButton path={path} />}
          {title}
        </div>
      )}
      <ReportSectionContainer className='report-chart' path={path}>
        {type === REPORT_TYPES.barChart ?
          <BarGroupChart
            samples={samples}
            groupLabelKey='x'
            colors={!isEmpty(colors) ? colors : undefined}
            xLabel={xAxis?.label}
            yLabel={yAxis?.label}
            units={yAxis?.units || ''}
            dataXFormat={xAxis?.data_format}
            processPopupHeader={
              xAxis?.data_format === 'timestamp' ?
                processPopupHeader :
                undefined
            }
          /> :
          type === REPORT_TYPES.boxChart ?
            <BoxplotChart
              samples={samples}
              x={xFn}
              firstQuartile={firstQuartileFn}
              thirdQuartile={thirdQuartileFn}
              min={lowerFenceFn}
              max={upperFenceFn}
              xLabel={xAxis?.label}
              yLabel={yAxis?.label}
              units={yAxis?.units || ''}
              dataXFormat={xAxis?.data_format}
            /> :
            type === REPORT_TYPES.pieChart ?
              <DonutChart
                width={350}
                thickness={10}
                values={samples}
                withLegend
                pie
              /> :
              type === REPORT_TYPES.lineChart ?
                <Fragment>
                  <TimelineGraphContainer
                    items={samples}
                    itemSamplesPath='persisted_samples'
                    useCurrentTimeAsTimelineEnd={false}
                    expanded
                  >
                    <MultipleLineChart
                      popupContentItemKeys={['label']}
                      minValue={minValue}
                      maxValue={maxValue}
                      valueKeyName='value'
                      itemColors={colors.length === samples.length ? colors : undefined}
                      units={yAxis?.units || ''}
                      xLabel={xAxis?.label}
                      yLabel={yAxis?.label}
                      dimensions={dimensions}
                    />
                  </TimelineGraphContainer>
                  <ChartLegend className='print-only' ordinalColorScale={colorScale} horizontal />
                </Fragment> :
                type === REPORT_TYPES.sunburstChart ?
                  <SunburstChart
                    data={samples}
                    chartWidth={350}
                    withLegend
                  /> :
                  type === REPORT_TYPES.table ?
                    <ReportTable {...data} /> :
                    null
        }
      </ReportSectionContainer>
    </div>
  );
});

ReportEntity.defaultProps = {
  dimensions: {
    compact: {
      height: 60,
      margin: {top: 4, right: 3, bottom: 5, left: 40},
      numTicksRows: 4
    },
    expanded: {
      height: 300,
      margin: {top: 10, right: 3, bottom: 30, left: 50},
      numTicksRows: 10
    },
  },
};

const ReportHeader = ({title, tocLevels, path}) => {
  const {collapsedSectionState, onSetSectionRef} = useContext(ReportContext);
  const collapsed = collapsedSectionState[path];
  const headerRef = useRef();
  useEffect(
    () => {
      onSetSectionRef(path, headerRef);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headerRef, path]
  );
  return (
    <Header
      id={`header.${path}`}
      className={cx('report-header', {expanded: !collapsed})}
      as={`h${Math.min(tocLevels.length, 6)}`}
    >
      <div ref={headerRef}>
        <ReportCollapseButton path={path} />
        {title}
      </div>
    </Header>
  );
};

const ReportCollapseButton = ({path}) => {
  const {collapsedSectionState, onCollapseToggle} = useContext(ReportContext);
  const collapsed = collapsedSectionState[path];
  return (
    <Popup
      content={collapsed ? 'Expand' : 'Collapse'}
      offset={[-11, 0]}
      trigger={
        <Button
          basic
          circular
          size='tiny'
          icon={<Chevron className={cx('chevron-icon', {expanded: !collapsed})} />}
          onClick={() => onCollapseToggle(path)}
          aria-label='Toggle collapse/extend section'
        />
      }
    />
  );
};

const ReportSectionContainer = ({className, children, path}) => {
  const {collapsedSectionState} = useContext(ReportContext);
  return (
    <div className={cx(className, {'report-collapsed': collapsedSectionState[path]})}>
      {children}
    </div>
  );
};

const ReportMarkdown = ({path, text}) => {
  const {collapsedSectionState} = useContext(ReportContext);
  return (
    <ReactMarkdown
      className={cx({'report-collapsed': collapsedSectionState[path]})}
      children={text}
    />
  );
};

export default ReportEntity;
