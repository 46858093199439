import {TimeRangeInput} from 'apstra-ui-common';

const auditEventLogFilterSchema = [
  {
    name: 'user',
    schema: {
      type: 'string',
      title: 'User',
    }
  },
  {
    name: 'user_ip',
    schema: {
      type: 'string',
      title: 'User IP Address',
    }
  },
  {
    name: 'type',
    schema: {
      type: 'array',
      title: 'Type',
      items: {
        enum: [
          'Login',
          'Logout',
          'BlueprintCommit',
          'BlueprintRevert',
          'BlueprintRollback',
          'BlueprintDelete',
          'DeviceConfigChange',
          'OperationModeChangeToMaintenance',
          'OperationModeChangeToNormal',
          'RatelimitExceptionAdd',
          'RatelimitExceptionDelete',
          'RatelimitClear',
          'SyslogCreate',
          'SyslogUpdate',
          'SyslogDelete',
          'UserCreate',
          'UserUpdate',
          'UserDelete',
          'AuthAclEnable',
          'AuthAclDisable',
          'AuthAclRuleAdd',
          'AuthAclRuleUpdate',
          'AuthAclRuleDelete',
        ]
      },
    }
  },
  {
    name: 'blueprint_label',
    schema: {
      type: 'string',
      title: 'Blueprint Name',
    }
  },
  {
    name: 'device_id',
    schema: {
      type: 'string',
      title: 'Device Key',
    }
  },
  {
    name: 'result',
    schema: {
      type: 'string',
      title: 'Result',
    }
  },
  {
    name: 'timestamp',
    as: TimeRangeInput,
    schema: {
      type: 'object',
      title: 'Time Range',
    }
  },
  {
    name: 'commit_message',
    schema: {
      type: 'string',
      title: 'Blueprint Commit Message',
    }
  },
];

export default auditEventLogFilterSchema;
