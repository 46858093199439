import {FC} from 'react';
import {Popup} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';

import {useTooltip} from './TooltipProvider';

import './TooltipPopup.less';

export const TooltipPopup: FC = observer(() => {
  const {containerRef, sharedTooltip} = useTooltip();
  return (
    <Popup
      className='topology-tooltip-popup'
      size='small'
      wide
      context={containerRef}
      content={sharedTooltip.content}
      offset={toJS(sharedTooltip.offset) as any}
      open={sharedTooltip.isOpen}
      inverted={sharedTooltip.isInverted}
      mountNode={sharedTooltip?.mountRef?.current}
    />
  );
});
