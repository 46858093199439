import {
  get, includes, some, forEachRight, castArray, isNil, isFunction,
  isBoolean, keys, isEqual, isEmpty, forEach, isUndefined, transform,
} from 'lodash';

import TrafficSearchBox, {requestInterfacesTraffic} from './components/TrafficSearchBox';
import {convertValueType, getProcessorByStageName, getStageByName} from './stageUtils';
import {STAGE_DATA_SOURCE} from './consts';

export const patternSchemas = [
  // [NS] {TS} -> Range Check
  {
    graph: [
      {values: {value: {type: 'number'}}, fetchStageData: true, hasPersistedData: true},
      {processorType: 'range_check'},
    ],
    renderingStrategy: {renderValueAs: 'lineChartWithRange', noAnomalyHighlighting: true},
  },
  // [NS] -> Range Check {TS} -> Time in State {TS}
  {
    graph: [
      {values: {value: {type: 'number'}}, fetchStageData: true, hasRawPersistedData: false},
      {processorType: 'range_check', fetchStageData: true},
      {
        processorType: 'time_in_state_check',
        values: {value: {type: 'string', hasPossibleValues: true}},
        fetchStageData: true,
        hasRawPersistedData: true
      },
    ],
    renderingStrategy: {renderValueAs: 'discreteStateTimelineWithLegend', noAnomalyHighlighting: true},
  },
    // [NS] {TS} -> Range Check {TS} -> Time in State {TS}
  {
    graph: [
      {values: {value: {type: 'number'}}, fetchStageData: true, hasRawPersistedData: true},
      {processorType: 'range_check', fetchStageData: true},
      {processorType: 'time_in_state_check', fetchStageData: true, hasRawPersistedData: true},
    ],
    renderingStrategy: {renderValueAs: 'discreteStateTimelineWithSamples', noAnomalyHighlighting: true},
  },
  // [DSS] -> Match Count
  {
    graph: [
      {values: {'*': {type: 'string', hasPossibleValues: true}}},
      {processorType: 'match_count'},
    ],
    renderingStrategy: {
      renderValueAs: ({name}) => name === 'value' ? 'gaugeCount' : 'primitiveValue',
      includePrecedingProcessorAnomalies: true,
    },
  },
  // [DSS] -> Match Count -> Range Check
  {
    graph: [
      {values: {'*': {type: 'string', hasPossibleValues: true}}},
      {processorType: 'match_count', fetchStageData: true},
      {processorType: 'range_check'},
    ],
    renderingStrategy: {renderValueAs: 'gaugeCountWithRange'},
  },
  // [DSS] -> Match Percentage
  {
    graph: [
      {values: {'*': {type: 'string', hasPossibleValues: true}}},
      {processorType: 'match_perc'},
    ],
    renderingStrategy: {
      renderValueAs: ({name}) => name === 'value' ? 'gaugePercent' : 'primitiveValue',
      includePrecedingProcessorAnomalies: true,
    },
  },
  // [DSS] -> Match Percentage -> Range Check
  {
    graph: [
      {values: {'*': {type: 'string', hasPossibleValues: true}}},
      {processorType: 'match_perc', fetchStageData: true},
      {processorType: 'range_check'},
    ],
    renderingStrategy: {renderValueAs: 'gaugePercentWithRange'},
  },
  // Utilization path stage
  {
    graph: [
      {processorType: 'traffic_monitor'},
      {processorType: 'system_utilization', inputName: 'rx_bps'},
    ],
    renderingStrategy: {
      renderStageDataAs: 'deviceTraffic',
      noPagination: true,
      noCustomization: true,
      SearchComponent: TrafficSearchBox,
      filterTransformer: TrafficSearchBox.filterTransformer,
      filterSerializer: TrafficSearchBox.filterSerializer,
      filterDeserializer: TrafficSearchBox.filterDeserializer,
      clearFilterOnContextTrigger: true,
      shouldFetchData: TrafficSearchBox.shouldFetchData,
      hiddenContextByDefault: true,
      noDataMessage: 'Select Source and Destination systems to visualize traffic between them',
    },
    fetchData: requestInterfacesTraffic,
  },
  // EVPN type3/5: default filter to hide unexpected routes
  {
    graph: [
      {processorType: 'evpn3', outputName: 'routes'},
    ],
    renderingStrategy: {
      defaultFilter: 'value != "Unexpected"',
      renderValueAs: 'routesStatus',
      alwaysUseContext: true,
    },
  },
  {
    graph: [
      {processorType: 'evpn5', outputName: 'routes'},
    ],
    renderingStrategy: {
      defaultFilter: 'value != "Unexpected"',
      renderValueAs: 'routesStatus',
      alwaysUseContext: true,
    },
  },
  // VXLAN Flood List Table
  {
    graph: [
      {outputName: 'routes'},
    ],
    renderingStrategy: {
      defaultFilter: 'value != "Unexpected"',
      renderValueAs: 'routesStatus',
      alwaysUseContext: true,
    },
  },
  {
    graph: [
      {
        processorType: 'optical_xcvr',
        fetchStageDataForOutputs: ['interface', 'lane'],
      },
      {
        processorType: 'optical_threshold',
        inputName: 'rx_power_has_high_alarm',
      },
    ],
    renderingStrategy: {
      extraColumns: [
        {
          name: 'anomalous_metrics',
          label: 'Anomalous Metrics',
        }
      ],
      alwaysUseContext: true,
    },
  },
  {
    graph: [
      {
        processorType: 'optical_xcvr',
        fetchStageDataForOutputs: ['interface', 'lane'],
      },
      {
        processorType: 'optical_threshold',
        inputName: 'rx_power_has_high_alarm',
      },
      {
        processorType: 'time_in_state_check',
      },
    ],
    renderingStrategy: {
      extraColumns: [
        {
          name: 'anomalous_metrics',
          label: 'Anomalous Metrics',
        }
      ],
      alwaysUseContext: true,
    },
  },
  {
    graph: [
      {
        processorType: 'optical_xcvr',
        stageDataSource: STAGE_DATA_SOURCE.time_series,
        fetchStageDataForOutputs: ['interface'],
        shouldIncludeStagesForTimeSeries: true,
      }
    ],
    renderingStrategy: {
      renderValueAs: 'anomalousOpticalMetric',
      alwaysUseContext: true,
      noSpotlightMode: false,
    },
  },
  {
    graph: [
      {
        values: {
          operational_power_supply_count: {type: 'number'},
          total_power_supply_count: {type: 'number'},
        }
      },
    ],
    renderingStrategy: {
      aggregationType: 'last',
      alwaysUseContext: true,
    },
  },
  {
    graph: [
      {
        values: {
          total_fan_tray_count: {type: 'number'},
        }
      },
    ],
    renderingStrategy: {
      aggregationType: 'last',
      alwaysUseContext: true,
    },
  },
];

function checkStageValueUnsuitable(stageValue, patternValue) {
  const {possible_values: possibleValues, type: stageValueType} = stageValue;
  const type = convertValueType(stageValueType);
  const hasPossibleValues = !isEmpty(possibleValues);
  return type !== patternValue.type ||
    ('hasPossibleValues' in patternValue && patternValue.hasPossibleValues !== hasPossibleValues);
}

export function checkForPatternBySchema({
  schema: {graph, renderingStrategy, fetchData},
  probe, processor, stage, dataSource,
}) {
  const relatedStages = [];
  const relatedProcessors = [];
  const fetchDataForRelatedStages = [];
  let matched = false;
  forEachRight(graph, ({
    processorType, values,
    inputName = 'in', outputName = null,
    fetchStageData = false, hasPersistedData = null,
    fetchStageDataForOutputs = [],
    stageDataSource,
  }, index) => {
    if (processorType && !includes(castArray(processorType), processor.type)) return false;
    if (values) {
      const valueKeys = keys(values);
      if ('*' in values) {
        const allSuitableValues = transform(castArray(values['*']), (result, patternValue) => {
          result &&= some(stage.values, (value) => {
            return !checkStageValueUnsuitable(value, patternValue);
          });
        }, true);
        if (!allSuitableValues) return false;
      } else if (!isEqual(valueKeys.sort(), keys(stage.values).sort())) {
        return false;
      }
      if (some(valueKeys, (valueKey) => {
        if (valueKey === '*') return false;
        if (!get(stage, ['values', valueKey])) return true;
        return checkStageValueUnsuitable(stage.values[valueKey], values[valueKey]);
      })) return false;
    }
    if (outputName && !some(
      castArray(outputName),
      (outputName) => get(processor.outputs, [outputName], null) === stage.name
    )) return false;
    if (isBoolean(hasPersistedData) && hasPersistedData !== stage.enable_metric_logging) return false;
    if (!isUndefined(stageDataSource) && stageDataSource !== dataSource) return false;

    relatedProcessors.unshift(processor);
    relatedStages.unshift(stage);

    const shouldFetchStageData = isFunction(fetchStageData) ? fetchStageData({processor}) : fetchStageData;
    if (shouldFetchStageData) {
      fetchDataForRelatedStages.push({stageName: stage.name, hasPersistedData});
    }
    if (!isEmpty(fetchStageDataForOutputs)) {
      forEach(fetchStageDataForOutputs, (outputName) => {
        const stageName = get(processor, ['outputs', outputName]);
        if (stageName) fetchDataForRelatedStages.push({stageName, hasPersistedData});
      });
    }
    if (!index) {
      matched = true;
      return false;
    }
    const sourceStageName = get(processor.inputs, [inputName, 'stage'], null);
    if (isNil(sourceStageName)) return false;
    stage = getStageByName({probe, stageName: sourceStageName});
    processor = getProcessorByStageName({probe, stageName: sourceStageName});
  });
  return matched ? {
    renderingStrategy, fetchData,
    shouldFetchPersistedStageData: some(graph, {hasPersistedData: true}),
    shouldFetchRawPersistedStageData: some(graph, {hasRawPersistedData: true}),
    relatedProcessors, relatedStages,
    fetchDataForRelatedStages
  } : null;
}

export default function checkForPatterns({probe, stageName, dataSource}) {
  const stage = getStageByName({probe, stageName});
  const processor = getProcessorByStageName({probe, stageName});
  for (const schema of patternSchemas) {
    const result = checkForPatternBySchema({schema, probe, processor, stage, dataSource});
    if (result) return result;
  }
  return null;
}
