import {sumBy, castArray, reduce, defaultTo, map, assign, filter, first} from 'lodash';

import {ALL_NODE_ROLES, LINK_ROLES} from '../../../roles';

// Anomalies where the node role is not used to group gauge data.
const typeOnlyAnomalies = ['deployment', 'config', 'route', 'mlag'];

// Returns service type data, used to grouped anomalies.
export function getGauges(blueprint, serviceAnomalyDigest, serviceTypeResolveFn) {
  const countLinks = (item) => blueprint ? countByRole(blueprint.links, item.role) * 2 : 0;
  const countNodes = (item) => blueprint ? countByRole(blueprint.nodes, item.role) : 0;
  const countExternalNodes = blueprint ? count(blueprint.nodes, (item) => item.system_type === 'external') : 0;
  const filterHidden = (items) => filter(items, (item) => !item.hidden);

  const hasGenerics = countNodes({role: ALL_NODE_ROLES.GENERIC}) > 0;
  const hasExternal = countExternalNodes > 0;
  const hasAccess = countNodes({role: ALL_NODE_ROLES.ACCESS}) > 0;
  const hasSpines = countNodes({role: ALL_NODE_ROLES.SPINE}) > 0;
  const isFiveStage = countNodes({role: ALL_NODE_ROLES.SUPERSPINE}) > 0;
  const isFreeform = blueprint?.isFreeform;

  const numNodes = blueprint && Array.isArray(blueprint.nodes) ? blueprint.nodes.length : 0;
  const numPairs = countNodes({role: ALL_NODE_ROLES.LEAF_PAIR}) * 2;
  const numLags = countLags(blueprint.links);

  // Anomaly types are grouped based on the way their maximum total is
  // calculated. Each anomaly type contains the following fields:
  //
  //   service:  Service type this anomaly belongs to.
  //   name:     User facing name for this anomaly type.
  //   type:     Anomaly type as defined in the backend data.
  //   role:     Link or node role(s) relevant to this anomaly.
  //   typeOnly: If true, indicates the gauge ID is the same for all roles.

  const services = {
    DEPLOYMENT: 'Deployment Status',
    EXTERNAL: 'External',
    FABRIC: 'Fabric',
    GENERIC_CONNECTIVITY: 'Generic System Connectivity',
    IP_FABRIC: 'IP Fabric',
    LEAF_PEERING: 'Leaf Peering',
    LIVENESS: 'Liveness',
    ROUTE: 'Route Verification',
  };

  /* eslint-disable max-len */
  /* eslint-disable no-multi-spaces */
  const linkAnomalies = {
    anomalies: filterHidden([
      {service: services.IP_FABRIC,             name: 'BGP',       type: 'bgp',       role: [LINK_ROLES.SPINE_SUPERSPINE, LINK_ROLES.SPINE_LEAF, 'leaf_peer_link', 'leaf_l3_peer_link', LINK_ROLES.LEAF_LEAF, 'access_l3_peer_link'], hidden: isFreeform},
      {service: services.GENERIC_CONNECTIVITY,  name: 'BGP',       type: 'bgp',       role: [ALL_NODE_ROLES.GENERIC, ALL_NODE_ROLES.REMOTE_GATEWAY], hidden: !hasGenerics},
      {service: services.IP_FABRIC,             name: 'Cabling',   type: 'cabling',   role: [LINK_ROLES.SPINE_SUPERSPINE, LINK_ROLES.SPINE_LEAF, 'leaf_peer_link', 'leaf_l3_peer_link', LINK_ROLES.LEAF_ACCESS, LINK_ROLES.LEAF_LEAF, 'access_l3_peer_link'], hidden: isFreeform},
      {service: services.IP_FABRIC,             name: 'Interface', type: 'interface', role: [LINK_ROLES.SPINE_SUPERSPINE, LINK_ROLES.SPINE_LEAF, 'leaf_peer_link', 'leaf_l3_peer_link', LINK_ROLES.LEAF_ACCESS, 'access_l3_peer_link'], hidden: isFreeform},
      {service: services.GENERIC_CONNECTIVITY,  name: 'Interface', type: 'interface', role: LINK_ROLES.TO_GENERIC, hidden: !hasGenerics},
      {service: services.EXTERNAL,              name: 'Interface', type: 'interface', role: 'external', hidden: !hasExternal},
      {service: services.FABRIC,                name: 'Cabling',   type: 'cabling',   role: 'internal', hidden: !isFreeform},
      {service: services.FABRIC,                name: 'Interface', type: 'interface', role: 'internal', hidden: !isFreeform},
    ]),
    counter: countLinks
  };

  const livenessAnomalies = {
    anomalies: filterHidden([
      {service: services.LIVENESS, name: 'Superspine', type: 'liveness', role: ALL_NODE_ROLES.SUPERSPINE, hidden: !isFiveStage},
      {service: services.LIVENESS, name: 'Spine',      type: 'liveness', role: ALL_NODE_ROLES.SPINE, hidden: !hasSpines},
      {service: services.LIVENESS, name: 'Leaf',       type: 'liveness', role: ALL_NODE_ROLES.LEAF, hidden: isFreeform},
      {service: services.FABRIC,   name: 'Node',       type: 'liveness', role: 'internal', hidden: !isFreeform},
      {service: services.LIVENESS, name: 'Generics',   type: 'liveness', role: ALL_NODE_ROLES.GENERIC,  hidden: !hasGenerics},
      {service: services.LIVENESS, name: 'Access',     type: 'liveness', role: ALL_NODE_ROLES.ACCESS,  hidden: !hasAccess},
    ]),
    counter: countNodes
  };

  const nodeAnomalies = {
    anomalies: filterHidden([
      {service: services.DEPLOYMENT,  name: 'Deployment',  type: 'deployment'},
      {service: services.DEPLOYMENT,  name: 'Config Dev.', type: 'config'},
      {service: services.DEPLOYMENT,  name: 'Config Ren.', type: 'blueprint_rendering', role: [ALL_NODE_ROLES.SUPERSPINE, ALL_NODE_ROLES.SPINE, ALL_NODE_ROLES.LEAF, 'unknown']},
      {service: services.ROUTE,       name: 'Route Table', type: 'route', hidden: isFreeform},
      {service: services.IP_FABRIC,   name: 'Hostname',    type: 'hostname',  role: [ALL_NODE_ROLES.SUPERSPINE, ALL_NODE_ROLES.SPINE, ALL_NODE_ROLES.LEAF, 'unknown', ALL_NODE_ROLES.ACCESS], hidden: isFreeform},
      {service: services.FABRIC,      name: 'Hostname',    type: 'hostname',  role: 'internal', hidden: !isFreeform},
    ]),
    counter: () => numNodes
  };

  const mlagAnomalies = {
    anomalies: filterHidden([
      {service: services.IP_FABRIC, name: 'MLAG', type: 'mlag', hidden: !hasGenerics}
    ]),
    counter: () => numPairs
  };

  const lagAnomalies = {
    anomalies: filterHidden([
      {
        service: services.GENERIC_CONNECTIVITY,
        name: 'LAG',
        type: 'lag',
        role: [LINK_ROLES.TO_GENERIC],
        hidden: !hasGenerics,
      },
      {
        service: services.IP_FABRIC,
        name: 'LAG',
        type: 'lag',
        role: [LINK_ROLES.LEAF_ACCESS, 'access_l3_peer_link'],
        hidden: isFreeform,
      },
      {
        service: services.LEAF_PEERING,
        name: 'LAG',
        type: 'lag',
        role: ['leaf_peer_link', 'leaf_l3_peer_link'],
        hidden: !hasSpines,
      },
      {
        service: services.FABRIC,
        name: 'LAG',
        type: 'lag',
        role: 'internal',
        hidden: !isFreeform,
      },
      {
        service: services.EXTERNAL,
        name: 'LAG',
        type: 'lag',
        role: 'external',
        hidden: !hasExternal,
      }
    ]),
    counter: () => numLags
  };
   /* eslint-enable max-len */
   /* eslint-enable no-multi-spaces */

  return [
    ...anomaliesToGauges(linkAnomalies, serviceAnomalyDigest, serviceTypeResolveFn),
    ...anomaliesToGauges(livenessAnomalies, serviceAnomalyDigest, serviceTypeResolveFn),
    ...anomaliesToGauges(nodeAnomalies, serviceAnomalyDigest, serviceTypeResolveFn),
    ...anomaliesToGauges(mlagAnomalies, serviceAnomalyDigest, serviceTypeResolveFn),
    ...anomaliesToGauges(lagAnomalies, serviceAnomalyDigest, serviceTypeResolveFn)
  ];
}

// Sets the gauge 'value' field based on the given anomaly digest.
function getGaugeValue(ids, serviceAnomalyDigest) {
  const anomaliesKeyCountMap = keyCountAnomalies(serviceAnomalyDigest);
  return sumBy(ids, (id) => defaultTo(anomaliesKeyCountMap[id], 0));
}

// Assigns gauge data to the given anomaly data, including a unique gauge ID
// to associate anomalies with a gauge, a maximum value, and a zero count.
function anomaliesToGauges({anomalies, counter}, serviceAnomalyDigest, serviceTypeResolveFn) {
  return map(anomalies, (anomaly) => {
    const roles = castArray(anomaly.role);
    const ids = !anomaly.role || anomaly.typeOnly ?
      [gaugeId(anomaly.type)] : roles.map((role) => gaugeId(anomaly.type, role));
    const maxValue = counter(anomaly);
    const value = getGaugeValue(ids, serviceAnomalyDigest);
    return assign(anomaly,
      {ids, minValue: 0, value, maxValue, service: serviceTypeResolveFn(anomaly.type, first(roles))}
    );
  });
}

// Returns an ID used to group anomalies.
function gaugeId(type, role) {
  const role0 = typeOnlyAnomalies.includes(type) ? '' : `${role}_`;
  return `${role0}${type}`;
}

// Calculates count for anomalies based on id and returns as map object
// Example: {"spine_liveness":2,"deployment":1}
function keyCountAnomalies(anomalies) {
  return reduce(anomalies, (obj, anomaly) => {
    const id = gaugeId(anomaly.type, anomaly.role);
    if (!obj[id]) {
      obj[id] = 0;
    }
    obj[id] += anomaly.count;
    return obj;
  }, {});
}

// Returns the number of items in a collection having the given role. Memoizes
// results for efficiency.
function countByRole(arr, role) {
  const callback = Array.isArray(role) ? (d) => role.includes(d.role) : (d) => role === d.role;
  return count(arr, callback);
}

// Returns the number of LAG endpoints in a set of links.
function countLags(links) {
  const linkRoles = [LINK_ROLES.TO_GENERIC, 'leaf_peer_link', 'leaf_l3_peer_link'];
  const epTypes = [ALL_NODE_ROLES.LEAF, ALL_NODE_ROLES.SPINE, 'peer'];

  const countEndpoints = (link) =>
    count(link.endpoints, (ep) => epTypes.includes(ep.type));

  return links.reduce((total, link) => {
    const isLag = link.composed_of && linkRoles.includes(link.role);
    return isLag ? total + countEndpoints(link) : total;
  }, 0);
}

// Efficient utility function to return the number of items in an array for
// which 'callback' returns true.
function count(arr, callback) {
  return reduce(arr, (total, item) => {
    return callback(item) ? ++total : total;
  }, 0);
}
