import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {observable, action, computed, toJS, makeObservable, reaction} from 'mobx';
import {observer} from 'mobx-react';
import {Message, Form} from 'semantic-ui-react';
import {
  map, find, filter, flatMap, mapValues, get, groupBy, castArray, head,
  isPlainObject, isString, isMatch, pullAllWith, isEmpty, pick, keys, isFunction, includes,
} from 'lodash';
import {COMBING_GRAPHS_MODE, DEFAULT_COMBING_GRAPHS_MODE,
  DurationInput, FetchDataError, Field, FormFragment, RadioGroupInput, ResourceModal,
  generatePropertyFromSchema, interpolateRoute, request} from 'apstra-ui-common';

import TagsInput from '../../components/TagsInput';
import AggregationInput from '../../components/AggregationInput';
import StageSearchBox from './StageSearchBox';
import StageInput from './StageInput';
import StageWidgetCustomizationInput from './StageWidgetCustomizationInput';
import {sortingToQueryParam, queryParamToSorting, filtersToQueryParam} from '../../queryParamUtils';
import {
  processorCanRaiseAnomalies, getProcessorByStageName, getStageByName,
  getStageRenderingStrategy, castFilterConverter, getStageDataSchema,
  getPossibleAggregationTypes, getValueAggregationTypes,
} from '../stageUtils';
import checkForPatterns from '../checkForPatterns';
import {
  WIDGET_TYPES, WIDGET_TYPE_ANOMALY_HEATMAP, WIDGET_TYPE_STAGE, DEFAULT_WIDGET_TYPE,
  STAGE_DATA_SOURCE, DEFAULT_STAGE_DATA_SOURCE, DEFAULT_STAGE_TIME_SERIES_DURATION,
  DEFAULT_STAGE_TIME_SERIES_AGGREGATION, OPTICAL_PROBE_METRICS
} from '../consts';
import IBAContext from '../IBAContext';
import StageWidgetValueColumnNameInput from './StageWidgetValueColumnNameInput';
import AggregationTypeInput from './AggregationTypeInput';

const DEFAULT_WIDGET_PAGE_SIZE = 10;
const SPOTLIGHT_MODE_PAGE_SIZE = 1;

@observer
export default class WidgetModal extends Component {
  static contextType = IBAContext;

  static propTypes = {
    fixedWidgetType: PropTypes.bool,
    fixedStage: PropTypes.bool,
  };

  static defaultProps = {
    mode: 'create',
    fixedWidgetType: false,
    fixedStage: false,
  };

  @observable widgetType = DEFAULT_WIDGET_TYPE;
  @observable properties = {};

  createWidget = () => request(
    interpolateRoute(
      this.context.routes.widgetList,
      {blueprintId: this.context.blueprintId}
    ),
    {method: 'POST', body: JSON.stringify(this.propertiesToWidget())}
  );

  updateWidget = () => request(
    interpolateRoute(
      this.context.routes.widgetDetails,
      {blueprintId: this.context.blueprintId, widgetId: this.props.widget.id}
    ),
    {method: 'PUT', body: JSON.stringify(this.propertiesToWidget())}
  );

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeStageReaction = reaction(
      () => this.properties.stage,
      () => {
        if (!this.selectedStageInfo) return null;
        const {probe_id: probeId, stage_name: stageName} = this.props.widget || {};
        const {stage, probe} = this.selectedStageInfo;
        const columnNames = keys(stage.values);
        const properties = (stage.name === stageName && probe.id === probeId) ?
          this.widgetToProperties(this.props.widget) : null;
        const valueColumnName = get(properties, ['value_column_name'], head(columnNames));
        const aggregationType = get(
          properties,
          ['aggregation_type'],
          getValueAggregationTypes(stage, valueColumnName, this.patternDescription)
        );
        this.setProperty('value_column_name', valueColumnName);
        this.setProperty('aggregation_type', aggregationType);
        this.setProperty('data_source', get(properties, ['data_source'], STAGE_DATA_SOURCE.real_time));
        this.setProperty(
          'stage_customization',
          get(properties, ['stage_customization'], {visibleColumns: null, sorting: {}})
        );
      },
    );
  }

  componentWillUnmount() {
    this.disposeStageReaction();
  }

  propertiesToWidget() {
    const widget = {};
    const properties = toJS(this.properties);
    widget.type = this.widgetType;
    widget.label = properties.label;
    widget.description = properties.description;
    if (this.widgetType === WIDGET_TYPE_ANOMALY_HEATMAP) {
      widget.rows = toJS(properties.rows);
      widget.columns = toJS(properties.columns);
    } else if (this.widgetType === WIDGET_TYPE_STAGE) {
      const {
        stageMayHaveAnomalies,
        stageHasAlternativeRenderingStrategy,
        stageMayHaveSpotlightMode,
        stageHasPersistedData,
        usePatternWithPersistedData,
        renderingStrategy,
        context: {processorDefinitions},
      } = this;
      widget.probe_id = get(properties, ['stage', 'probe_id'], null);
      widget.stage_name = get(properties, ['stage', 'stage_name'], null);
      widget.data_source = stageHasPersistedData ? properties.data_source : null;
      if (widget.data_source === STAGE_DATA_SOURCE.time_series || usePatternWithPersistedData) {
        widget.time_series_duration = properties.time_series_duration;
        widget.aggregation_period = properties.aggregation_period;
        widget.aggregation_type = properties.aggregation_type;
      }
      let defaultVisibleColumns = null;
      if (widget.data_source === STAGE_DATA_SOURCE.time_series && this.selectedStageInfo) {
        const {stage, processor} = this.selectedStageInfo;
        const columns = getStageDataSchema({
          stage,
          processor,
          processorDefinitions,
          renderingStrategy,
          valueColumnName: properties.value_column_name,
        });
        defaultVisibleColumns = map(columns, 'name');
      }
      const visibleColumns = get(properties, ['stage_customization', 'visibleColumns'], []);
      widget.visible_columns = isEmpty(visibleColumns) ? defaultVisibleColumns : visibleColumns;
      widget.orderby = sortingToQueryParam(get(properties, ['stage_customization', 'sorting'], {}));
      widget.show_context = stageHasAlternativeRenderingStrategy ? properties.show_context : false;
      widget.filter = (
        (widget.show_context && renderingStrategy?.filterSerializer) ||
        filtersToQueryParam)(properties.filter);
      widget.max_items = properties.max_items;
      widget.anomalous_only = stageMayHaveAnomalies ? properties.anomalous_only : false;
      widget.spotlight_mode = stageMayHaveSpotlightMode ? properties.spotlight_mode : false;
      widget.combine_graphs = properties.combine_graphs;
    }
    return widget;
  }

  widgetToProperties(widget) {
    const properties = {
      label: widget.label,
      description: widget.description,
    };
    if (widget.type === WIDGET_TYPE_ANOMALY_HEATMAP) {
      properties.rows = widget.rows;
      properties.columns = widget.columns;
    } else if (widget.type === WIDGET_TYPE_STAGE) {
      properties.stage = {probe_id: widget.probe_id, stage_name: widget.stage_name};
      properties.stage_customization = {
        visibleColumns: isEmpty(widget.visible_columns) ? null : widget.visible_columns,
        sorting: queryParamToSorting(widget.orderby),
      };
      properties.data_source = widget.data_source;
      properties.time_series_duration = widget.time_series_duration;
      properties.aggregation_type = widget.aggregation_type;
      properties.aggregation_period = widget.aggregation_period;
      properties.filter = (this.renderingStrategy?.filterDeserializer || castFilterConverter)(widget.filter);
      properties.max_items = widget.spotlight_mode ? SPOTLIGHT_MODE_PAGE_SIZE : widget.max_items;
      properties.anomalous_only = widget.anomalous_only;
      properties.show_context = widget.show_context;
      properties.spotlight_mode = widget.spotlight_mode;
      properties.combine_graphs = widget.combine_graphs;
      properties.value_column_name = this.getValueColumnName(widget);
    }
    return properties;
  }

  getValueColumnName(widget) {
    if (widget.value_column_name) return widget.value_column_name;
    if (widget.data_source === STAGE_DATA_SOURCE.real_time || !this.selectedStageInfo) return null;
    const {stage} = this.selectedStageInfo;
    return find(widget.visible_columns, (name) => name in stage.values) || head(keys(stage.values));
  }

  @computed
  get renderingStrategy() {
    if (!this.selectedStageInfo) return null;
    const {probe, processor, stage} = this.selectedStageInfo;
    return getStageRenderingStrategy({
      probe, processor, stage,
      dataSource: get(this.properties, ['data_source'], this.props.widget?.dataSource),
      // eslint-disable-next-line camelcase
      usePattern: get(this.properties, ['show_context'], this.props.widget?.show_context),
    });
  }

  @computed get renderValueAs() {
    const {renderingStrategy} = this;
    const value = this.properties.value_column_name;
    if (!value || !renderingStrategy) {
      return null;
    }
    return isFunction(renderingStrategy.renderValueAs) ?
      renderingStrategy.renderValueAs(value) :
      renderingStrategy.renderValueAs;
  }

  @computed get mayCombineGraphs() {
    const valueColumnName = this.properties.value_column_name;
    const spotlightMode = this.properties.spotlight_mode;
    const isGraphSupportCombine = this.renderValueAs === 'lineChart' ||
      (this.renderValueAs === 'anomalousOpticalMetric' && !includes(OPTICAL_PROBE_METRICS, valueColumnName));
    return !spotlightMode && isGraphSupportCombine;
  }

  @computed get patternDescription() {
    if (this.selectedStageInfo) {
      const {probe, stage} = this.selectedStageInfo;
      const dataSource = get(this.properties, ['data_source'], this.props.widget?.dataSource);
      return checkForPatterns({probe, stageName: stage.name, dataSource});
    }
    return null;
  }

  @computed get useCustomSearchBox() {
    const {properties, renderingStrategy, stageDataSourceIsTimeSeries} = this;
    return properties.show_context &&
      !stageDataSourceIsTimeSeries &&
      !!renderingStrategy?.SearchComponent;
  }

  @computed get aggregationTypeOptions() {
    if (!this.selectedStageInfo) return [];
    const {selectedStageInfo: {stage}, properties: {value_column_name: valueColumnName}} = this;
    return map(getPossibleAggregationTypes(stage, valueColumnName), (type) => ({
      const: type, title: type,
    }));
  }

  @computed get formSchema() {
    const {widgetType, selectedStageInfo, props: {mode, fixedWidgetType}} = this;
    const schema = [
      {
        name: 'type',
        required: true,
        disabled: fixedWidgetType || mode === 'update',
        schema: {
          type: 'string',
          title: 'Type',
          default: DEFAULT_WIDGET_TYPE,
          oneOf: map(WIDGET_TYPES, (value, key) => ({const: key, title: value})),
        },
      },
      {
        name: 'label',
        required: true,
        schema: {type: 'string', title: 'Name'}
      },
    ];
    if (widgetType === WIDGET_TYPE_ANOMALY_HEATMAP) {
      schema.push(...[
        {
          name: 'rows',
          required: true,
          as: TagsInput,
          schema: {type: 'array', title: 'Row Tags', items: {type: 'string'}}
        },
        {
          name: 'columns',
          required: true,
          as: TagsInput,
          schema: {type: 'array', title: 'Column Tags', items: {type: 'string'}}
        },
      ]);
    } else if (widgetType === WIDGET_TYPE_STAGE) {
      const {
        stageCustomizationAvailable, stageMayHaveAnomalies, stageHasAlternativeRenderingStrategy,
        stageMayHaveSpotlightMode, stageHasPersistedData, stageDataSourceIsTimeSeries, usePatternWithPersistedData,
        renderingStrategy, isSelectedProbeOperational, alwaysUseContext, mayCombineGraphs, useCustomSearchBox,
        aggregationTypeOptions, usePatternWithRawPersistedData, props: {fixedStage}
      } = this;
      schema.push(...[
        {
          name: 'stage',
          required: true,
          disabled: fixedStage,
          as: StageInput,
          schema: {
            type: 'object',
            title: 'Stage',
            default: {probe_id: null, stage_name: null}
          }
        },
        {
          name: 'data_source',
          hidden: !stageHasPersistedData,
          as: RadioGroupInput,
          schema: {
            type: 'string',
            title: 'Data Source',
            description: 'Indicates what data source should be used for the stage: real time or time-series. ' +
              'The latter one can be used only when metric logging is enabled for the stage.',
            oneOf: [
              {const: STAGE_DATA_SOURCE.real_time, title: 'Real Time'},
              {const: STAGE_DATA_SOURCE.time_series, title: 'Time Series'},
            ],
            default: DEFAULT_STAGE_DATA_SOURCE
          }
        },
        {
          name: 'value_column_name',
          as: StageWidgetValueColumnNameInput,
          hidden: !stageDataSourceIsTimeSeries && !usePatternWithPersistedData,
          schema: {
            type: 'string',
            title: 'Value Column Name',
            description: 'For time-series data, show only one column value name',
          }
        },
        {
          name: 'aggregation_type',
          as: AggregationTypeInput,
          hidden: (!stageDataSourceIsTimeSeries && !usePatternWithPersistedData) || usePatternWithRawPersistedData,
          schema: {
            type: 'string',
            title: 'Aggregation Type',
            description: '',
            oneOf: aggregationTypeOptions,
          },
          clearable: true,
        },
        {
          name: 'time_series_duration',
          as: DurationInput,
          hidden: !stageDataSourceIsTimeSeries && !usePatternWithPersistedData,
          schema: {
            type: 'number',
            title: 'Time Series Duration',
            description: 'For time-series data, show only last samples limited by duration.',
            default: DEFAULT_STAGE_TIME_SERIES_DURATION
          },
        },
        {
          name: 'aggregation_period',
          as: AggregationInput,
          hidden: (!stageDataSourceIsTimeSeries && !usePatternWithPersistedData) || usePatternWithRawPersistedData,
          disabled: this.properties.aggregation_type === 'none',
          schema: {
            type: 'number',
            title: 'Time Series Aggregation',
            description: 'Accumulate samples within this period of time and show their average as a single item.',
            default: DEFAULT_STAGE_TIME_SERIES_AGGREGATION
          },
        },
        {
          name: 'combine_graphs',
          hidden: !mayCombineGraphs,
          schema: {
            type: 'string',
            title: 'Combine graphs',
            description: 'Mode of displaying multiple graphs',
            oneOf: [
              {const: COMBING_GRAPHS_MODE.NONE, title: 'Separate graphs'},
              {const: COMBING_GRAPHS_MODE.LINEAR, title: 'Combine graphs: Linear'},
              {const: COMBING_GRAPHS_MODE.STACKED, title: 'Combine graphs: Stacked'},
            ],
            default: DEFAULT_COMBING_GRAPHS_MODE
          }
        },
        {
          name: 'stage_customization',
          hidden: !isSelectedProbeOperational,
          as: StageWidgetCustomizationInput,
          schema: {
            type: 'object',
            title: 'Stage Output Customization',
            default: {visibleColumns: null, sorting: {}}
          }
        },
        {
          name: 'anomalous_only',
          hidden: !isSelectedProbeOperational || !stageMayHaveAnomalies,
          schema: {
            type: 'boolean',
            title: 'Anomalies Only',
            description: 'Show only anomalous items.',
          }
        },
        {
          name: 'show_context',
          hidden: !isSelectedProbeOperational || !stageHasAlternativeRenderingStrategy || alwaysUseContext,
          schema: {
            type: 'boolean',
            title: 'Show Contextual Information',
            description: 'Show information from related stages.',
          }
        },
        {
          name: 'filter',
          hidden: !isSelectedProbeOperational || !selectedStageInfo,
          as: WidgetStageSearchBox,
          schema: {
            type: 'object',
            SearchBoxComponent: useCustomSearchBox ? renderingStrategy?.SearchComponent
              : StageSearchBox
          }
        },
        {
          name: 'spotlight_mode',
          hidden: !isSelectedProbeOperational || !stageMayHaveSpotlightMode,
          schema: {
            type: 'boolean',
            title: 'Spotlight View',
            description: 'Show an extended view of a single stage item.',
          }
        },
        {
          name: 'max_items',
          disabled: this.properties.spotlight_mode,
          hidden: !isSelectedProbeOperational || !stageCustomizationAvailable,
          schema: {
            type: 'number',
            title: 'Max Items',
            description: 'Max number of stage data items in widget output.',
            minimum: 0,
            maximum: 100,
            default: DEFAULT_WIDGET_PAGE_SIZE,
          }
        },
      ]);
    }
    schema.push(...[
      {
        name: 'description',
        schema: {type: 'string', title: 'Description'}
      },
    ]);
    return schema;
  }

  @computed get selectedStageInfo() {
    const propertiesStage = this.properties.stage || pick(this.props.widget, ['probe_id', 'stage_name']);
    if (this.widgetType === WIDGET_TYPE_STAGE && propertiesStage) {
      const {probe_id: probeId, stage_name: stageName} = propertiesStage;
      if (probeId && stageName) {
        const probe = find(this.props.probes, {id: probeId});
        if (probe) {
          const processor = getProcessorByStageName({probe, stageName});
          const stage = getStageByName({probe, stageName});
          if (stage && processor) return {probe, processor, stage};
        }
      }
    }
    return null;
  }

  @computed get usePatternWithPersistedData() {
    const {patternDescription, properties} = this;
    return patternDescription?.shouldFetchPersistedStageData && properties.show_context;
  }

  @computed get usePatternWithRawPersistedData() {
    const {patternDescription} = this;
    return patternDescription && patternDescription.shouldFetchRawPersistedStageData;
  }

  @computed get stageCustomizationAvailable() {
    if (this.selectedStageInfo) {
      return this.renderingStrategy ? !this.renderingStrategy.noCustomization : true;
    }
    return false;
  }

  @computed get stageMayHaveAnomalies() {
    if (this.stageCustomizationAvailable) {
      const {processor} = this.selectedStageInfo;
      return processorCanRaiseAnomalies(processor) && !this.stageDataSourceIsTimeSeries;
    }
    return false;
  }

  @computed get stageHasAlternativeRenderingStrategy() {
    return !!(this.selectedStageInfo && this.patternDescription) &&
      (!this.stageDataSourceIsTimeSeries || this.patternDescription.renderingStrategy?.alwaysUseContext);
  }

  @computed get alwaysUseContext() {
    return this.patternDescription?.renderingStrategy?.alwaysUseContext;
  }

  @computed get isSelectedProbeOperational() {
    if (!this.selectedStageInfo) {
      return false;
    }
    const {probe} = this.selectedStageInfo;
    return probe.state === 'operational';
  }

  @computed get stageMayHaveSpotlightMode() {
    if (!this.selectedStageInfo) {
      return true;
    }
    const {processor, stage} = this.selectedStageInfo;
    const {data_source: dataSource} = this.properties;
    const regularRenderingStrategy = getStageRenderingStrategy({processor, stage, dataSource});
    return regularRenderingStrategy && !regularRenderingStrategy.noSpotlightMode;
  }

  @computed get submitAvailable() {
    if (this.widgetType === WIDGET_TYPE_STAGE) {
      return this.selectedStageInfo !== null && this.visibleColumnsLength !== 0 && this.isSelectedProbeOperational;
    }
    return true;
  }

  @computed get visibleColumnsLength() {
    return get(this.properties, ['stage_customization', 'visibleColumns', 'length'], null);
  }

  @computed get stageHasPersistedData() {
    if (!this.selectedStageInfo) {
      return false;
    }
    return this.selectedStageInfo.stage.enable_metric_logging;
  }

  @computed get stageDataSourceIsTimeSeries() {
    if (!this.selectedStageInfo) {
      return false;
    }
    return this.properties.data_source === STAGE_DATA_SOURCE.time_series;
  }

  @action
  setWidgetType = (widgetType) => {
    this.widgetType = widgetType;
    const {widget} = this.props;
    const propertiesFromWidget = widget ? this.widgetToProperties(widget) : {};
    for (const {name, schema} of this.formSchema) {
      if (name === 'type') continue;
      if (!(name in this.properties)) {
        this.properties[name] = get(propertiesFromWidget, [name], generatePropertyFromSchema(schema));
      }
    }
  };

  @action
  setSpotlightMode = (value) => {
    this.properties.spotlight_mode = value;
    const maxItems = value ? SPOTLIGHT_MODE_PAGE_SIZE : DEFAULT_WIDGET_PAGE_SIZE;
    this.properties.max_items = maxItems;
  };

  @action
  setValueColumnName = (value) => {
    this.properties.value_column_name = value;
    if (!this.selectedStageInfo) return;
    const {stage} = this.selectedStageInfo;
    this.properties.aggregation_type = getValueAggregationTypes(stage, value, this.patternDescription);
  };

  @action
  setAggregationType = (value) => {
    this.properties.aggregation_type = value;
    if (value === 'none') {
      this.properties.aggregation_period = 0;
    }
  };

  @action
  resetState = () => {
    const {widget} = this.props;
    this.properties = {};
    this.setWidgetType(widget ? widget.type : DEFAULT_WIDGET_TYPE);
  };

  submit = async () => {
    const {blueprintId} = this.context;
    const {mode, widget} = this.props;
    const result = await (mode === 'update' ? this.updateWidget : this.createWidget)();
    const widgetId = mode === 'update' ? widget.id : result.id;
    const resourceLabel = this.properties.label;
    const resourceHref = widgetId ? `/blueprints/${blueprintId}/analytics/widgets/${widgetId}` : null;
    return {widgetId, resourceLabel, resourceHref};
  };

  processErrors = ({errors}) => {
    if (isPlainObject(errors)) {
      return flatMap(errors, (propertyErrors, propertyName) => {
        if (propertyName === 'probe_id' || propertyName === 'stage_name') propertyName = 'stage';
        return castArray(propertyErrors).map((message) => ({type: 'property', propertyName, message}));
      });
    } else if (isString(errors)) {
      return [{type: 'generic', message: errors}];
    } else {
      return [];
    }
  };

  @action
  setProperty = (name, value) => {
    let clearFilterOnContextTrigger = this.renderingStrategy?.clearFilterOnContextTrigger;
    this.properties[name] = value;
    clearFilterOnContextTrigger ||= this.renderingStrategy?.clearFilterOnContextTrigger;
    if (clearFilterOnContextTrigger) {
      if (name === 'show_context' ||
        (name === 'data_source' && value === 'time_series' && this.properties.show_context)) {
        this.properties.filter = '';
      }
    }
  };

  render() {
    const {mode, open, trigger, onClose, onSuccess, showCreateAnother, probes} = this.props;
    return (
      <ResourceModal
        className='iba-widget-modal'
        mode={mode}
        resourceName='Widget'
        trigger={trigger}
        size='small'
        open={open}
        onClose={onClose}
        resetState={this.resetState}
        submitAvailable={this.submitAvailable}
        submit={this.submit}
        processErrors={this.processErrors}
        onSuccess={onSuccess}
        showCreateAnother={showCreateAnother}
      >
        {({actionInProgress, errors}) =>
          <WidgetForm
            schema={this.formSchema}
            widgetType={this.widgetType}
            setWidgetType={this.setWidgetType}
            setSpotlightMode={this.setSpotlightMode}
            setValueColumnName={this.setValueColumnName}
            setProperty={this.setProperty}
            setAggregationType={this.setAggregationType}
            properties={this.properties}
            disabled={actionInProgress}
            errors={errors}
            probes={probes}
            selectedStageInfo={this.selectedStageInfo}
            stageCustomizationAvailable={this.stageCustomizationAvailable}
          />
        }
      </ResourceModal>
    );
  }
}

@observer
export class WidgetForm extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setPropertyValue(name, value) {
    const {setProperty, errors} = this.props;
    if (name === 'type') {
      this.props.setWidgetType(value);
    } else if (name === 'spotlight_mode') {
      this.props.setSpotlightMode(value);
    } else if (name === 'value_column_name') {
      this.props.setValueColumnName(value);
    } else if (name === 'aggregation_type') {
      this.props.setAggregationType(value);
    } else {
      setProperty(name, value);
      pullAllWith(errors, [{type: 'property', propertyName: name}], isMatch);
    }
  }

  @computed get errors() {
    const {errors} = this.props;
    return {
      form: mapValues(
        groupBy(filter(errors, {type: 'property'}), 'propertyName'),
        (errorGroup) => flatMap(errorGroup, 'message')
      ),
      generic: find(errors, {type: 'generic'}),
      http: find(errors, {type: 'http'}),
    };
  }

  render() {
    const {errors, props: {widgetType, properties, ...props}} = this;
    const {knownTags} = this.context;
    return (
      <Fragment>
        <Form>
          <FormFragment
            {...props}
            values={{...properties, type: widgetType}}
            errors={errors.form}
            onChange={(name, value) => this.setPropertyValue(name, value)}
            allowNewTags={false}
            knownTags={knownTags}
          />
        </Form>
        {errors.http &&
          <FetchDataError error={errors.http.error} />
        }
        {errors.generic &&
          <Message error icon='warning sign' header='Error' content={errors.generic.message} />
        }
      </Fragment>
    );
  }
}

export function WidgetStageSearchBox({value, values, selectedStageInfo, onChange, schema: {SearchBoxComponent}}) {
  return (
    <Field width={16}>
      <SearchBoxComponent
        key={selectedStageInfo.stage.name}
        aria-label='System imbalance query'
        stage={selectedStageInfo.stage}
        processor={selectedStageInfo.processor}
        dataSource={values.data_source}
        filters={value}
        applyOnChange
        onChange={onChange}
      />
    </Field>
  );
}
