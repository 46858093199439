import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {observable, action, reaction, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {Form} from 'semantic-ui-react';
import {saveAs} from 'file-saver';
import copy from 'copy-to-clipboard';
import {isEmpty} from 'lodash';
import {AsyncActionsModal, CodeEditorControl, Loader} from 'apstra-ui-common';

@observer
export default class ExportModal extends Component {
  static propTypes = {
    export: PropTypes.func.isRequired,
    mimeType: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    actions: PropTypes.array,
    mode: PropTypes.string,
    HelpMessageComponent: PropTypes.elementType,
    helpMessageComponentProps: PropTypes.object,
  };

  static defaultProps = {
    mimeType: 'text/plain',
    mode: 'json',
  };

  @observable contents = '';
  @observable loading = true;

  resetState = () => {
    if (this.props.resetState) this.props.resetState();
    this.export();
  };

  @action
  export = async () => {
    const contents = await this.props.export();
    runInAction(() => {
      this.contents = contents;
      this.loading = false;
    });
  };

  copyContents = () => {
    copy(this.contents);
  };

  saveAsFile = () => {
    const {mimeType, fileName} = this.props;
    saveAs(new Blob([this.contents], {type: mimeType}), fileName);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeReexport = reaction(() => this.props.export, () => this.export());
  }

  componentWillUnmount() {
    this.disposeReexport();
  }

  render() {
    const {content, mode, actions, HelpMessageComponent, helpMessageComponentProps, ...props} = this.props;
    delete props.export;
    delete props.mimeType;
    delete props.fileName;
    delete props.resetState;
    return (
      <AsyncActionsModal
        closeIcon
        onMount={this.resetState}
        content={
          () =>
            <Fragment>
              {content}
              <Form>
                {HelpMessageComponent &&
                  <HelpMessageComponent {...helpMessageComponentProps} />
                }
                {this.loading ?
                  <Loader /> :
                  <CodeEditorControl
                    mode={mode}
                    value={this.contents}
                    multiLine
                    showGutter
                    maxLines={20}
                    disabled
                  />}
              </Form>
            </Fragment>
        }
        actions={isEmpty(actions) ? [
          {
            key: 'copy',
            secondary: true,
            content: 'Copy',
            icon: 'copy',
            onClick: this.copyContents,
            disabled: this.loading
          },
          {
            key: 'save',
            primary: true,
            content: 'Save As File',
            icon: 'save',
            onClick: this.saveAsFile,
            disabled: this.loading
          },
        ] : actions}
        {...props}
      />
    );
  }
}
