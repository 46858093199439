import {observable, action, computed, makeObservable} from 'mobx';
import {debounce, isEqual} from 'lodash';

const SHOW_POPUP_DELAY = 300;

export class SharedTooltip {
  @observable isOpen = false;
  @observable isInverted = false;
  @observable.ref content = null;
  @observable offset = [0, 0];
  @observable position = 'top right';
  @observable.ref mountRef = null;

  @computed get isPositioned() {
    return !isEqual(this.offset, [0, 0]);
  }

  followThePointer(mouseEvent, containerRef) {
    const {clientX, clientY} = mouseEvent;
    const {x, y} = containerRef.current.getBoundingClientRect();
    this.setOffset(clientX - x, clientY - y);
  }

  @action
  setOffset(x, y) {
    this.offset = [x - 20, 5 - y];
    if (this.content !== null && !this.isOpen) {
      // When position gets determined for previously
      // but hidden popup - make it open
      this.isOpen = true;
    }
  }

  show = debounce(action((content, inverted) => {
    this.content = content;
    // Popup must not be displayed until position is determined
    this.isOpen = this.isPositioned;
    this.isInverted = !!inverted;
    return this.isPositioned;
  }), SHOW_POPUP_DELAY);

  @action
  hide = () => {
    this.show.cancel();
    this.isOpen = false;
    this.content = null;
  };

  @action
  setMountRef(ref) {
    this.mountRef = ref;
  }

  constructor() {
    makeObservable(this);
  }
}
