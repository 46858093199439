import {action, makeObservable, observable} from 'mobx';

class EndpointGroup {
  @observable id = null;
  @observable tags = [];
  @observable label = null;
  @observable index = null;

  @action
  setLabel(label) {
    this.label = label;
  }

  constructor({id, label, tags}, index) {
    this.id = id;
    this.label = label;
    this.tags = tags;
    this.index = index;
    makeObservable(this);
  }
}

export default EndpointGroup;
