import {map} from 'lodash';
import {Label} from 'semantic-ui-react';

export const syslogEventSchema = [
  {field: 'address', label: 'Address'},
  {field: 'port', label: 'Port'},
  {field: 'protocol', label: 'Protocol'},
  {field: 'facility', label: 'Facility'},
  {field: 'timezone', label: 'Timezone'},
];

export const userEventSchema = [
  {field: 'first_name', label: 'First Name'},
  {field: 'last_name', label: 'Last Name'},
  {field: 'email', label: 'Email'},
  {
    field: 'roles',
    label: 'Roles',
    formatter: (value) => map(value, (role, index) => <Label key={index}>{role}</Label>)
  },
];

export const authAclRuleEventSchema = [
  {field: 'action', label: 'Action'},
  {field: 'subnet', label: 'Subnet'},
  {field: 'comment', label: 'Comment'},
];
